<template>
  <v-stepper v-model="e1">
    <!-- Stepper Headers  -->
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1" :color="color">
        Company Name
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- <v-stepper-step :complete="e1 > 2" step="2" :color="color">
        How Many Guests
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" step="3" :color="color">
        Name of guest and titles
      </v-stepper-step>

      <v-divider></v-divider> -->

      <v-stepper-step :complete="e1 > 2" step="2" :color="color">
        Stock Ticker
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" step="3" :color="color">
        Website
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 4" step="4" :color="color">
        Company Logo
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 5" step="5" :color="color">
        Thank you
      </v-stepper-step>
    </v-stepper-header>

    <!-- Stepper Content  -->
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card class="mx-auto" max-width="600">
          <v-card-text>
            <p class="text-h4 text--primary">
              What is the name of your company
            </p>
            <div class="text--primary">
              Please enter the names as you want it to appear on the network.
              Please include the type of corporation (i.e. LTD, LLC, INC)
            </div>
            <v-text-field
              v-model="form.companyName"
              placeholder="Type your answer here..."
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-btn :color="color" class="white--text" @click="e1 = 2">
              Save <v-icon right dark> mdi-check </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <!-- <v-stepper-content step="2">
        <v-card class="mx-auto" max-width="600">
          <v-card-text>
            <p class="text-h4 text--primary">
              How many guests will appear on the segment?
            </p>

            <v-list>
              <v-list-item-group
                v-model="form.howManyGuest"
                mandatory
                color="indigo"
              >
                <v-list-item
                  v-for="(item, i) in guestSegmentsOptions"
                  :key="i"
                  :value="item.text"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn text class="mr-4" color="secondary" @click="e1 = 1">
              Back
            </v-btn>

            <v-btn :color="color" class="white--text" @click="e1 = 3">
              Save <v-icon right dark> mdi-check </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card class="mx-auto" max-width="600">
          <v-card-text>
            <p class="text-h4 text--primary">Name of guests and titles?</p>
            <div class="text--primary">
              Please enter the names of the features guests as well as their
              respective titles (i.e. CEO, CFO, VP of marketing, Director) as
              you want it to appear on Television? (i.e. John Smith, CEO + Jane
              Doe, CFO)
            </div>

            <v-textarea
              v-model="form.guestNameAndTitle"
              placeholder="Type your answer here..."
              rows="2"
            ></v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-btn text class="mr-4" color="secondary" @click="e1 = 2">
              Back
            </v-btn>

            <v-btn :color="color" class="white--text" @click="e1 = 4">
              Save <v-icon right dark> mdi-check </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content> -->

      <v-stepper-content step="2">
        <v-card class="mx-auto" max-width="600">
          <v-card-text>
            <p class="text-h4 text--primary">
              What is your stock and or Token(Crypto Currency) symbol?
            </p>
            <div class="text--primary">
              Please provide the market yur stock is trading on (i.e. OTC,
              NASDAQ, NYSE and then the Symbol OTCQB: NTTS). If yoou are not a
              public company or do not have a Token please answer N/A.
            </div>
            <v-text-field
              v-model="form.stockTicker"
              placeholder="Type your answer here..."
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-btn text class="mr-4" color="secondary" @click="e1 = 1">
              Back
            </v-btn>

            <v-btn :color="color" class="white--text" @click="e1 = 3">
              Save <v-icon right dark> mdi-check </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card class="mx-auto" max-width="600">
          <v-card-text>
            <p class="text-h4 text--primary">What is your company website?</p>
            <div class="text--primary">
              Please enter the URL as your want it to appear on the network
            </div>

            <v-text-field
              v-model="form.website"
              placeholder="xyz.com"
              prefix="https://"
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-btn text class="mr-4" color="secondary" @click="e1 = 2">
              Back
            </v-btn>

            <v-btn :color="color" class="white--text" @click="e1 = 4">
              Save <v-icon right dark> mdi-check </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="4">
        <v-card class="mx-auto" max-width="600">
          <v-card-text>
            <p class="text-h4 text--primary">Please upload your company logo</p>
            <div class="text--primary">
              Please provide a file with a transparent background if possible
            </div>
            <v-file-input
              @change="handleImage"
              accept="image/*"
              placeholder="File input"
            ></v-file-input>
          </v-card-text>

          <v-card-actions>
            <v-btn text class="mr-4" color="secondary" @click="e1 = 3">
              Back
            </v-btn>

            <v-dialog v-model="submitDialog" persistent max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#006c5d"
                  class="white--text mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Save <v-icon right dark> mdi-check </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h6"> Warning!! </v-card-title>
                <v-card-text
                  >Are you sure you would like to submit form?</v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="submitDialog = false"
                  >
                    No
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="handleFormSubmitEvent"
                  >
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="5">
        <v-card class="mx-auto" max-width="600">
          <v-card-text>
            <v-img src="ntts-logo.png"></v-img>

            <p class="text-h4 text--primary">Thank you for your information</p>
            <div class="text--primary">
              Information provided will be displayed on the New To The Street
              Show. Please make sure the information you provided is correct.
            </div>
          </v-card-text>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
// Update Api
import axios from "axios";
import constants from "../data/constants";
import helper from "../helper/helper";

export default {
  data() {
    return {
      e1: 1,
      color: "#006c5d",
      uid: this.$route.query.uid ?? 0,
      submitDialog: false,
      form: {
        companyName: null,
        howManyGuest: null,
        guestNameAndTitle: null,
        stockTicker: null,
        website: null,
        companyLogo: null,
      },
      agreement: {},

      guestSegmentsOptions: [
        {
          icon: "mdi-alpha-a-box-outline",
          text: "Just One",
        },
        {
          icon: "mdi-alpha-b-box-outline",
          text: "Two",
        },
        {
          icon: "mdi-alpha-c-box-outline",
          text: "Three",
        },
        {
          icon: "mdi-alpha-d-box-outline",
          text: "More Then Three",
        },
      ],
    };
  },

  // checks for agreement ID
  mounted() {
    if (this.uid == 0) {
      this.$router.push("login");
    }
    this.fetchAgreement();
  },

  methods: {
    // gets agreement data
    fetchAgreement() {
      axios
        .get(`${constants.apiHost}/document`, {
          params: {
            id: [this.uid],
          },
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.agreement = res.data[0];
          } else {
            this.$router.push("login");
          }
        });
    },

    handleFormSubmitEvent() {
      this.form.website = `https://${this.form.website}`;

      let val = {
        documents: [
          {
            ...this.agreement,
            clientForm: this.form,
          },
        ],
      };

      axios.post(`${constants.apiHost}/document`, val).then((res) => {
        let agreement = JSON.parse(JSON.stringify(this.agreement));
        helper.xstateMange(agreement);

        this.e1 = 5;
        this.submitDialog = false;
      });
    },

    handleImage(e) {
      this.createBase64Image(e);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form.companyLogo = e.target.result;
      };

      reader.readAsDataURL(fileObject);
    },
  },

  watch: {
    e1(val) {
      if (val == 5) {
        let self = this;
        setTimeout(function () {
          self.$router.push("/login");
        }, 4000);
      }
    },
  },
};
</script>

<style>
</style>
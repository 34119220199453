<template>
  <div>
    <component :is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
import AppLayout from "@/layouts/app";
import AuthLayout from "@/layouts/auth";
import SignatureLayout from "@/layouts/signature";

export default {
  components: {
    AppLayout,
    AuthLayout,
    SignatureLayout
  },

  data() {
    return {
      isLogIn: false,
    };
  },

  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
};
</script>

<template>
  <div>
    <v-row>
      <h1>Leads Calendar</h1>
      <!-- toolbar schedule start -->
      <v-toolbar flat>
        <v-spacer></v-spacer>

        <v-btn outlined color="grey darken-2" @click="createEvent">
          <span>Schedule</span>
          <v-icon right> mdi-calendar-account </v-icon>
        </v-btn>
        <v-dialog v-model="dialog" max-width="600px">
          <template> </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Schedule Meeting</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Event Title"
                      required
                      v-model="scheduleEvent.summary"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Description"
                      required
                      v-model="scheduleEvent.description"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-dialog
                      v-model="startDateTime"
                      persistent
                      max-width="290"
                    >
                      <v-card>
                        <v-date-picker
                          v-if="showStartDatepicker"
                          v-model="scheduleEvent.startDate"
                          @input="handleStartDatePicker"
                        >
                        </v-date-picker>

                        <v-time-picker
                          v-if="showStartTimePicker"
                          format="24hr"
                          v-model="scheduleEvent.startTime"
                          @input="handleStartTimePicker"
                        >
                        </v-time-picker>
                      </v-card>
                    </v-dialog>

                    <v-text-field
                      label="Start Time"
                      required
                      v-model="scheduleEvent.startDateTime"
                      hint="MM/DD/YYYY hh:mm"
                      persistent-hint
                      @click="handleStartDateClickEvent"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-dialog v-model="endDateTime" persistent max-width="290">
                      <v-card>
                        <v-date-picker
                          v-if="showEndDatepicker"
                          v-model="scheduleEvent.endDate"
                          @input="handleEndDatePicker"
                        >
                        </v-date-picker>

                        <v-time-picker
                          v-if="showEndTimePicker"
                          format="24hr"
                          v-model="scheduleEvent.endTime"
                          @input="handleEndTimePicker"
                        >
                        </v-time-picker>
                      </v-card>
                    </v-dialog>

                    <v-text-field
                      label="End Time"
                      hint="MM/DD/YYYY hh:mm"
                      persistent-hint
                      v-model="scheduleEvent.endDateTime"
                      @click="handleEndDateClickEvent"
                      dense
                    ></v-text-field>
                  </v-col>

                  <template v-for="(attendee, i) in scheduleEvent.attendees">
                    <v-col cols="12" sm="6" md="4" :key="i">
                      <v-text-field
                        :label="`Attendee ${i + 1} Email`"
                        required
                        v-model="attendee.email"
                        dense
                      ></v-text-field>
                    </v-col>
                  </template>

                  <v-col cols="12" sm="6">
                    <v-select
                      :items="[
                        'NTTS Office',
                        'Zoom',
                        'Phone Call',
                        'Google Hangouts',
                      ]"
                      label="Location"
                      required
                      v-model="scheduleEvent.location"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <!-- Lead names drop down -->
                    <v-autocomplete
                      v-model="scheduleEvent.leadName"
                      :items="leads"
                      item-text="name"
                      item-value="id"
                      label="Lead Name"
                      dense
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col v-if="scheduleEvent.location === 'Phone Call'" cols="12">
                    <v-text-field
                      label="Phone Number"
                      required
                      v-model="scheduleEvent.phone_number"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col v-if="scheduleEvent.location === 'Zoom'" cols="12">
                    <v-text-field
                      label="Zoom Link"
                      required
                      v-model="scheduleEvent.zoomLink"
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      v-model="scheduleEvent.colorId"
                      :items="colors"
                      item-text="color"
                      item-value="colorId"
                      label="Pick Color"
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="submitEvent()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </v-row>
    <!-- toolbar schedule end -->

    <!-- Today / Month toolbar start -->
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>

            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>

            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>

            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <!-- Today / Month toolbar end -->
        </v-sheet>

        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          ></v-calendar>

          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-btn icon @click="editselectedEvent">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>

                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon @click="cancelselectedEvent">
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </v-toolbar>

              <v-card-text>
                <b>Description</b> {{ selectedEvent.description }} <br />
                <b>Location: </b> {{ selectedEvent.location }} <br />
                <template v-if="selectedEvent.location == 'Phone Call'">
                  <b>Phone Number: </b>
                    {{ selectedEvent.phone_number }} <br />
                </template>
                <template v-if="selectedEvent.location == 'Zoom'">
                  <b>Zoom Link: </b>
                  <a :href="selectedEvent.zoomLink" target="_blank">
                    {{ selectedEvent.zoomLink }} </a
                  ><br />
                </template>
                <b>Attendees: </b>
                <span v-html="eventAttendees"></span>
              </v-card-text>

              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>


<script>
// Update Api
import axios from "axios";
import moment from "moment";
import constants from '../data/constants';

export default {
  data: () => ({
    // Calendar Submit
    scheduleEvent: {
      summary: "",
      location: "",
      description: "",
      startDateTime: "",
      startDate: "",
      startTime: "",
      endDateTime: "",
      endDate: "",
      endTime: "",
      attendees: [{ email: "" }, { email: "" }, { email: "" }],
      leadName: "",
      id: "",
      colorId: "",
      zoomLink: "",
      phone_number: ""
    },

    dialog: false,
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,

    startDateTime: false,
    showStartDatepicker: false,
    showStartTimePicker: false,

    endDateTime: false,
    showEndDatepicker: false,
    showEndTimePicker: false,

    // Event Date Format
    // It must be a Date, number of seconds since Epoch, or a string in the format of YYYY-MM-DD or YYYY-MM-DD hh:mm.
    //Zero-padding is optional and seconds are ignored.
    events: [],
    leads: [],

    // brown has no color id
    colors: [
      { colorId: 1, color: "purple lighten-3" },
      { colorId: 2, color: "green lighten-3" },
      { colorId: 3, color: "purple darken-3" },
      { colorId: 4, color: "pink" },
      { colorId: 5, color: "yellow" },
      { colorId: 6, color: "orange" },
      { colorId: 7, color: "blue lighten-3" },
      { colorId: 8, color: "grey" },
      { colorId: 9, color: "blue darken-3" },
      { colorId: 10, color: "green darken-3" },
      { colorId: 11, color: "red" },
    ],

    snackbar: false,
    snackbarMessage: "",
  }),

  computed: {
    // loops through the attendees and creates an array
    eventAttendees() {
      var res = "";
      if (
        "attendees" in this.selectedEvent &&
        this.selectedEvent.attendees != undefined
      ) {
        this.selectedEvent.attendees.forEach((v, i) => {
          if (i == 0) {
            res += `${v.email}`;
          } else {
            res += `<br /> ${v.email}`;
          }
        });
      }
      return res;
    },
  },

  mounted() {
    this.$refs.calendar.checkChange();
    this.fetchLeads();
    // look to see in the url if there are any params and if any of those params are called leadName
    if (this.$route.query.leadName) {
      this.dialog = true;
    }
  },

  methods: {
    // Get all leads while page load
    fetchLeads() {
      axios
        .post(`${constants.apiHost}/collectionDoc`, {
          model: "['cryptoLeads', 'leads']",
        })
        .then((res) => {
          this.leads = res.data;
          this.leads.map((v) => {
            v.name = v.name ? v.name : v.companyName;
            return v;
          });

          // sets company name to url param leadName
          if (this.$route.query.leadName) {
            this.scheduleEvent.leadName = this.$route.query.leadName;
          }
        });
    },

    // scheduled event reset when creating new event
    createEvent() {
      this.scheduleEvent = {
        summary: "",
        location: "",
        description: "",
        startDateTime: "",
        startDate: "",
        startTime: "",
        endDateTime: "",
        endDate: "",
        endTime: "",
        attendees: [{ email: "" }, { email: "" }, { email: "" }],
        leadName: "",
        id: "",
        colorId: "",
        zoomLink: "",
        phone_number: ""
      };

      this.selectedEvent = {};

      this.dialog = true;
    },

    // Puts the selectedEvent data into the Schedule Event Form
    editselectedEvent() {
      this.scheduleEvent.summary = this.selectedEvent.name;
      this.scheduleEvent.location = this.selectedEvent.location;
      this.scheduleEvent.description = this.selectedEvent.description;
      this.scheduleEvent.zoomLink = this.selectedEvent.zoomLink;
      this.scheduleEvent.phone_number = this.selectedEvent.phone_number;
      this.scheduleEvent.startDateTime = moment(
        this.selectedEvent.start
      ).format("MM/DD/YYYY HH:mm");
      this.scheduleEvent.startDate = "";
      this.scheduleEvent.startTime = "";
      this.scheduleEvent.endDateTime = moment(this.selectedEvent.end).format(
        "MM/DD/YYYY HH:mm"
      );
      this.scheduleEvent.endDate = "";
      this.scheduleEvent.endTime = "";

      if (
        "attendees" in this.selectedEvent &&
        this.selectedEvent.attendees != undefined
      ) {
        this.scheduleEvent.attendees = [];
        this.selectedEvent.attendees.forEach((v, i) => {
          this.scheduleEvent.attendees.push({ email: v.email });
        });
      } else {
        this.scheduleEvent.attendees = [
          { email: "" },
          { email: "" },
          { email: "" },
        ];
      }

      this.scheduleEvent.id = this.selectedEvent.id;
      this.scheduleEvent.leadName = this.selectedEvent.source.title;
      this.scheduleEvent.colorId = parseInt(this.selectedEvent.colorId);

      this.dialog = true;
    },

    // cancel selected event modal
    cancelselectedEvent() {
      let val = {
        id: this.selectedEvent.id,
      };
      let leadId = this.selectedEvent.source?.title ?? null;

      axios.post(`${constants.apiHost}/leads-cancel`, val).then((res) => {
        this.selectedOpen = false;
        this.events = this.events.filter((v) => v.id != val.id);

        if (leadId) {
          let lead = this.leads.find((v) => v.id == leadId);

          // checks for calednarEvent key in DB and creates value
          if ("calendarEvents" in lead) {
            lead.calendarEvents = lead.calendarEvents.filter(
              (v) => v.id != val.id
            );
          }

          axios
            .post(`${constants.apiHost}/document`, {
              documents: [lead],
            })
            .then((res) => {
              if ("status" in res && res.status != "errors") {
                //
              }
            });
        }
        // this.fetchEvent();
      });
    },

    // Save event details and store into database
    submitEvent() {
      let val = JSON.parse(JSON.stringify(this.scheduleEvent));
      val.attendees = val.attendees.filter((v) => v.email != "");
      let zoom = `\n\n Zoom Link: ${val.zoomLink}`;
      let phone_number = `\n\n Phone Number: ${val.phone_number}`;
      val.description = `${val.description}`;
      if (val.location == "Zoom") {
        val.description += `${zoom}`;
      } else if (val.location == "Phone Call") {
        val.description += `${phone_number}`;
      }

      let onlyDesc = val.description;
      let onlyZoom = val.zoomLink;
      let onlyPhone = val.phone_number;

      delete val.zoomLink;
      delete val.phone_number;
      // This is used to set the event ID in the calendar event so we can identify the company
      val.source = {
        title: this.scheduleEvent.leadName,
        url: "https://google.com",
      };

      axios
        .post(
          `${constants.apiHost}/leads-create-update`,
          val
        )
        .then((res) => {
          let color = "brown";
          if ("colorId" in res.data) {
            let getColor = this.colors.find(
              (v) => v.colorId == res.data.colorId
            );
            if (getColor) {
              color = getColor.color;
            }
          }

          let zoomLink = "";
          let description = res.data.description;
          if (
            res.data.description &&
            res.data.description.includes(" Zoom Link: ")
          ) {
            zoomLink = res.data.description.split(" Zoom Link: ").at(-1);
            description = description.replace(` Zoom Link: ${zoomLink}`, "");
          }

          let newObj = {
            name: res.data.summary,
            description: onlyDesc,
            zoomLink: onlyZoom,
            phone_number: onlyPhone,
            location: res.data.location,
            attendees: res.data.attendees,
            start: new Date(res.data.start.dateTime),
            end: new Date(res.data.end.dateTime),
            id: res.data.id,
            color: color,
            colorId: res.data.colorId,
            timed: "false",
            source: res.data.source,
          };

          // pushes event to local varaiable newObj so there is no need to fetch data from google api
          let createEvent = true;
          if (val.id == "") {
            this.events.push(newObj);
          } else {
            createEvent = false;
            this.events = this.events.map((v) => {
              if (v.id == newObj.id) {
                return newObj;
              }
              return v;
            });
            this.selectedEvent = newObj;
          }
          // DB record
          let calendarEvent = {
            id: res.data.id,
            name: res.data.summary,
            description: res.data.description,
            location: res.data.location,
            attendees: res.data.attendees,
            start: new Date(res.data.start.dateTime),
            end: new Date(res.data.end.dateTime),
          };

          let lead = this.leads.find(
            (v) => v.id == this.scheduleEvent.leadName
          );

          // checks for calednarEvent key in DB and creates value
          if ("calendarEvents" in lead) {
            if (!createEvent) {
              lead.calendarEvents = lead.calendarEvents.filter(
                (v) => v.id != calendarEvent.id
              );
            }
          } else {
            lead.calendarEvents = [];
          }
          lead.calendarEvents.push(calendarEvent);

          axios
            .post(`${constants.apiHost}/document`, {
              documents: [lead],
            })
            .then((res) => {
              if ("status" in res && res.status != "errors") {
                //
              }
            });
        })
        .catch((err) => {
          if (err.error && err.error.message) {
            this.snackbar = true;
            this.snackbarMessage = err.error.message;
          }
        });

      this.dialog = false;
    },

    // default vuetify calendar function
    getDate(date) {
      return new Date(date);
    },

    // default vuetify calendar function
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    // default vuetify calendar function
    getEventColor(event) {
      return event.color;
    },

    // default vuetify calendar function
    setToday() {
      this.focus = "";
    },

    // default vuetify calendar function
    prev() {
      this.$refs.calendar.prev();
    },

    // default vuetify calendar function
    next() {
      this.$refs.calendar.next();
    },

    // handles when an event is clicked on the calendar
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = JSON.parse(JSON.stringify(event));
        console.log(this.selectedEvent);

        let zoomLink = "";
        let phone_number = "";
        let description = this.selectedEvent.description;
        if (
          this.selectedEvent.description &&
          this.selectedEvent.description.includes(" Zoom Link: ")
        ) {
          zoomLink = this.selectedEvent.description
            .split(" Zoom Link: ")
            .at(-1);
          description = description.replace(` Zoom Link: ${zoomLink}`, "");
        }
        if (
          this.selectedEvent.description &&
          this.selectedEvent.description.includes(" Phone Number: ")
        ) {
          phone_number = this.selectedEvent.description
            .split(" Phone Number: ")
            .at(-1);
          description = description.replace(` Phone Number: ${phone_number}`, "");
        }

        this.selectedEvent.description = description;
        this.selectedEvent.zoomLink = zoomLink;
        this.selectedEvent.phone_number = phone_number;

        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    // handle start date modal popup event
    handleStartDateClickEvent() {
      this.scheduleEvent.startDate = "";
      this.scheduleEvent.startTime = "";
      this.startDateTime = true;
      this.showStartDatepicker = true;
    },

    // event start date selected
    handleStartDatePicker() {
      this.showStartDatepicker = false;
      this.showStartTimePicker = true;
    },

    // event start time selected
    handleStartTimePicker() {
      this.showStartDatepicker = true;
      this.showStartTimePicker = false;
      this.startDateTime = false;
      let startDateTime = `${this.scheduleEvent.startDate} ${this.scheduleEvent.startTime}`;
      this.scheduleEvent.startDateTime =
        moment(startDateTime).format("MM/DD/YYYY HH:mm");
    },

    // handle end date modal popup event
    handleEndDateClickEvent() {
      this.scheduleEvent.endDate = "";
      this.scheduleEvent.endTime = "";
      this.endDateTime = true;
      this.showEndDatepicker = true;
    },

    // event end date selected
    handleEndDatePicker() {
      this.showEndDatepicker = false;
      this.showEndTimePicker = true;
    },

    // event end time selected
    handleEndTimePicker() {
      this.showEndDatepicker = true;
      this.showEndTimePicker = false;
      this.endDateTime = false;
      let endDateTime = `${this.scheduleEvent.endDate} ${this.scheduleEvent.endTime}`;
      this.scheduleEvent.endDateTime =
        moment(endDateTime).format("MM/DD/YYYY HH:mm");
    },
  },

  watch: {
    scheduleEvent: {
      deep: true,
      handler: (val, oldVal) => {
        let add = true;
        val.attendees.forEach((v) => {
          if (v.email == "") {
            add = false;
          }
        });

        if (add) {
          let count = val.attendees.length;
          let rem = count % 3;
          console.log(count);
          console.log(rem);
          for (let i = 1; i <= 3 - rem; i++) {
            val.attendees.push({ email: "" });
          }
        }
      },
    },
  },

  created() {
    // Calls google calendar api to get events
    axios
      .post(`${constants.apiHost}/calendar-type`, {
        calendartype: "Leads",
      })
      .then((response) => {
        axios
          .get(`${constants.apiHost}/events-from-primary`)
          .then((res) => {
            this.allEvents = JSON.parse(JSON.stringify(res.data.items));

            // Iterates through Events and puts them in proper format for vuetify prop
            this.allEvents.forEach((item) => {
              let color = "brown";
              if ("colorId" in item) {
                let getColor = this.colors.find(
                  (v) => v.colorId == item.colorId
                );
                if (getColor) {
                  color = getColor.color;
                }
              }

              let newObj = {
                name: item.summary,
                description: item.description,
                location: item.location,
                attendees: item.attendees,
                start: new Date(item.start.dateTime),
                end: new Date(item.end.dateTime),
                id: item.id,
                color: color,
                timed: "false",
                source: item.source,
                colorId: item.colorId,
              };
              this.events.push(newObj);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
  },
};
</script>

<style>
</style>
<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredAgreements"
      :search="search"
      :loading="tableLoader"
      class="elevation-1"
      no-results-text="No Results"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>My Agreements</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-select
            :items="statesItems"
            v-model="searchItemByState"
            placeholder="Search Status"
            hide-details="auto"
            item-text="name"
            item-value="id"
            clearable
          ></v-select>

          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            tile
            color="#206a5d"
            to="create-agreement"
            style="color: #ffffff"
          >
            <v-icon left> mdi-plus </v-icon>
            New Agreement
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          icon
          class="mr-2"
          @click="editItem(item)"
          :to="'edit-agreement/' + item.id"
        >
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>

        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

      <template v-slot:item.agreementName="{ item }">
        <v-btn text :to="`/show-agreement/${item.id}`" small>
          {{ item.agreementName }}
        </v-btn>
      </template>

      <!-- removed value -->
      <template v-slot:item.status="{ item }">
        <v-chip
          :color="helper.getAgreementStatusColor(item.xstate.status || 'saved')"
          dark
        >
          {{ helper.getAgreementStatusName(item.xstate.status || "saved") }}
        </v-chip>
      </template>

      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h6"> Warning!! </v-card-title>
        <v-card-text>
          {{
            `Are you sure you would like to delete ${agreement.agreementName} agreement?`
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteItemConfirm(false)">
            No
          </v-btn>
          <v-btn color="green darken-1" text @click="deleteItemConfirm(true)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// updated api
import axios from "axios";
import helper from "./../helper/helper";
import constants from "../data/constants";

export default {
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      tableLoader: false,
      agreement: {},
      statesItems: [
        { id: "CREATEAGREEMENT", name: "Agreement in Progress" },
        { id: "CLIENTSIGN", name: "Agreement with Client" },
        { id: "HOSTSIGN", name: "Agreement with Host" },
        { id: "ONBOARDING", name: "Client Onboarding" },
        { id: "SETINTERVIEWDATE", name: "Interview Scheduling" },
        { id: "INTERVIEWCOMPLETED", name: "Interview Scheduled" },
      ],
      search: "",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "agreementName",
        },
        { text: "Main Contact", value: "client.name" },
        { text: "Phone #", value: "client.number" },
        { text: "Email", value: "client.email" },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
        { text: "Category", value: "categories" },
        {
          text: "Due Date",
          value: "date",
          sortable: true,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      newAgreements: [],
      agreements: [],
      editedIndex: -1,
      editedItem: {
        clientMainContactName: "",
        clientMainContactMobilePhone: null,
        clientMainContactEmail: "",
        clientMainContactRole: "",
        date: null,
      },
      defaultItem: {
        clientMainContactName: "",
        clientMainContactMobilePhone: null,
        clientMainContactEmail: "",
        clientMainContactRole: "",
        date: null,
      },
      searchItemByState: null,
      helper: helper,
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },

    filteredAgreements() {
      if (this.searchItemByState == null) {
        return this.agreements.filter((v) => {
          if (v.xstate.status == "FINISH") {
            //
          } else {
            return v;
          }
        });
      }
      return this.agreements.filter((v) => {
        if (
          v.xstate.status != "FINISH" &&
          v.xstate.status == this.searchItemByState
        ) {
          return true;
        }
        return false;
      });
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    // shows agreements on agreements page
    initialize() {
      this.tableLoader = true;
      axios
        .post(`${constants.apiHost}/collectionDoc`, {
          model: "['agreements']",
        })
        .then((res) => {
          this.tableLoader = false;
          this.agreements = Object.values(res.data).filter((v) => {
            if (
              "isActive" in v ||
              v.isActive == false ||
              (v.state && v.state.status == "final")
            ) {
              //
            } else {
              return v;
            }
          });
        });
    },
    //send to step 4 on edit agreement
    editItem(item) {
      this.editedIndex = this.newAgreements.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.agreement = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm(type) {
      let val = {
        documents: [
          {
            ...JSON.parse(JSON.stringify(this.agreement)),
            isActive: false,
          },
        ],
      };
      this.dialogDelete = false;
      this.agreement = {};

      if (type) {
        this.tableLoader = true;
        axios.post(`${constants.apiHost}/document`, val).then((res) => {
          this.initialize();
        });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.newAgreements[this.editedIndex], this.editedItem);
      } else {
        this.newAgreements.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
<template>
  <div>
    <p>Please review the finalized agreement below:</p>

    <iframe :src="pdf" width="100%" height="712px"></iframe>
    <!-- Uncomment this row when generating pdf-->

    <v-row>
      <v-col>
        <p>Select who will sign first below:</p>
        <!-- agreementSign -->
        <v-radio-group v-model="items.clientFirst" :items="signFirst" mandatory>
          <v-radio label="NTTS" :value="false"></v-radio>
          <v-radio
            :label="`${company.name} (${items.client.name})`"
            :value="true"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="mb-2">
        <v-dialog
          v-if="items.clientFirst"
          v-model="dialog"
          persistent
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#006c5d"
              @click="handleDialogEvent"
              class="mr-2 white--text"
            >
              Select Send Date
            </v-btn>
          </template>
          <!-- Date and Time Picker -->
          <v-card>
            <v-date-picker
              v-if="showDatepicker"
              v-model="submitDate"
              :items="date"
              @input="handleDatePicker"
            >
            </v-date-picker>

            <v-time-picker
              v-if="showTimePicker"
              format="24hr"
              v-model="submitTime"
              :items="time"
              @input="handleTimePicker"
            >
            </v-time-picker>
          </v-card>
        </v-dialog>
        <template v-if="items.clientFirst">
          {{ submitDate }} {{ submitTime }}
        </template>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn text @click="updateStep(4)" class="mr-2"> Back </v-btn>

        <!-- "updateStep(6)" -->
        <v-dialog v-model="submitDialog" persistent max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#006c5d"
              class="white--text mr-2"
              v-bind="attrs"
              v-on="on"
            >
              <!--add back @click="handleSubmit"-->
              <template v-if="items.clientFirst"> Submit to Customer </template>
              <template v-else> Submit </template>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h6"> Warning!! </v-card-title>
            <v-card-text
              >Are you sure you would like to submit form?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="submitDialog = false">
                No
              </v-btn>
              <v-btn color="green darken-1" text @click="submitForm">
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Update Api
import axios from "axios";
import helper from "../../helper/helper";
import constants from "../../data/constants";

export default {
  props: {
    step: Number,
    items: Object,
    pdf: String,
    companies: Array,
    submitDate: String,
    submitTime: String,
  },

  data() {
    return {
      dialog: false,
      showDatepicker: true,
      showTimePicker: false,
      date: null,
      time: null,
      switch1: true,
      signFirst: true,
      submitDialog: false,
    };
  },

  computed: {
    company() {
      let company = this.companies.find((v) => v.id == this.items.company);
      if (company) {
        return company;
      }
      return {};
    },
  },

  methods: {
    handleDialogEvent() {
      this.dialog = true;
      this.submitDate = null;
      this.submitTime = null;
    },

    handleDatePicker() {
      this.showDatepicker = false;
      this.showTimePicker = true;
      console.log(this.picker);
    },

    handleTimePicker() {
      this.showDatepicker = true;
      this.showTimePicker = false;
      this.dialog = false;
    },
    // updating or creating document in the DB
    submitForm() {
      if (this.items.clientFirst) {
        if (this.submitDate == null || this.submitTime == null) {
          this.items.submitDate = new Date().toUTCString();
        }
        this.items.submitDate = new Date(
          `${this.submitDate} ${this.submitTime}`
        ).toUTCString();
      } else {
        this.items.submitDate = null;
      }
      console.log(this.items.submitDate);

      // if (this.$route.params.id) {
      let val = {
        documents: [this.items],
      };
      // update
      axios.post(`${constants.apiHost}/document`, val).then((res) => {
        this.handleSubmit();
        this.handleCreateAgreement();
        this.submitDialog = false;
      });
      // } else {
      //   // create
      //   axios
      //     .post(
      //       "https://prodeo-nodered-dev.prodeo-live.com/agreements",
      //       this.items
      //     )
      //     .then((res) => {
      //       this.handleSubmit();
      //       this.handleCreateAgreement();
      //       this.submitDialog = false;
      //     });
      // }
    },

    handleSubmit() {
      let company = this.companies.find((v) => v.id == this.items.company);

      console.log(company);

      let val = {
        ...this.items,
        contractingCompany: "New to the Street Group LLC",
        clientCompanyName: company.name,
        clientAddressCity: company.city,
        clientAddressCountry: company.country,
        clientAddressLine1: company.addressLine1,
        clientAddressLine2: company.addressLine2,
        clientAddressState: company.state,
        clientAddressZipCode: company.zip,
        commonStock: company.commonStock,
        clientAuthorizedSignerEmail: this.items.client.email,
        clientAuthorizedSignerMobilePhone: this.items.client.number,
        clientAuthorizedSignerName: this.items.client.name,
        clientMainContactRole: this.items.client.role,
        clientMainContactEmail: this.items.secondaryClient.email,
        clientMainContactMobilePhone: this.items.secondaryClient.number,
        clientMainContactName: this.items.secondaryClient.name,
        clientSignature: null,
        contractSignature: null,
        generateInvoice: false,
        submit: null,
        type: "agreement",
      };

      console.log(val);

      console.log("Submit Step 5 > step 6");
      this.updateStep(6);
    },

    // xstate
    handleCreateAgreement() {
      let milestone = {
        documents: [
          {
            agreement: this.items.id,
            id: helper.makeid(20),
            code: "Step-0",
            collectionName: "milestones",
            description: "Agreement Created",
            timestamp: Date.now(),
          },
        ],
      };

      axios.post(`${constants.apiHost}/document`, milestone).then(() => {
        let agreement = JSON.parse(JSON.stringify(this.items));
        helper.xstateMange(agreement);

        // axios
        //   .post(
        //     `${constants.apiHost}/createAgreement`,
        //     val
        //   )
        //   .then((res) => {
        //     console.log("response from create agreement api:::::", res);
        //   });
      });
    },

    updateStep(to) {
      this.$emit("updateStep", to);
    },
  },
};
</script>
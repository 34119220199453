<template>
  <div>
    <template v-if="agreement != {}">
      <v-row no-gutters>
        <v-col>
          <v-card class="pa-3" outlined tile>
            <b> {{ agreement.agreementName }} </b>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="pa-2" outlined tile>
            <b>Status: &nbsp; &nbsp;</b>
            <v-chip class="px-5" :color="`${getColor()} white--text`">
              {{ getStatusName() }}
            </v-chip>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="pa-2" outlined tile>
            <b>Interview:&nbsp; &nbsp;</b>
            <v-chip color="blue white--text">
              {{ getRandomInterviewStatus }}
            </v-chip>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="6">
          <v-container style="max-width: 100%">
            <v-timeline dense clipped>
              <v-timeline-item class="mb-6" hide-dot>
                <span
                  ><h1>{{ agreement.agreementName }} Timeline</h1></span
                >
              </v-timeline-item>

              <v-timeline-item
                v-for="data in timeline"
                :key="data.time"
                class="mb-4"
                :color="timelineChipIconColor(data.code)"
                :icon="timelineChipIcon(data.code)"
                small
              >
                <v-row justify="space-between">
                  <v-col cols="7">
                    <v-chip
                      class="white--text ml-0"
                      :color="timelineChipColor(data.code)"
                      label
                      small
                    >
                      {{ timelineChipText(data.code) }}
                    </v-chip>
                    {{ data.description }}
                  </v-col>
                  <v-col class="text-right" cols="5">
                    {{ getDate(data.timestamp) }}
                  </v-col>
                </v-row>
                <v-btn v-if="data.btn" class="mx-0">{{ data.btn }}</v-btn>
              </v-timeline-item>
            </v-timeline>
          </v-container>
        </v-col>

        <!-- upcoming interview Starts -->
        <!-- agreement.state &&
            agreement.state.value == 'finalizedInterview' && -->
        <v-col v-if="interview.currentInterview" sm="6">
          <v-card
            elevation="2"
            shaped
            :loading="loading"
            class="mx-auto"
            max-width="374"
          >
            <v-toolbar color="teal" dark dense>
              <v-toolbar-title>Upcoming Interview </v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>

            <v-img height="200" :src="anchorImg"></v-img>

            <v-card-title>
              <v-icon class="pa-3"> mdi-account-voice </v-icon>
              Anchor: {{ interview.currentInterview.anchor }}
            </v-card-title>

            <v-img height="200" src="/blankprofile.png"></v-img>

            <v-card-title>
              <v-icon class="pa-3"> mdi-account-voice </v-icon>
              Interviewee:
              <br />
            </v-card-title>
            <v-card-text>
              <template
                v-for="interviewee in interview.currentInterview
                  .intervieweeDetails"
              >
                <span :key="interviewee.email">
                  {{ interviewee.name }} - {{ interviewee.role }} -
                  {{ interviewee.email }}
                  <br />
                </span>
              </template>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-title>
              <v-icon class="pa-3"> mdi-calendar-clock </v-icon>Interview
              Scheduled on:
            </v-card-title>

            <v-card-text class="ml-14">
              <v-chip color="teal white--text">
                {{ interviewTime }}
              </v-chip>
            </v-card-text>

            <v-card-actions>
              <v-btn color="red" text @click="checkReschedule">
                Reschedule
              </v-btn>

              <v-btn color="black" text @click="completeInterviewDialog = true">
                Completed Interview
              </v-btn>
            </v-card-actions>
            <v-divider class="mx-4"></v-divider>

            <v-card-actions>
              <v-card-title>Interview Questions:</v-card-title>

              <v-spacer></v-spacer>

              <v-btn icon @click="show = !show">
                <v-icon>{{
                  show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition v-if="interview.currentInterview">
              <div v-show="show">
                <v-divider></v-divider>
                <v-card max-width="650">
                  <v-expansion-panels accordion>
                    <v-expansion-panel
                      v-for="(item, index) in interview.currentInterview
                        .questions"
                      :key="index"
                    >
                      <v-expansion-panel-header>
                        <h3>Question: {{ index + 1 }}</h3>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-textarea
                          name="question"
                          :value="item.response"
                          hint="Type the question you want to be asked"
                          prepend-inner-icon="mdi-message-question-outline"
                          class="mx-2"
                          rows="2"
                        ></v-textarea>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-card-actions>
                    <v-btn
                      color="deep-purple lighten-2"
                      text
                      @click="saveQuestions"
                    >
                      Save Changes
                    </v-btn>
                    <v-btn
                      color="deep-purple lighten-2"
                      text
                      @click="discardChanges"
                    >
                      Discard
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>

        <!-- Client form Details -->
        <v-col sm="6" v-if="agreement.clientForm">
          <v-card elevation="2" shaped :loading="loading" class="mx-auto">
            <v-toolbar color="teal" dark dense>
              <v-toolbar-title>Client Form </v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-img
                    :aspect-ratio="16 / 9"
                    height="150"
                    contain
                    :src="agreement.clientForm.companyLogo"
                  ></v-img>
                </v-col>

                <v-col cols="6">
                  <b>Company Name:</b> {{ agreement.clientForm.companyName }}
                  <br />
                  <!-- <b>Guest Name and Title:</b>
                  {{ agreement.clientForm.guestNameAndTitle }} <br />
                  <b>How Many Guest:</b> {{ agreement.clientForm.howManyGuest }}
                  <br /> -->
                  <b>Stock Ticker:</b> {{ agreement.clientForm.stockTicker }}
                  <br />
                  <b>Website:</b>
                  <v-btn
                    :href="agreement.clientForm.website"
                    text
                    small
                    target="_blank"
                  >
                    {{ agreement.clientForm.website }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <v-dialog v-model="completeInterviewDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h6"> Warning!! </v-card-title>
        <v-card-text
          >Are you sure you would like to complete the Interview?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="completeInterviewDialog = false"
          >
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :loading="completeInterviewBtnLoader"
            @click="handleCompleteInterviewEvent"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="rescheduleDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h6"> Warning!! </v-card-title>
        <v-card-text>
          Please contact your representative to reschedule your interview
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="rescheduleDialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Update Api
import axios from "axios";
import helper from "../helper/helper";
import moment from "moment";
import constants from "../data/constants";
export default {
  data() {
    return {
      loading: false,
      show: false,
      id: this.$route.params.id ?? 0,
      agreement: {},
      company: {},
      timeline: [],
      newtimeline: [],
      completeInterviewDialog: false,
      completeInterviewBtnLoader: false,
      interview: {},
      rescheduleDialog: false,
    };
  },

  mounted() {
    if (this.id != 0) {
      this.fetchAgreement();
    } else {
      this.$router.push("agreements");
    }
  },

  computed: {
    getRandomInterviewStatus() {
      var min = this.interview.iteration ?? 0;
      var max = this.agreement.servicePlans;
      return `${min} out of ${max}`;
    },

    anchorImg() {
      let img = "";
      if (this.interview != {} && this.interview.currentInterview) {
        if (this.interview.currentInterview.anchor == "Anna Berry") {
          img = "/annaberry.png";
        } else if (this.interview.currentInterview.anchor == "Jane King") {
          img = "/janeking.jpeg";
        }
      }
      return img;
    },

    interviewTime() {
      if (this.interview.currentInterview) {
        return moment(this.interview.currentInterview.startTime).format(
          "MM/DD/YYYY HH:mm:ss"
        );
      }
      return "";
    },
  },

  methods: {
    fetchAgreement() {
      axios
        .get(`${constants.apiHost}/document`, {
          params: {
            id: [this.id],
          },
        })
        .then((res) => {
          if (!res.data[0]) {
            this.$router.push("agreements");
          }
          this.agreement = res.data[0];
          this.fetchCompany();
          this.fetchTimeline();
          this.fetchInterview();
        });
    },

    fetchCompany() {
      axios
        .get(`${constants.apiHost}/document`, {
          params: {
            id: [this.agreement.company],
          },
        })
        .then((res) => {
          this.company = res.data[0];
        });
    },

    fetchInterview() {
      axios
        .post(`${constants.apiHost}/interviews`, {
          params: {
            id: [this.id],
          },
        })
        .then((res) => {
          this.interview = res.data;
          console.log(
            "Interview Agreement Object ",
            this.interview.currentInterview
          );
        });
    },

    fetchTimeline() {
      axios
        .post(`${constants.apiHost}/getMilestones`, {
          agreement: this.id,
        })
        .then((res) => {
          // Added Code to order timeline
          let newTimeline = res.data.sort(function (x, y) {
            return x.timestamp - y.timestamp;
          });
          this.timeline = newTimeline;
          console.log("Time Line::::: ", this.timeline);
        });
    },

    getColor() {
      let status = this.agreement.xstate ? this.agreement.xstate.status : "";
      return helper.getAgreementStatusColor(status);
    },

    getStatusName() {
      let status = this.agreement.xstate ? this.agreement.xstate.status : "";
      return helper.getAgreementStatusName(status);
    },

    timelineChipColor(status) {
      return helper.timelineChipColor(status);
    },

    timelineChipText(status) {
      return helper.timelineChipText(status);
    },

    timelineChipIcon(status) {
      return helper.timelineChipIcon(status);
    },

    timelineChipIconColor(status) {
      return helper.timelineChipIconColor(status);
    },

    getDate(value) {
      var theDate = new Date(value);
      return theDate.toGMTString();
    },

    //Xstate Finishes
    //Updates interview document completed flag to true
    handleCompleteInterviewEvent() {
      this.completeInterviewBtnLoader = true;

      let currentInterview = this.interview.currentInterview;
      let val = {
        documents: [
          {
            ...currentInterview,
            completed: true,
          },
        ],
      };

      axios.post(`${constants.apiHost}/document`, val).then((res) => {
        let agreement = JSON.parse(JSON.stringify(this.agreement));
        helper.xstateMange(agreement);

        console.log(res);
        this.completeInterviewDialog = false;
        this.completeInterviewBtnLoader = false;
        this.interview.iteration++;
        delete this.interview.currentInterview;
        if (this.interview.iteration < this.agreement.servicePlans) {
          this.agreement.state.value = "setInterviewDate";
        } else {
          this.agreement.state.value = "final";
        }
      });
    },

    checkReschedule() {
      let interviewStartTime = moment(
        this.interview.currentInterview.startTime
      );
      let diff = interviewStartTime.diff(moment(), "days");
      if (diff >= 10) {
        this.$router.push(
          `/client-calendar/?uid=${this.agreement.id}&interview_id=${this.interview.currentInterview.id}`
        );
      } else {
        this.rescheduleDialog = true;
      }
    },

    saveQuestions() {},

    discardChanges() {},
  },
};
</script>
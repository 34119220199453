<template>
  <div>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10">
          <v-card class="elevation-6 mt-6">
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-row>
                  <v-col cols="24">
                    <v-card-text class="mt-0">
                      <v-row align="center" justify="center">
                        <v-col cols="8">
                          <v-img width="300" src="/ntts-logo.png"></v-img>
                        </v-col>
                      </v-row>
                      <h4 class="text-center">Reset Password</h4>
                      <v-form ref="resetPasswordForm">
                        <v-row align="center" justify="center">
                          <v-col cols="12" sm="8">
                            <v-text-field
                              v-model="resetPasswordForm.password"
                              label="Password"
                              :rules="passwordRule"
                              outlined
                              dense
                              color="#206a5d"
                              autocomplete="false"
                              type="password"
                            />

                            <v-text-field
                              v-model="resetPasswordForm.password_confirmation"
                              label="Re-enter password"
                              :rules="confirmPasswordRule"
                              outlined
                              dense
                              color="#206a5d"
                              autocomplete="false"
                              type="password"
                            />

                            <v-btn
                              color="#006c5d"
                              @click="resetPasswordFormSubmit"
                              class="white--text"
                              block
                              tile
                            >
                              Reset Password
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// Update Api
import axios from "axios";
import nttslogo from "/Users/eric/.node-red/projects/Vuetifystepper/uibuilder/agreement-template/src/assets/ntts-logo.png";
import constants from "../data/constants";
import router from "../router";

export default {
  data() {
    return {
      step: 1,
      logo: nttslogo,
      snackbar: false,
      snackbarMessage: "",
      resetPasswordForm: {
        password: null,
        password_confirmation: null,
        bucketName: constants.bucketName,
      },

      passwordRule: [(v) => !!v || "Password is required"],
      confirmPasswordRule: [
        (v) => !!v || "Password is required",
        (v) =>
          v == this.resetPasswordForm.password ||
          "The password confirmation does not match.",
      ],
    };
  },

  props: {
    source: String,
  },

  mounted() {
    console.log(
      decodeURIComponent(this.$route.fullPath.replace("/login?redirectTo=", ""))
    );
  },

  methods: {
    resetPasswordFormSubmit() {
      let token = this.$route.params.token;
      let resetPasswordToken = token.split(" ")[0];
      let email = token.split(" ")[1];

      if (this.$refs.resetPasswordForm.validate()) {
        let val = {
          resetPasswordToken,
          email,
          bucketName: this.resetPasswordForm.bucketName,
          newPassword: this.resetPasswordForm.password,
        };
        let me = this;

        axios
          .post(`${constants.host}/api/user/resetPassword`, val)
          .then(function (response) {
            // handle success
            let data = response.data;
            console.log(data);
            if ("status" in data) {
              me.snackbar = true;
              me.snackbarMessage = data.message;
              me.$router.push(`/login`);
            }
          })
          .catch(function (error) {
            me.snackbar = true;
            me.snackbarMessage = error;
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.v-application .rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
  color: #206a5d;
}
.v-application .rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}
</style>
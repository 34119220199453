<template>
  <!-- Props: companies, headers, search  -->

  <!-- Data Table  -->
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredLeads"
      :search="search"
      :loading="tableLoading"
      sort-by="name"
      class="elevation-1"
      no-results-text="No Results"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Crypto Leads</v-toolbar-title>
          <!-- search text field  -->
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-divider class="mx-4" inset vertical></v-divider>

          <v-select
            :items="cryptoLeadStatuses"
            v-model="searchItemByState"
            placeholder="Search Status"
            hide-details="auto"
            item-text="title"
            item-value="value"
            clearable
          ></v-select>

          <v-spacer></v-spacer>
          <!-- Created a new crypto lead redirect to the new crypto lead page -->
          <v-btn
            tile
            color="#206a5d"
            to="crypto-lead/create"
            style="color: #ffffff"
          >
            <v-icon left> mdi-plus </v-icon>
            New Crypto Lead
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.owners="{ item }">
        <template v-for="owner in item.owners">
          <v-chip class="ma-1" :key="owner.id" small label>
            {{ owner.name }}
          </v-chip>
        </template>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)" dark>
          {{ getStatusName(item.status) }}
        </v-chip>
      </template>

      <template v-slot:item.nextUpcomingEvent="{ item }">
        <span v-html="nextUpcomingEvent(item)"></span>
      </template>

      <template v-slot:item.actions="{ item }">
        <template v-if="item.isActive">
          <!-- redirect to edit crypto lead by using the crypto lead id id  -->
          <v-btn small icon :to="'crypto-lead/edit/' + item.id">
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>

          <v-btn small icon @click="noteTimeLine(item)">
            <v-icon small> mdi-note </v-icon>
          </v-btn>

          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>

        <template v-else>
          <v-icon small @click="deleteItem(item)"> mdi-refresh </v-icon>
        </template>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="refresh"> Reset </v-btn>
      </template>
    </v-data-table>

    <!-- Data table ends -->

    <!-- delete dialog  -->
    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h6"> Warning!! </v-card-title>
        <v-card-text>
          {{
            `Are you sure you would like to ${
              cryptoLead.isActive ? "delete" : "restore"
            } ${cryptoLead.name} crypto lead?`
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteItemConfirm(false)">
            No
          </v-btn>
          <v-btn color="green darken-1" text @click="deleteItemConfirm(true)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-navigation-drawer
      v-model="noteDialog"
      absolute
      right
      temporary
      width="700"
      clipped
    >
      <v-toolbar color="#006c5d" class="white--text">
        <v-toolbar-title>{{ cryptoLead.name }} Notes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="noteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- beginning timeline -->
      <v-container>
        <v-text-field
          v-model="eventInput"
          hide-details
          flat
          label="Note"
          outlined
          placeholder="Enter here..."
          @keydown.enter="comment"
        >
        </v-text-field>

        <v-btn
          class="my-3 white--text"
          color="#206a5d"
          @click="comment"
          :disabled="eventInput == ''"
        >
          Post
        </v-btn>

        <v-card flat fixed class="overflow-y-auto" max-height="500">
          <template v-for="event in timeline">
            <v-card class="my-3" :key="event.timestamp">
              <v-card-title class="mb-2">
                <v-row align="center" justify="space-between">
                  <div>
                    <v-avatar color="indigo mr-2" size="36">
                      <span class="white--text text-h6">
                        {{ event.userInitials }}
                      </span>
                    </v-avatar>
                  </div>
                  <div style="font-size: 12px">
                    <span v-text="event.timestamp"></span>
                  </div>
                </v-row>
              </v-card-title>

              <v-card-text>
                {{ event.text }}
              </v-card-text>
            </v-card>
          </template>
        </v-card>
      </v-container>

      <!-- End timeline -->
    </v-navigation-drawer>
    <!-- </v-dialog> -->
  </div>
</template>

<script>
// Update api
import axios from "axios";
import moment from "moment";
import helper from "../../helper/helper";
import constants from "../../data/constants";

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    noteDialog: false,
    tableLoading: false,
    cryptoLead: {},
    timeline: [],
    search: "",
    eventInput: "",
    // Main Header Name
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Email", value: "contactEmail" },
      { text: "Contact Number", value: "contactNumber" },
      { text: "Contact Name", value: "contactName" },
      { text: "Owner", value: "ownerNames", width: 150 },
      { text: "Date", value: "date", width: 110 },
      { text: "Next Upcoming Event", value: "nextUpcomingEvent" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false, width: 125 },
    ],
    cryptoLeads: [], // empty list for the results from the api call

    cryptoLeadStatuses: [
      { title: "N/A", value: 0, color: "grey" },
      { title: "Lead-Intro", value: 1, color: "amber lighten-2" },
      { title: "Contract Sent", value: 2, color: "orange" },
      // { title: "IR Companies", value: 3, color: "teal" },
      { title: "Proposal", value: 4, color: "green darken-2" },
      { title: "Closed-Won", value: 5, color: "green lighten-2" },
      { title: "Future Follow up", value: 6, color: "purple accent-2" },
      { title: "Dead-Deals", value: 7, color: "black" },
      {
        title: "INTRO EMAIL FROM SUPPORT",
        value: 8,
        color: "orange lighten-2",
      },
    ],
    searchItemByState: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },

    filteredLeads() {
      if (this.searchItemByState == null) {
        return this.cryptoLeads;
      }
      return this.cryptoLeads.filter((v) => {
        if (v.status == this.searchItemByState) {
          return true;
        }
        return false;
      });
    },

    userInitials() {
      let user = helper.userData();
      return user.firstName.charAt(0) + user.lastName.charAt(0);
    },
  },

  // calls refresh function which causes new companies to show immediately by calling the api
  mounted() {
    // calls refresh function to load companies as page loads
    this.refresh();
  },

  methods: {
    // refresh gets all of the companies that are in the DB
    refresh() {
      this.tableLoading = true;
      axios
        .post(`${constants.apiHost}/collectionDoc`, {
          model: "['cryptoLeads']",
        })
        .then((res) => {
          this.cryptoLeads = res.data;
          this.tableLoading = false;
        });
    },

    deleteItem(item) {
      this.cryptoLead = item;
      this.dialogDelete = true;
    },

    noteTimeLine(item) {
      this.cryptoLead = item;
      this.noteDialog = true;

      this.timeline = this.cryptoLead.events.sort((a, b) => {
        let timeA = moment(a.timestamp, "hh:mm:ss a, MM-DD-YYYY");
        let timeB = moment(b.timestamp, "hh:mm:ss a, MM-DD-YYYY");
        return timeA.isBefore(timeB) ? 1 : -1;
      });
    },

    // API call to delete cryptoLead
    deleteItemConfirm(type) {
      this.dialogDelete = false;
      let val = JSON.parse(JSON.stringify(this.cryptoLead));
      val.isActive = !val.isActive;
      this.cryptoLead = {};

      if (type) {
        this.tableLoading = false;

        axios
          .post(`${constants.apiHost}/document`, {
            documents: [val],
          })
          .then((res) => {
            if ("status" in res && res.status != "errors") {
              this.refresh();
            }
          });
      }
    },

    getColor(status) {
      return this.cryptoLeadStatuses.find((v) => v.value == status).color;
    },

    getStatusName(status) {
      return this.cryptoLeadStatuses.find((v) => v.value == status).title;
    },

    nextUpcomingEvent(item) {
      if (!item.calendarEvents || item.calendarEvents.length == 0) {
        return "NA";
      }

      let calendarEvents = item.calendarEvents
        .map((v) => {
          v.diff = moment(v.start).diff(moment(), "minutes");
          console.log(v.diff);
          return v;
        })
        .filter((v) => v.diff >= 0)
        .sort((a, b) => a.diff - b.diff);

      console.log(calendarEvents);

      if (calendarEvents[0]) {
        return `${calendarEvents[0].name} <br /> ${moment(
          calendarEvents[0].start
        ).format("MM/DD/YYYY HH:mm")}`;
      }

      return "NA";
    },

    comment() {
      if (this.eventInput.trim() != "") {
        let time = moment().format("hh:mm:ss a, MM-DD-YYYY");

        let newEvent = {
          text: this.eventInput,
          userId: helper.userData().id,
          userInitials: this.userInitials,
          timestamp: time,
        };

        this.cryptoLead.events.push(newEvent);

        this.timeline = this.cryptoLead.events.sort((a, b) => {
          let timeA = moment(a.timestamp, "hh:mm:ss a, MM-DD-YYYY");
          let timeB = moment(b.timestamp, "hh:mm:ss a, MM-DD-YYYY");
          return timeA.isBefore(timeB) ? 1 : -1;
        });

        let submit = {
          documents: [this.cryptoLead],
        };

        axios.post(`${constants.apiHost}/document`, submit).then((res) => {});
      }
      this.eventInput = "";
    },
  },
};
</script>
import axios from "axios";
import router from "../router";
import constants from '../data/constants'

export default {
    userData() {
        let userData = localStorage.getItem("user_data");
        if (userData) {
            userData = JSON.parse(userData);
            userData.role = userData.role ?? 'user';
        } else {
            userData = null;
        }
        return userData;
    },

    hasRouteAccess(route) {
        let userData = this.userData();
        let allRoutes = router.options.routes;
        let findRoute = allRoutes.find(v => v.path == route);
        if (findRoute && findRoute.meta && findRoute.meta.role && userData && findRoute.meta.role.includes(userData.role)) {
            return true;
        }
        return false;
    },

    makeid(length) {
        let result = "";
        let characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        return result;
    },

    getAgreementStatusColor(status) {
        if (typeof status === "undefined") return "grey";
        else if (status === "CREATEAGREEMENT") return "amber lighten-2";
        else if (status === "CLIENTSIGN") return "orange";
        else if (status === "HOSTSIGN") return "orange";
        else if (status === "ONBOARDING") return "blue";
        else if (status === "SETINTERVIEWDATE") return "teal";
        else if (status === "INTERVIEWCOMPLETED") return "green";
        else if (status === "FINISH") return "grey";
        else return "grey";
    },

    getAgreementStatusName(status) {
        if (typeof status === "undefined") return "Agreement Saved";
        else if (status === "CREATEAGREEMENT") return "Agreement in Progress";
        else if (status === "CLIENTSIGN") return "Agreement with Client";
        else if (status === "HOSTSIGN") return "Agreement with NTTS";
        else if (status === "ONBOARDING") return "Client Onboarding";
        else if (status === "SETINTERVIEWDATE") return "Interview Scheduling";
        else if (status === "INTERVIEWCOMPLETED") return "Interview Scheduled";
        else if (status === "FINISH") return "Completed";
        else return "Agreement Saved";
    },

    timelineChipColor(status) {
        if (status === "Step-0") return "blue";
        else if (status === "Step-1") return "amber lighten-2";
        else if (status === "Step-1.5") return "amber lighten-2";
        else if (status === "Step-2") return "amber lighten-2";
        else if (status === "Step-2.5") return "amber lighten-2";
        else if (status === "Step-3") return "amber lighten-2";
        else if (status === "Step-4") return "green darken-1";
        else if (status === "Step-5") return "green darken-3";
        else return "red";
    },

    timelineChipText(status) {
        if (status === "Step-0") return "Created";
        else if (status === "Step-1") return "Signature";
        else if (status === "Step-1.5") return "Signature";
        else if (status === "Step-2") return "Signature";
        else if (status === "Step-2.5") return "Signature";
        else if (status === "Step-3") return "Signature";
        else if (status === "Step-4") return "Interview";
        else if (status === "Step-5") return "Interview Completed";
        else return "NA";
    },

    timelineChipIcon(status) {
        if (status === "Step-0") return "mdi-draw";
        else if (status === "Step-1") return "mdi-draw";
        else if (status === "Step-1.5") return "mdi-draw";
        else if (status === "Step-2") return "mdi-draw";
        else if (status === "Step-2.5") return "mdi-draw";
        else if (status === "Step-3") return "mdi-draw";
        else if (status === "Step-4") return "mdi-broadcast";
        else if (status === "Step-5") return "mdi-check";
        else return "NA";
    },

    timelineChipIconColor(status) {
        if (status === "Step-0") return "blue";
        else if (status === "Step-1") return "amber lighten-2";
        else if (status === "Step-1.5") return "amber lighten-2";
        else if (status === "Step-2") return "amber lighten-2";
        else if (status === "Step-2.5") return "amber lighten-2";
        else if (status === "Step-3") return "amber lighten-2";
        else if (status === "Step-4") return "green darken-1";
        else if (status === "Step-5") return "green darken-3";
        else return "NA";
    },

    clientTypes: [
        { name: 'Lead', 'id': 'lead' },
        { name: 'Crypto Lead', 'id': 'crypto-lead' },
    ],

    xstateMange(agreement) {
        let to = "";
        let xstate = agreement.xstate;

        if (xstate.status == "CREATEAGREEMENT") {
            if (agreement.clientFirst == true) {
                xstate.status = "CLIENTSIGN";
            } else {
                xstate.status = "HOSTSIGN";
            }
        } else if (xstate.status == "CLIENTSIGN") {
            xstate.data.clientSigned = true;
            if (xstate.data.hostSigned == true) {
                xstate.status = "ONBOARDING";
            } else {
                xstate.status = "HOSTSIGN";
            }
        } else if (xstate.status == "HOSTSIGN") {
            xstate.data.hostSigned = true;
            if (xstate.data.clientSigned == true) {
                xstate.status = "ONBOARDING";
            } else {
                xstate.status = "CLIENTSIGN";
            }
        } else if (xstate.status == "ONBOARDING") {
            xstate.status = "SETINTERVIEWDATE";
        } else if (xstate.status == "SETINTERVIEWDATE") {
            xstate.data.iteration += 1;
            xstate.status = "INTERVIEWCOMPLETED";
        } else if (xstate.status == "INTERVIEWCOMPLETED") {
            if (xstate.data.duration > xstate.data.iteration) {
                xstate.status = "SETINTERVIEWDATE"
            } else {
                xstate.status = "FINISH"
            }
        }

        axios.post(`${constants.apiHost}/agreementXstate`, xstate).then((res) => {
            console.log(res);
        });

        if (constants.test_env) {
            to = constants.test_env_email;
        }
        let url = "";
        let mailPayload = [];
        let from = constants.from_email;
        let attachments = [{
            "content": agreement.document.split(',')[1],
            "filename": "Agreement",
            "type": "application/pdf",
            "disposition": "attachment"
        }];
        let milestone = {};

        if (xstate.status == "CLIENTSIGN") {
            // Email To Client For Signature
            url = `${constants.frontEndHost}/signature-agreement-for-guest?uid=${agreement.id}&step=1`;
            mailPayload.push({
                "to": agreement.client.email,
                "from": from,
                "subject": "Agreement (New To The Street)",
                "text": "Hello, Please click the link to sign the agreement",
                "html": "Hello " + agreement.client.name + ", <br><br>Thank you for choosing New To The Street for your valued media exposure. We are confident that you will be extremely satisfied with the media and the network exposure that we offer.<br><br> Enclosed, please find our production and broadcasting Agreement. If this Agreement is acceptable to you, please follow the below link to sign. <br><br> <a href='" + url + "'>Click here to access the agreement</a><br><br>Once we receive the signed Agreement from you, you will recieve the option to select your first interview date (within 48 hours). <br><br> If you have any questions, please don't hesitate to contact us. <br><br> Sincerly,<br>Michelle",
                "attachments": attachments,
                "delay" : 0,
                "date" : agreement
            });


            milestone = {
                "agreement": agreement.id,
                "code": "Step-1",
                "collectionName": "milestones",
                "description": "Awaiting " + agreement.client.name + " to sign the document",
                "id": this.makeid(20),
                "timestamp": Date.now()
            };

        } else if (xstate.status == "HOSTSIGN") {
            // Email to Host for Signature 
            url = `${constants.frontEndHost}/signature-agreement?uid=${agreement.id}&step=2`;
            mailPayload.push({
                "to": constants.host_email,
                "from": from,
                "subject": "Agreement (" + agreement.agreementName + ")",
                "text": "Hello, Please click the link to sign the agreement",
                "html": "Hello, <br><br> Please review the agreement " + agreement.agreementName + ". <br><br><a href='" + url + "'>Click here to access the agreement</a><br><br> Thank you,<br>Michelle",
                "attachments": attachments
            });

            milestone = {
                "agreement": agreement.id,
                "code": "Step-2",
                "collectionName": "milestones",
                "description": "Awaiting New to the Street Managing Partners to sign the document",
                "id": this.makeid(20),
                "timestamp": Date.now()
            };
        } else if (xstate.status == "ONBOARDING") {
            // Finalized Agreement: Email to Client with updated Signed Document
            mailPayload.push({
                "to": agreement.client.email,
                "from": from,
                "subject": "Finalized Agreement (" + agreement.agreementName + ")",
                "text": "Hello, Please click the link to sign the agreement",
                "html": "Hello <br><br> The " + agreement.agreementName + " agreement has been finalized.  A copy of the finalized agreement has been attached to this email <br><br> Thank you,<br>Michelle",
                "attachments": attachments
            });

            // Finalized Agreement: Email to Host with updated Signed Doccument 
            mailPayload.push({
                "to": constants.host_email,
                "from": from,
                "subject": "Agreement Finalized (New To The Street)  ",
                "text": "Hello, Please click the link to sign the agreement",
                "html": "Hello " + agreement.client.name + "<br><br> The " + agreement.agreementName + " agreement has been finalized.  A copy of the finalized agreement has been attached to this email <br><br> Thank you,<br>Michelle",
                "attachments": attachments
            });

            // Onboarding Email to Client 
            // Delay 6 hours
            url = `${constants.frontEndHost}/client-form?uid=${agreement.id}`
            mailPayload.push({
                "to": agreement.client.email,
                "from": from,
                "subject": "Onboarding (New To The Street)",
                "text": "Hello, Please click the link to sign the agreement",
                "html": "Hello " + agreement.client.name + ",<br><br>  We look forward to working with you to fully maximize the exposure and awareness goals for Apple on our massive platform.<br><br> Our excellent client support team is available 24/7 to help you with any questions or concerns you may have. Our client's satisfaction is a priority to us, so please feel free to share any and all feedback to ensure a high-quality process. The team always take's our client's opinions into consideration and will do their best to implements solutions for you.<br><br><a href='" + url + "'>Click here to start your onboarding</a> <br><br>Looking forward to starting the series!<br><br>Best,<br>Michelle",
                "delay": 60 * 60 * 6,
            });

            milestone = {
                "agreement": agreement.id,
                "code": "Step-5",
                "collectionName": "milestones",
                "description": "Onboarding has begun",
                "id": this.makeid(20),
                "timestamp": Date.now()
            };


        } else if (xstate.status == "SETINTERVIEWDATE") {
            // Schedule Interview Email to client 
            // Delay by 24 hours or if we can send it at 9 A.M. the following morning 
            url = `${constants.frontEndHost}/client-calendar?uid=${agreement.id}`
            let delay = 60 * 60 * 24;
            if (agreement.xstate.data.iteration != 0) {
                delay = 60 * 60 * 24 * 10;
            }

            mailPayload.push({
                "to": agreement.client.email,
                "from": from,
                "subject": "Schedule Interview " + (agreement.xstate.data.iteration + 1) + "/" + agreement.xstate.data.duration + " with New To The Street",
                "text": "Hello, Please click the link to sign the agreement",
                "html": "Hello " + agreement.client.name + ",  <br><br>Please click the link below to schedule your interview:<br><br><a href='" + url + "'>Click here to schedule</a><br><br>If you have any questions, please don't hesitate to contact us.<br><br> Thank you,<br>Michelle",
                'delay': delay
            });

            if (agreement.xstate.data.iteration != 0) {
                milestone = {
                    "agreement": agreement.id,
                    "code": "Step-4",
                    "collectionName": "milestones",
                    "description": "Interview " + (agreement.xstate.data.iteration) + "/" + agreement.xstate.data.duration + " Completed",
                    "id": this.makeid(20),
                    "timestamp": Date.now()
                };
            }

        } else if (xstate.status == "INTERVIEWCOMPLETED") {
            milestone = {
                "agreement": agreement.id,
                "code": "Step-4",
                "collectionName": "milestones",
                "description": "Interview " + (agreement.xstate.data.iteration) + "/" + agreement.xstate.data.duration + " scheduled by " + agreement.client.name,
                "id": this.makeid(20),
                "timestamp": Date.now()
            };
        } else if (xstate.status == "FINISH") {
            milestone = {
                "agreement": agreement.id,
                "code": "Step-5",
                "collectionName": "milestones",
                "description": "Agreement Completed",
                "id": this.makeid(20),
                "timestamp": Date.now()
            };
        }

        mailPayload.forEach(v => {
            if (!('delay' in v)) {
                v.delay = 0;
            }
            if (constants.test_env) {
                v.to = constants.test_env_email;
                v.delay = 30;
            }
            axios.post(`${constants.apiHost}/googleTaskEmail`, v).then(res => {
                console.log(res);
            });
            // axios.post(`${constants.apiHost}/sendmail`, v).then(res => {
            //     console.log(res);
            // })
        });

        axios.post(`${constants.apiHost}/document`, {
            documents: [milestone]
        }).then((res) => {
            console.log(res);
        });
    },

}
<template>
  <div>
    <v-stepper v-model="step" alt-labels>
      <!-- STEPPER HEADER -->

      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          step="1"
          :editable="step != 6"
          color="#006c5d"
          class="white-text"
        >
          <small>Contracting Company Details</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 2"
          step="2"
          :editable="step != 6"
          color="#006c5d"
          class="white-text"
        >
          <small>Client and Point of Contact Details</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 3"
          step="3"
          :editable="step != 6"
          color="#006c5d"
          class="white-text"
        >
          <small>Service Type</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 4"
          step="4"
          :editable="step != 6"
          color="#006c5d"
          class="white-text"
        >
          <small>Agreement Overview</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 5"
          step="5"
          color="#006c5d"
          class="white-text"
        >
          <small>Confirm Agreement Document</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="6" color="#006c5d" class="white-text">
          <small>Finish</small>
        </v-stepper-step>
      </v-stepper-header>

      <!-- Step 1 [Contracting Company Details] -->
      <v-stepper-content step="1">
        <form1
          :items="form"
          :companies="companies"
          @updateStep="step = $event"
          @refetchCompanies="refetchCompanies"
        />
      </v-stepper-content>

      <!-- STEP 2 [Client and Point of Contact Details] -->
      <v-stepper-content step="2">
        <form2 :items="form" @updateStep="step = $event" />
      </v-stepper-content>

      <!-- STEP 3 [Services Rendered] -->
      <v-stepper-content step="3">
        <form3
          :items="form"
          :companies="companies"
          @updateStep="step = $event"
        />
      </v-stepper-content>

      <!-- STEP 4 [Agreement Overview]-->
      <v-stepper-content step="4">
        <v-card class="mb-4" elevation="0">
          <v-row>
            <v-col>
              <overview :form="form" :companies="companies" />
            </v-col>
          </v-row>

          <v-btn text @click="step = 3" class="mr-2"> Back </v-btn>

          <v-btn
            color="#006c5d"
            class="white--text"
            :loading="saveBtnLoader"
            @click="
              () => {
                sendFormData();
                generatePdf();
              }
            "
          >
            Save
          </v-btn>
        </v-card>
      </v-stepper-content>

      <!-- STEP 5 [Confirm Agreement Document] -->
      <v-stepper-content step="5">
        <preview
          :pdf="pdfSrc"
          :items="form"
          :companies="companies"
          :submitDate="submitDate"
          :submitTime="submitTime"
          @updateStep="step = $event"
        />
        <!-- Snackbar -->
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          bottom
          color="success"
          outlined
        >
          {{ step4snackbartext }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-stepper-content>

      <!-- STEP 6 [Finish] -->
      <v-stepper-content step="6">
        <div v-if="form.clientFirst === true">
          <v-alert :value="true" type="success">
            Thank you, an email has been sent to
            <span> {{ form.client.name }} ({{ form.client.email }}) </span>.
          </v-alert>
        </div>
        <div v-else>
          <v-alert :value="true" type="success">
            <span
              >Thank you, an email has been sent to the managing partners at
              NTTS</span
            >.
          </v-alert>
        </div>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
// updated api

import axios from "axios";
import agreementsTableData from "@/data/agreementsTableData";
import helper from "../helper/helper";
import moment from "moment";
import constants from "../data/constants";

export default {
  components: {
    form1: require("@/components/steps/Form1.vue").default,
    form2: require("@/components/steps/Form2.vue").default,
    form3: require("@/components/steps/Form3.vue").default,
    overview: require("@/components/steps/Overview.vue").default,
    preview: require("@/components/steps/Preview.vue").default,
  },

  created() {
    this.fetchCompanies();
  },

  data() {
    return {
      step: this.$route.query.step ?? 1,
      saveBtnLoader: false,
      form: {
        id: null,
        contractingCompany: "29f0cb2b-6d40-4e1a-bfe7-21be7a73fc6f",
        collectionName: "agreements",
        clientSignature: null,
        clientInitial: null,
        contractSignature: null,
        contractInitial: null,

        // Step 1
        categories: [],
        description: null,
        agreementName: null,
        date: null,
        signDate: null,
        company: null,

        // Step 2
        client: {
          email: null,
          name: null,
          number: null,
          role: null,
        },
        secondaryClient: {
          name: null,
          email: null,
          number: null,
          role: null,
        },

        // Step 3
        restrictedShares: null,
        initialPayment: null,
        monthlyPayment: null,
        editCompensation: null,
        servicePlans: null,
        tableData: [],

        // Step 5
        clientFirst: false,
        submitDate: null,
        submitTime: null,

        // submitDate in utc string format

        clientInitial: null,
        contractInitial: null,
        salesRep: {
          id: "",
          name: "",
        },
        xstate: {
          data: {
            agreementId: "",
            duration: "",
            iteration: 0,
          },
          status: "CREATEAGREEMENT",
        },
        document: null,
      },
      pdfSrc: null,
      snackbar: false,
      step4snackbartext: "Agreement Saved to Database",
      timeout: 3000,
      companies: [],
      submitDate: null,
      submitTime: null,

      allAgreements: [],
    };
  },

  mounted() {
    console.log(this.uid);
    if (this.$route.params.id) {
      this.fetchAgreement();
    } else {
      this.form.id = this.makeid(20);
      this.form.tableData = JSON.parse(
        JSON.stringify(agreementsTableData)
      ).sort((a, b) => a.order - b.order);
      console.log(this.form.tableData);
      this.form.salesRep = {
        id: helper.userData().id,
        name: helper.userData().name,
      };
      console.log(this.form);
    }
  },

  computed: {
    uid() {
      let userData = localStorage.getItem("user_data");
      userData = JSON.parse(userData);
      return userData.id;
    },
  },

  methods: {
    fetchCompanies() {
      axios
        .post(`${constants.apiHost}/collectionDoc`, {
          model: "['companies']",
        })
        .then((res) => {
          this.companies.push(
            {
              id: "new-company",
              name: "Create New Company",
              isActive: true,
            },
            ...res.data
          );
          if (this.$route.query.company) {
            this.form.company = this.$route.query.company;
            let company = this.companies.find((v) => v.id == this.form.company);

            if (company) {
              this.form.agreementName = `${company.name} 1`;
            }
            // This counts the amount of agreements a company has.  So that when we create a default agreement name
            // there is a default number.  This will need to be updated when more companies are added as its not efficient
            axios
              .post(`${constants.apiHost}/collectionDoc`, {
                model: "['agreements']",
              })
              .then((res) => {
                this.allAgreements = res.data;

                let companysAgreements = this.allAgreements.filter(
                  (v) => v.company == this.form.company
                );
                this.form.agreementName = `${company.name} ${
                  companysAgreements.length + 1
                }`;
              });
          }
        });
    },

    refetchCompanies(id) {
      axios
        .post(`${constants.apiHost}/collectionDoc`, {
          model: "['companies']",
        })
        .then((res) => {
          this.companies.push(
            {
              id: "new-company",
              name: "Create New Company",
              isActive: true,
            },
            ...res.data
          );
          this.form.company = id;
        });
    },

    fetchAgreement() {
      axios
        .get(`${constants.apiHost}/document`, {
          params: {
            id: [this.$route.params.id],
          },
        })
        .then((res) => {
          let data = res.data[0];
          this.form = data;

          if (this.form.submitDate != null) {
            let date = moment(this.form.submitDate);
            this.submitDate = date.format("YYYY-MM-DD");
            this.submitTime = date.format("HH:mm");
          }

          if (this.step == 5) {
            let self = this;
            setTimeout(function () {
              self.generatePdf();
              self.snackbar = true;
            }, 2000);
          }
        });
    },

    makeid(length) {
      let result = "";
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    getFormattedForm() {
      for (let i = 0; i < services.length; i++) {
        const found = this.form.tableData.find((e) => e.name == services[i]);

        data.tableData[i] = {
          id: i,
          required: found ? true : false,
          serviceType: services[i],
          serviceDuration: found ? found.duration : 0,
        };
      }
      return data;
    },

    sendForm() {
      console.log(this.form);
      this.step = 5;
    },

    // change function name to something more appropriate
    sendFormData() {
      this.saveBtnLoader = true;
      this.form.xstate.data.agreementId = this.form.id;
      this.form.xstate.data.duration = this.form.servicePlans;

      // Saved Data step 4 before preview
      let val = {
        documents: [this.form],
      };
      if (this.$route.params.id) {
        // update
        axios.post(`${constants.apiHost}/document`, val).then((res) => {
          this.step = 5;
          this.saveBtnLoader = false;
          this.snackbar = true;
        });
      } else {
        axios.post(`${constants.apiHost}/document`, val).then((res) => {
          this.$router.push(`/edit-agreement/${this.form.id}&step=5`);
          this.step = 5;
          this.saveBtnLoader = false;
          this.snackbar = true;
        });
      }
    },

    generatePdf() {
      console.log("generate pdf");
      let company = this.companies.find((v) => v.id == this.form.company);

      console.log(company);

      let val = {
        ...this.form,
        contractingCompany: "New to the Street Group LLC",
        clientCompanyName: company.name,
        clientAddressCity: company.city,
        clientAddressCountry: company.country,
        clientAddressLine1: company.addressLine1,
        clientAddressLine2: company.addressLine2,
        clientAddressState: company.state,
        clientAddressZipCode: company.zip,
        clientType: company.type,
        commonStock: company.commonStock,
        clientMainContactRole: this.form.client.role,
        clientMainContactEmail: this.form.client.email,
        clientMainContactMobilePhone: this.form.client.number,
        clientMainContactName: this.form.client.name,
        clientSignature: null,
        contractSignature: null,
        generateInvoice: false,
        submit: null,
        type: "agreement",
      };

      console.log(val);
      // /previewPDF
      axios.post(`${constants.apiHost}/previewPDF`, val).then((res) => {
        this.pdfSrc = "data:application/pdf;base64," + res.data;
        this.form.document = this.pdfSrc;
        //this.step = 5;
        console.log(this.pdfSrc, "Log of pdfSrc");
      });
    },
  },

  watch: {
    step() {
      console.log(`${this.step} submitted`);
      console.log(this.form);
    },
  },
};
</script>

<style scoped>
.v-input {
  margin-top: 0;
}

.v-stepper__step {
  flex: 1 1;
}

small {
  text-align: center;
}
</style>

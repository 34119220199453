<template>
  <div>
    <div>
      <VueSignaturePad
        class="signature"
        :class="[emptySignError ? 'signature--error' : 'signature--normal']"
        width="100%"
        ref="signaturePad"
        :options="options"
      />
      <p v-if="emptySignError" class="red--text">Fill the signature!</p>
    </div>

    <div class="buttons mt-2">
      <v-btn @click="undo" depressed small> Undo </v-btn>
      <v-btn @click="clear" depressed small> Clear </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: Number,
  },
  data: () => ({
    options: {
      penColor: "#000",
    },
    emptySignError: false,
  }),
  mounted() {
    this.$nextTick(function () {
      this.$refs.signaturePad.resizeCanvas();
    });
  },
  watch: {
    step() {
      if (this.step === 2) {
        this.$nextTick(function () {
          this.$refs.signaturePad.resizeCanvas();
        });
      }
    },
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.emptySignError = false;
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.emptySignError = true;
      }
      return { isEmpty, signature: data };
    },
  },
};
</script>

<style>
.signature {
  border: solid 3px transparent;
  border-radius: 5px;
  aspect-ratio: 3 / 1;
}

.signature--normal {
  border-color: #8d8d8d;
}

.signature--error {
  border-color: #f44336;
}

.buttons {
  display: flex;
  column-gap: 8px;
}
</style>

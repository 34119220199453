<template>
  <!-- Props: companies, headers, search  -->

  <!-- Data Table  -->
  <div>
    <v-data-table
      :headers="headers"
      :items="companies"
      :search="search"
      :loading="tableLoading"
      sort-by="name"
      class="elevation-1"
      no-results-text="No Results"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Clients</v-toolbar-title>
          <!-- search text field  -->
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <!-- Created a new company redirect to the new company page -->
          <v-btn tile color="#006c5d" to="companyprofile" class="white--text">
            <v-icon left> mdi-plus </v-icon>
            New Client
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <template v-if="item.isActive">
          <!-- redirect to edit company by using the company id  -->
          <v-btn small icon class="mr-2" :to="'edit-companyprofile/' + item.id">
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>

          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>

        <template v-else>
          <v-icon small @click="deleteItem(item)"> mdi-refresh </v-icon>
        </template>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="refresh"> Reset </v-btn>
      </template>
    </v-data-table>

    <!-- Data table ends -->

    <!-- delete dialog  -->
    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h6"> Warning!! </v-card-title>
        <v-card-text>
          {{
            `Are you sure you would like to ${
              company.isActive ? "delete" : "restore"
            } ${company.name} company?`
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteItemConfirm(false)">
            No
          </v-btn>
          <v-btn color="green darken-1" text @click="deleteItemConfirm(true)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Update Api
import axios from "axios";
import constants from '../data/constants';

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    tableLoading: false,
    company: {},
    search: "",
    // Main Header Name
    headers: [
      {
        text: "Company Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Email", value: "contactEmail" },
      { text: "Contact Number", value: "contactNumber" },
      { text: "Contact Name", value: "contactName" },
      { text: "Industry", value: "industry" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    companies: [], // empty list for the results from the api call
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  // calls refresh function which causes new companies to show immediately by calling the api
  mounted() {
    // calls refresh function to load companies as page loads
    this.refresh();
  },

  methods: {
    // refresh gets all of the companies that are in the DB
    refresh() {
      this.tableLoading = true;
      axios
        .post(`${constants.apiHost}/collectionDoc`, {
          model: "['companies']",
        })
        .then((res) => {
          this.companies = res.data;
          this.tableLoading = false;
        });
      console.log("this.companies: ", this.companies);
    },

    deleteItem(item) {
      console.log(item);
      this.company = item;
      this.dialogDelete = true;
    },
    // API call to delete company
    deleteItemConfirm(type) {
      this.dialogDelete = false;
      let val = JSON.parse(JSON.stringify(this.company));
      val.isActive = !val.isActive;
      this.company = {};

      if (type) {
        this.tableLoading = false;

        axios
          .post(`${constants.apiHost}/document`, {
            documents: [val],
          })
          .then((res) => {
            if ("status" in res && res.status != "errors") {
              this.refresh();
            }
          });
      }
    },
  },
};
</script>
<template>
  <div>
    <v-row>
      <v-col class="pa-4" cols="12" lg="3" md="6" sm="12">
        <interviewee-card :user="userData"></interviewee-card>
      </v-col>
      <template v-if="agreement">
        <v-col
          cols="12"
          class="pt-7"
          lg="4"
          md="6"
          sm="12"
          v-if="interview.currentInterview"
        >
          <v-card
            elevation="2"
            shaped
            :loading="loading"
            class="mx-auto"
            max-width="374"
          >
            <v-toolbar color="#006c5d" class="white--text" dense>
              <v-toolbar-title>Upcoming Interview </v-toolbar-title>

              <v-spacer></v-spacer>
            </v-toolbar>

            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>

            <v-img height="200" :src="anchorImg"></v-img>

            <v-card-title>
              <v-icon class="pa-3"> mdi-account-voice </v-icon>
              Anchor: {{ interview.currentInterview.anchor }}
            </v-card-title>

            <v-card-title>
              <v-icon class="pa-3"> mdi-account-voice </v-icon>
              Interviewee:
              {{ interview.currentInterview.interviewee }}
            </v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-title>
              <v-icon class="pa-3"> mdi-calendar-clock </v-icon>Interview
              Scheduled on:
            </v-card-title>

            <v-card-text class="ml-14">
              <v-chip color="teal white--text">
                {{ interviewTime }}
              </v-chip>
            </v-card-text>

            <!-- will need to add back in the future  -->
            <v-card-actions>
              <v-btn color="red" text @click="checkReschedule">
                Reschedule
              </v-btn>
            </v-card-actions>
            <v-divider class="mx-4"></v-divider>

            <v-card-actions>
              <v-card-title>Interview Questions:</v-card-title>

              <v-spacer></v-spacer>

              <v-btn icon @click="show = !show">
                <v-icon>
                  {{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition v-if="interview.currentInterview">
              <div v-show="show">
                <v-divider></v-divider>
                <v-card max-width="650">
                  <v-expansion-panels accordion>
                    <v-expansion-panel
                      v-for="(item, index) in interview.currentInterview
                        .questions"
                      :key="index"
                    >
                      <v-expansion-panel-header>
                        <h3>Question: {{ index + 1 }}</h3>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-textarea
                          name="question"
                          :value="item.response"
                          hint="Type the question you want to be asked"
                          prepend-inner-icon="mdi-message-question-outline"
                          class="mx-2"
                          rows="2"
                        ></v-textarea>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-card-actions>
                    <v-btn
                      color="deep-purple lighten-2"
                      text
                      @click="saveQuestions"
                    >
                      Save Changes
                    </v-btn>
                    <v-btn
                      color="deep-purple lighten-2"
                      text
                      @click="discardChanges"
                    >
                      Discard
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </template>
    </v-row>

    <v-dialog v-model="rescheduleDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h6"> Warning!! </v-card-title>
        <v-card-text>
          Please contact your representative to reschedule your interview
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="rescheduleDialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Update Api
import axios from "axios";
import helper from "../helper/helper";
import moment from "moment";
import constants from '../data/constants';
export default {
  name: "Home",
  components: {
    "interviewee-card": require("@/components/dashboard/IntervieweeCard.vue")
      .default,
    "upcoming-interview":
      require("@/components/dashboard/upcomingInterview.vue").default,
    timeline: require("@/components/dashboard/timeline.vue").default,
    "press-releases": require("@/components/dashboard/pressRelease.vue")
      .default,
  },

  data() {
    return {
      timeline: null,
      agreementId: null,
      agreement: null,
      loading: false,
      show: false,
      interview: {},
      rescheduleDialog: false,
    };
  },

  methods: {
    timelineChipColor(status) {
      return helper.timelineChipColor(status);
    },

    timelineChipText(status) {
      return helper.timelineChipText(status);
    },

    // Added 10/27
    timelineChipIcon(status) {
      return helper.timelineChipIcon(status);
    },
    // Added 10/27
    timelineChipIconColor(status) {
      return helper.timelineChipIconColor(status);
    },

    getDate(value) {
      var theDate = new Date(value);
      return theDate.toLocaleString();
    },

    saveQuestions() {},

    discardChanges() {},

    checkReschedule() {
      let interviewStartTime = moment(
        this.interview.currentInterview.startTime
      );
      let diff = interviewStartTime.diff(moment(), "days");
      if (diff >= 10) {
        this.$router.push(
          `/client-calendar/?uid=${this.agreement.id}&interview_id=${this.interview.currentInterview.id}`
        );
      } else {
        this.rescheduleDialog = true;
      }
    },
  },

  created() {
    let val = {
      userId: this.userData.id,
    };
    axios
      .post(`${constants.apiHost}/dashboardProfile`, val)
      .then((res) => {
        this.agreement = res.data.agreement[0];
        this.agreementId = this.agreement.id;
        this.interview = res.data.interview;
      });
  },

  computed: {
    // this creates the uid variable from local strage when user_data changes
    // This will always get the current logged in user uid
    userData() {
      let userData = localStorage.getItem("user_data");
      return JSON.parse(userData);
    },

    anchorImg() {
      let img = "";
      if (this.interview != {} && this.interview.currentInterview) {
        if (this.interview.currentInterview.anchor == "Anna Berry") {
          img = "/annaberry.png";
        } else if (this.interview.currentInterview.anchor == "Jane King") {
          img = "/janeking.jpeg";
        }
      }
      return img;
    },

    interviewTime() {
      if (this.interview.currentInterview) {
        return moment(this.interview.currentInterview.startTime).format(
          "MM/DD/YYYY HH:mm:ss"
        );
      }
      return "";
    },
  },
};
</script>


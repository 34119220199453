<template>
  <div class="my-4">
    <v-container>
      <v-row justify="space-between">
        <v-col cols="4"> </v-col>

        <v-col v-if="$route.params.id" cols="8" class="text-right">
          <!-- Passed the Lead ID to the leads calendar page on the click of the button  -->
          <v-btn
            :to="`/leadscalendar?leadName=${leadId}`"
            class="mr-4 white--text"
            color="#206a5d"
          >
            Schedule Event
          </v-btn>

          <v-btn
            color="#206a5d"
            @click="dialog = true"
            class="mr-4 white--text"
          >
            Notes
          </v-btn>

          <v-btn
            color="#206a5d"
            @click="attachmentDialog = true"
            class="mr-4 white--text"
          >
            attachments
          </v-btn>

          <v-btn
            :loading="createAgreementLoading"
            @click="createAgreement"
            class="mr-4 white--text"
            color="#206a5d"
          >
            Create Agreement
          </v-btn>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#206a5d"
                class="white--text"
                v-bind="attrs"
                v-on="on"
              >
                Status
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in leadStatuses"
                :key="index"
                link
                @click="updateLeadStatus(item.value)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="teal lighten-4">
      <h4 class="mb-2">Sources</h4>

      <v-row>
        <v-col cols="6" class="pb-0">
          <v-text-field
            v-model="companySearch"
            label="Search"
            clearable
            persistent-hint
            placeholder="Enter the crypto currency name"
          ></v-text-field>
        </v-col>

        <v-col cols="12" v-if="companySearch != ''">
          <v-btn
            :href="`https://www.otcmarkets.com/stock/${companySearchUrl}/profile`"
            target="_blank"
          >
            https://www.otcmarkets.com/stock/{{ companySearchUrl }}/profile
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="teal lighten-5">
      <v-row justify="space-between">
        <v-col cols="4">
          <v-text-field v-model="companyName" label="Company Name" dense>
          </v-text-field>
        </v-col>

        <v-col cols="8" class="text-right">
          <v-img max-height="150" max-width="250" :src="this.companyLogo">
          </v-img>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="teal lighten-4">
      <h4 class="mb-2">Contact Person Details</h4>
      <br />
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="companyContactName"
            label="Contact Name"
            required
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="companyContactNumber"
            label="Contact Number"
            required
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="companyContactEmail"
            label="Contact Email"
            required
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="companyContactRole"
            label="Contact Role"
            required
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-select
            v-model="owners"
            :items="employees"
            :menu-props="{ maxHeight: '400' }"
            label="Select Owner"
            multiple
            dense
            chips
            item-value="id"
            item-text="name"
          ></v-select>
        </v-col>

        <v-col cols="6">
          <v-menu
            ref="datePicker"
            v-model="datePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            dense
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="leadDate"
              @input="datePicker = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>

    <v-row> </v-row>
    <br />
    <v-container class="teal lighten-5">
      <h4 class="mb-2">Company Address</h4>
      <br />

      <v-row>
        <v-col cols="4">
          <v-text-field v-model="companyCity" label="City" dense>
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field v-model="companyState" label="State" dense>
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field v-model="companyZip" label="Zipcode" dense>
          </v-text-field>
        </v-col>

        <v-col cols="5">
          <v-text-field v-model="companyCountry" label="Country" dense>
          </v-text-field>
        </v-col>

        <v-col cols="5">
          <v-text-field v-model="companyAddress" label="Address" dense>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="teal lighten-4">
      <h4 class="mb-2">Socail Media</h4>
      <br />
      <v-row>
        <v-col cols="4">
          <v-text-field v-model="companyFacebook" label="Facebook" dense>
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field v-model="companyTwitter" label="Twitter" dense>
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field v-model="companyLinkedin" label="Linkedin" dense>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="teal lighten-5">
      <h4 class="mb-2">Company Information</h4>
      <br />
      <v-row>
        <v-col cols="4">
          <v-text-field v-model="companyWebsite" label="Website" dense>
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field v-model="companyPhone" label="Phone" dense>
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field v-model="companyEmail" label="Email" dense>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="teal lighten-4">
      <h4 class="mb-2">Company Description</h4>
      <br />
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="companyDescription"
            name="input-7-1"
            label="Company Description"
            auto-grow
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="teal lighten-5">
      <h4 class="mb-2">Securities Information</h4>
      <br />
      <v-row>
        <v-col cols="6">
          <v-text-field v-model="companySIC" label="SIC Code" dense>
          </v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="companySymbol" label="Symbol" dense>
          </v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="companyMarketCap" label="Market Cap" dense>
          </v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="companyFloat" label="Float" dense>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="teal lighten-4">
      <h4 class="mb-2">Securities Data</h4>
      <br />
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="securityLastSale"
            label="Last Sale (Current Price)"
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field v-model="securityVolume" label="Volume" dense>
          </v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field v-model="securityDailyHigh" label="Daily High" dense>
          </v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field v-model="securityDailyLow" label="Daily Low" dense>
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field v-model="securityDailyLow" label="Daily High" dense>
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field v-model="securityAnnualHigh" label="52 Week High" dense>
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field v-model="securityAnnualLow" label="52 Week Low" dense>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="pl-0">
      <v-btn color="#206a5d" class="mr-0 white--text" @click="validate()">
        Save
      </v-btn>
    </v-container>

    <v-navigation-drawer
      v-model="dialog"
      absolute
      right
      temporary
      width="700"
      clipped
    >
      <v-toolbar color="#006c5d" class="white--text">
        <v-toolbar-title>{{ companyName }} Notes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- beginning timeline -->
      <v-container>
        <v-text-field
          v-model="input"
          hide-details
          flat
          label="Note"
          outlined
          placeholder="Enter here..."
          @keydown.enter="comment"
        >
        </v-text-field>

        <v-btn
          class="my-3 white--text"
          color="#206a5d"
          @click="comment"
          :disabled="input == ''"
        >
          Post
        </v-btn>

        <v-card flat fixed class="overflow-y-auto" max-height="500">
          <template v-for="event in timeline">
            <v-card class="my-3" :key="event.timestamp">
              <v-card-title class="mb-2">
                <v-row align="center" justify="space-between">
                  <div>
                    <v-avatar color="indigo mr-2" size="36">
                      <span class="white--text text-h6">
                        {{ event.userInitials }}
                      </span>
                    </v-avatar>
                  </div>
                  <div style="font-size: 12px">
                    <span v-text="event.timestamp"></span>
                  </div>
                </v-row>
              </v-card-title>

              <v-card-text>
                {{ event.text }}
              </v-card-text>
            </v-card>
          </template>
        </v-card>
      </v-container>

      <!-- End timeline -->
    </v-navigation-drawer>

    <lead-attachment
      :dialog="attachmentDialog"
      :lead="leadData"
      @close="attachmentDialog = false"
    />
  </div>
</template>
    
<script>
// Update Api
import axios from "axios";
import moment from "moment";
import constants from "../data/constants";
import LeadAttachment from "../components/LeadAttachment.vue";

export default {
  components: { LeadAttachment },
  data() {
    return {
      createAgreementLoading: false,
      datePicker: false,
      owners: [],
      employees: [],

      leadDate: null,
      leadOwners: [],
      leadId: null,
      leadData: {
        companyName: null,
        attachments: [],
      }, // does not need to be submitted
      companyId: "", // Default companyId for new lead
      companyName: null,
      companyCity: null,
      companyState: null,
      companyZip: null,
      companyCountry: null,
      companyAddress: null,
      companyWebsite: null,
      companyPhone: null,
      companyFacebook: null,
      companyTwitter: null,
      companyLinkedin: null,
      companyDescription: null, //not used yet
      companyDirectors: null, // List of Directors
      companyofficers: null, // List of officers
      companyEmail: null,
      companySIC: null,
      companySymbol: null,
      companyLogo: null,
      companyMarketCap: null,
      companyFloat: null,
      companySearch: "",

      // Contact person details
      companyContactName: null,
      companyContactNumber: null,
      companyContactEmail: null,
      companyContactRole: null,

      // security price data
      stockPriceData: null, // does not need to be submitted
      securityPercentChange: null,
      securityLastSale: null,
      securityVolume: null,
      securityDailyLow: null,
      securityDailyHigh: null,
      securityAnnualHigh: null,
      securityAnnualLow: null,

      // Stock Chart Data
      stockChartData: null, // does not need to be submitted
      stockChartRecords: null, // does not need to be submitted
      status: 1,

      value: [],

      leadStatuses: [
        { title: "Lead-Intro", value: 1, color: "amber lighten-2" },
        { title: "Contract Sent", value: 2, color: "orange" },
        { title: "IR Companies", value: 3, color: "teal" },
        { title: "Proposal", value: 4, color: "green" },
        { title: "Closed-Won", value: 5, color: "blue" },
        { title: "Future Follow up", value: 6, color: "purple" },
        { title: "Dead-Deals", value: 7, color: "black" },
      ],
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,

      //timeline in notes
      events: [],
      attachments: [],
      input: null,
      nonce: 0,

      attachmentDialog: false,
    };
  },

  methods: {
    fetchEmployees() {
      axios.post(`${constants.apiHost}/employees`).then((res) => {
        this.employees = res.data;
      });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}-${day}-${year}`;
    },

    //timeline in notes
    comment() {
      let time = moment().format("hh:mm:ss a, MM-DD-YYYY");

      let newEvent = {
        text: this.input,
        userId: this.userData.id,
        userInitials: this.userInitials,
        timestamp: time,
      };

      this.events.push(newEvent);

      let submit = {
        documents: [this.consoleData()],
      };

      axios.post(`${constants.apiHost}/document`, submit).then((res) => {});

      this.input = null;
    },

    consoleData() {
      let allData = {
        id: this.leadId,
        date: this.leadDate,
        isActive: true,
        collectionName: "leads",
        companyId: this.companyId,
        companyName: this.companyName,
        companyCity: this.companyCity,
        companyState: this.companyState,
        companyZip: this.companyZip,
        companyCountry: this.companyCountry,
        companyAddress: this.companyAddress,
        companyWebsite: this.companyWebsite,
        companyPhone: this.companyPhone,
        companyFacebook: this.companyFacebook,
        companyTwitter: this.companyTwitter,
        companyLinkedin: this.companyLinkedin,
        companyDescription: this.companyDescription,
        companyDirectors: this.companyDirectors, // List of Directors
        companyofficers: this.companyofficers, // List of officers
        companyEmail: this.companyEmail,
        companySIC: this.companySIC,
        companySymbol: this.companySymbol,
        companyLogo: this.companyLogo,
        companyMarketCap: this.companyMarketCap,
        companyFloat: this.companyFloat,
        companySearch: this.companySearch,

        // security price data
        securityPercentChange: this.securityPercentChange,
        securityLastSale: this.securityLastSale,
        securityVolume: this.securityVolume,
        securityDailyLow: this.securityDailyLow,
        securityDailyHigh: this.securityDailyHigh,
        securityAnnualHigh: this.securityAnnualHigh,
        securityAnnualLow: this.securityAnnualLow,

        //contact person details
        companyContactName: this.companyContactName,
        companyContactNumber: this.companyContactNumber,
        companyContactEmail: this.companyContactEmail,
        companyContactRole: this.companyContactRole,

        status: this.status,
        notes: this.events,
        attachments: this.attachments,
        owners: [],
        ownerNames: null,
      };

      let ownerList = [];
      let ownerNames = [];
      this.owners.forEach((owner) => {
        let employee = this.employees.find((v) => v.id == owner);
        if (employee) {
          ownerList.push({
            id: employee.id,
            name: employee.name,
          });
          ownerNames.push(employee.name);
        }
      });

      allData.owners = ownerList;
      allData.ownerNames = ownerNames.join(', ');

      return allData;
    },

    makeid(length) {
      let result = "";
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    validate() {
      let val = this.consoleData();
      if (this.$route.params.id) {
        //
      } else {
        val.companyId = "";
      }
      console.log("val: ", val);
      let submit = {
        documents: [val],
      };
      if (this.$route.params.id) {
        axios.post(`${constants.apiHost}/document`, submit).then((res) => {
          if ("status" in res && res.status != "errors") {
            this.$router.push("/leadsearch");
          }
        });
      } else {
        axios.post(`${constants.apiHost}/document`, submit).then((res) => {
          this.$router.push("/leadsearch");
        });
      }
    },

    createAgreement() {
      this.createAgreementLoading = true;

      let id = this.makeid(20);
      if (this.companyId != "") {
        id = this.companyId;
      }

      let val = {
        id: id,
        city: this.companyCity,
        collectionName: "companies",
        email: this.companyEmail,
        number: this.companyPhone,
        industry: null,
        isActive: true,
        logo: this.companyLogoUrl,
        commonStock: this.companySymbol,
        name: this.companyName,
        state: this.companyState,
        addressLine1: this.companyAddress,
        addressLine2: null,
        timezone: null,
        vertical: null,
        zip: this.companyZip,
        country: this.companyCountry,

        // contact person details
        contactName: this.companyContactName,
        contactNumber: this.companyContactNumber,
        contactEmail: this.companyContactEmail,
        contactRole: this.companyContactRole,
      };

      if (this.companyId == "") {
        axios
          .post(`${constants.apiHost}/document`, {
            documents: [val],
          })
          .then((res) => {
            if (this.companyId == "") {
              this.companyId = id;
              this.status = 5;
              let submit = {
                documents: [this.consoleData()],
              };

              axios.post(`${constants.apiHost}/document`, submit);
            }

            let router = this.$router;
            setTimeout(function () {
              this.createAgreementLoading = false;
              router.push(`/create-agreement?company=${val.id}`);
            }, 2000);
          })
          .catch((err) => {
            this.createAgreementLoading = false;
            console.log(err);
          });
      } else {
        axios
          .post(`${constants.apiHost}/document`, {
            documents: [val],
          })
          .then((res) => {
            if (this.companyId == "") {
              this.companyId = id;
              this.status = 5;
              let submit = {
                documents: [this.consoleData()],
              };

              axios.post(`${constants.apiHost}/document`, submit);
            }

            let router = this.$router;
            setTimeout(function () {
              this.createAgreementLoading = false;
              router.push(`/create-agreement?company=${val.id}`);
            }, 2000);
          })
          .catch((err) => {
            this.createAgreementLoading = false;
            console.log(err);
          });
      }
    },

    updateLeadStatus(status) {
      let val = this.consoleData();
      val.status = status;
      let submit = {
        documents: [val],
      };

      axios.post(`${constants.apiHost}/document`, submit).then((res) => {
        if ("status" in res && res.status != "errors") {
          this.$router.push("/leadsearch");
        }
      });
    },
  },

  // timeline in notes
  computed: {
    timeline() {
      return this.events.sort((a, b) => {
        let timeA = moment(a.timestamp, "hh:mm:ss a, MM-DD-YYYY");
        let timeB = moment(b.timestamp, "hh:mm:ss a, MM-DD-YYYY");
        return timeA.isBefore(timeB) ? 1 : -1;
      });
      // return this.events.slice().reverse();
    },

    userData() {
      let userData = localStorage.getItem("user_data");
      let user = {
        firstName: "No",
        lastName: "User",
        name: "No User",
        id: null,
      };
      if (userData) {
        user = JSON.parse(userData);
      }
      return user;
    },

    userInitials() {
      let user = this.userData;
      return user.firstName.charAt(0) + user.lastName.charAt(0);
    },

    computedDateFormatted() {
      return this.formatDate(this.leadDate);
    },

    companySearchUrl() {
      let search = this.companySearch ?? "";
      search = search.toLowerCase().replace(" ", "-");
      return search;
    },
  },

  mounted() {
    if (this.$route.params.id) {
      axios
        .get(`${constants.apiHost}/document`, {
          params: {
            id: [this.$route.params.id],
          },
        })
        .then((res) => {
          let data = res.data[0];
          this.leadData = data;
          console.log("route Data", data);
          this.companyName = data.companyName;
          this.leadId = data.id;
          this.leadDate = data.date;
          this.leadOwners = data.owners;

          this.companyId = data.companyId;
          this.companyCity = data.companyCity;
          this.companyState = data.companyState;
          this.companyZip = data.companyZip;
          this.companyCountry = data.companyCountry;
          this.companyAddress = data.companyAddress;
          this.companyWebsite = data.companyWebsite;
          this.companyPhone = data.companyPhone;
          this.companyFacebook = data.companyFacebook;
          this.companyTwitter = data.companyTwitter;
          this.companyLinkedin = data.companyLinkedin;
          this.companyDescription = data.companyLinkedin;
          this.companyDirectors = data.companyDirectors; // List of Directors
          this.companyofficers = data.companyofficers; // List of officers
          this.companyEmail = data.companyEmail;
          this.companySIC = data.companySIC;
          this.companySymbol = data.companySymbol;
          this.companyLogo = data.companyLogo;
          this.companyMarketCap = data.companyMarketCap;
          this.companyFloat = data.companyFloat;
          this.companySearch = data.companySearch;

          // security price data
          this.securityPercentChange = data.securityPercentChange;
          this.securityLastSale = data.securityLastSale;
          this.securityVolume = data.securityVolume;
          this.securityDailyLow = data.securityDailyLow;
          this.securityDailyHigh = data.securityDailyHigh;
          this.securityAnnualHigh = data.securityAnnualHigh;
          this.securityAnnualLow = data.securityAnnualLow;

          // contact person details
          this.companyContactName = data.companyContactName;
          this.companyContactNumber = data.companyContactNumber;
          this.companyContactEmail = data.companyContactEmail;
          this.companyContactRole = data.companyContactRole;

          this.status = data.status;
          this.events = data.notes;
          this.attachments = data.attachments ?? [];

          if (this.leadOwners.length > 0) {
            this.leadOwners.forEach((owner) => {
              this.owners.push(owner.id);
            });
          }
        });
    } else {
      this.leadId = this.makeid(20);
    }

    this.fetchEmployees();
  },
};
</script>
    
    <style>
</style>
<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row justify="center" class="mt-5">
        <v-col cols="6">
          <v-card class="mx-auto" max-width="434" tile>
            <v-avatar class="profile" color="grey" size="164" tile>
              <v-img :src="company.logo"></v-img>
            </v-avatar>
            <v-list-item style="color: #000">
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  {{ company.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ company.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-file-input
            @change="handleImage"
            accept="image/*"
            label="File input"
          ></v-file-input>
        </v-col>
      </v-row>

      <v-container class="teal lighten-5">
        <h4 class="mb-2">Company Details</h4>
        <br />

        <v-row>
          <v-col cols="4">
            <v-select
              v-model="company.type"
              :items="clientTypes"
              label="Client Type"
              item-value="id"
              item-text="name"
              dense
            >
            </v-select>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="company.name"
              :rules="nameRules"
              label="Company Name"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="company.email"
              :rules="emailRules"
              label="Company Email"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="company.number"
              label="Company Number"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="company.industry"
              label="Industry"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="company.commonStock"
              :label="company.type == 'crypto-lead' ? 'Crypto Ticker' : 'Stock Ticker'"
              required
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="teal lighten-4">
        <h4 class="mb-2">Contact Person Details</h4>
        <br />
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="company.contactName"
              label="Contact Name"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="company.contactNumber"
              label="Contact Number"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="company.contactEmail"
              label="Contact Email"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="company.contactRole"
              label="Contact Role"
              required
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="company.type == 'lead'" class="teal lighten-5">
        <h4 class="mb-2">Primary Address</h4>
        <br />

        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="company.addressLine1"
              label="Address Line 1"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="company.city"
              label="City"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="company.state"
              label="State"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="company.zip"
              label="Zip"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-select
              v-model="company.country"
              :items="countries"
              label="Country"
              item-text="name"
              dense
            >
              <template v-slot:item="slotProps">
                <i :class="['mr-2', 'em', slotProps.item.flag]"></i>
                {{ slotProps.item.name }}
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="company.type == 'crypto-lead'" class="teal lighten-5">
        <h4 class="mb-2">Secondary Address</h4>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="company.addressLine2"
              label="Address Line 2"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6"></v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="validate"
        >
          Save
        </v-btn>
      </v-container>
    </v-form>
  </div>
</template>

<script>
// Update Api
import axios from "axios";
import { countries } from "../data/countries";
import constants from "../data/constants";
import helper from "../helper/helper";

export default {
  data: () => ({
    extractionData: null,
    valid: true,
    nameRules: [(v) => !!v || "Name is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],

    countries: countries,
    // company object before creation
    company: {
      id: null,
      city: null,
      collectionName: "companies",
      contactName: null,
      contactNumber: null,
      contactEmail: null,
      contactRole: null,
      industry: null,
      isActive: true,
      logo: null,
      commonStock: null,
      name: null,
      email: null,
      number: null,
      state: null,
      street: null,
      addressLine1: null,
      addressLine2: null,
      timezone: null,
      vertical: null,
      zip: null,
      country: null,
      type: null,
    },

    clientTypes: helper.clientTypes,
  }),

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        if (this.$route.params.id) {
          axios
            .post(`${constants.apiHost}/document`, {
              documents: [this.company],
            })
            .then((res) => {
              if ("status" in res && res.status != "errors") {
                this.$router.push("/companies");
              }
            });
        } else {
          axios
            .post(`${constants.apiHost}/document`, {
              documents: [this.company],
            })
            .then((res) => {
              this.$router.push("/companies");
            });
        }
      }
    },

    // called when a uesr uploads a new image file
    handleImage(e) {
      if (e) {
        this.createBase64Image(e);
      } else {
        this.company.logo = null;
      }
    },
    // intakes a image file
    // returns a base64 string
    //https://medium.com/js-dojo/how-to-upload-base64-images-in-vue-nodejs-4e89635daebc
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.company.logo = e.target.result;
      };

      reader.readAsDataURL(fileObject);
    },
    // created unique id for new company
    makeid(length) {
      let result = "";
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
  // call api for company data when page is mounted
  mounted() {
    if (this.$route.params.id) {
      axios
        .get(`${constants.apiHost}/document`, {
          params: {
            id: [this.$route.params.id],
          },
        })
        .then((res) => {
          let data = res.data[0];
          this.company = data;
        });
    } else {
      this.company.id = this.makeid(20);
    }
  },
};
</script>

<style>
</style>
<template>
  <div class="my-4">
    <v-row align="center">
      <v-col>
        <v-select
          :items="compensationCategory"
          :menu-props="{ top: true, offsetY: true }"
          label="Compensation Category"
          v-model="selected"
        ></v-select>
      </v-col>

      <v-col>
        <div v-if="selected === 'Stock'">
          <v-select
            :items="stockCompensation"
            :menu-props="{ top: true, offsetY: true }"
            label="Compensation Option"
            v-model="selectedCompensation"
            @change="selectCompensationText"
          ></v-select>
        </div>
        <div v-if="selected === 'Crypto'">
          <v-select
            :items="cryptoCompensation"
            :menu-props="{ top: true, offsetY: true }"
            label="Compensation Option"
            v-model="selectedCompensation"
            @change="selectCompensationText"
          ></v-select>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model="company.commonStock"
          :rules="[rules.required]"
          label="Common Stock:"
          required
          disabled
          dense
          @input="selectCompensationText"
        >
        </v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          v-model="items.restrictedShares"
          type="number"
          :rules="[rules.required]"
          label="Restricted Shares:"
          required
          dense
          @input="selectCompensationText"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model="items.initialPayment"
          type="number"
          :rules="[rules.required]"
          label="Initial Payment"
          prepend-inner-icon="mdi-currency-usd"
          required
          dense
          @input="selectCompensationText"
        ></v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          v-model="items.monthlyPayment"
          type="number"
          :rules="[rules.required]"
          label="Monthly Payment"
          prepend-inner-icon="mdi-currency-usd"
          required
          dense
          @input="selectCompensationText"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-textarea
      dense
      name="input-7-1"
      filled
      label="Edit Compensation"
      auto-grow
      v-model="items.editCompensation"
    ></v-textarea>

    <v-row>
      <v-col>
        <v-select
          v-model="items.servicePlans"
          :items="labelsOptions"
          label="Select Duration (In Months)"
          @input="changeLabels"
          dense
        ></v-select>
      </v-col>
    </v-row>

    <!-- TABLE -->
    <v-row>
      <v-col>
        <v-data-table
          v-model="items.tableData"
          :headers="headers"
          :items="updateTableDataOrder"
          dense
          :items-per-page="20"
          hide-default-footer
          item-key="id"
          class="mx-1 elevation-1"
        >
          <template v-slot:item.serviceDuration="props">
            <v-edit-dialog :return-value.sync="props.item.serviceDuration">
              {{ props.item.serviceDuration }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.serviceDuration"
                  label="Edit"
                  single-line
                  counter
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.required="{ item }">
            <v-simple-checkbox
              v-model="item.required"
              :ripple="false"
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.number="props">
            <template v-if="props.item.editable">
              <v-text-field
                v-model="props.item.number"
                single-line
                class="pt-0"
                hide-details="auto"
                @keyup="handleNumberChangeEvent(props)"
              ></v-text-field>
            </template>

            <template v-else></template>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn text @click="updateStep(2)" class="mr-2"> Back </v-btn>

        <v-btn color="#006c5d" class="white--text" @click="updateStep(4)">
          Next
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { rules } from "@/data/rules";
import agreementsTableData from "@/data/agreementsTableData";

export default {
  props: {
    step: Number,
    items: Object,
    companies: Array,
  },

  data() {
    return {
      labelsOptions: ["3", "6", "12"],
      selectedCompensation: "",
      selectedCompCategory: "",
      selectedCompOption: "",
      compensationCategory: ["Stock", "Crypto", "Custom"],
      stockCompensation: [
        "Stock Compensation 1",
        "Stock Compensation 2",
        "Stock Compensation 3",
      ],
      cryptoCompensation: [
        "Crypto Compensation 1",
        "Crypto Compensation 2",
        "Crypto Compensation 3",
        "Crypto Compensation 4",
        "Crypto Compensation 5",
      ],
      selected: "",
      // For the table
      headers: [
        {
          text: "Select",
          value: "required",
          sortable: false,
          width: "35px",
        },
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "serviceType",
          width: "60%",
        },
        {
          text: "Duration",
          value: "serviceDuration",
          sortable: false,
        },
        {
          text: "Number",
          value: "number",
          sortable: false,
        },
      ],

      staticData: [
        {
          category: 1,
          option: 1,
          check: "Stock Compensation 1",
          text: "New to the Street Group LLC has agreed to accept the following compensation in exchange for performing the services set forth on Schedule A: The Client agrees to pay New to the Street Group LLC INITIAL_PAYMENT due upon signing ( initial Filming & Editing), MONTHLY_PAYMENT monthly (Due Before each interview), a total of 6 payments and RESTRICTED_SHARES restricted shares of COMMON_STOCK. The client acknowledges that all monies and shares/stocks are deemed fully earned upon the execution of this Agreement.",
        },
        {
          category: 1,
          option: 2,
          check: "Stock Compensation 2",
          text: "New to the Street Group LLC has agreed to accept the following compensation in exchange for performing the services set forth on Schedule A: The Client agrees to pay New to the Street Group LLC INITIAL_PAYMENT cash (MONTHLY_PAYMENT Due before each interview, total of 12 interviews/months) and RESTRICTED_SHARES restricted shares Rule 144 of COMMON_STOCK paid in two installments –75,000 shares 90 days after signing and the 75,000 remaining shares 180 days after signing. The Client may refrain from issuing restricted shares in the event New To The Street Group LLC fails to provide the other services described on Schedule A until such services are provided.",
        },
        {
          category: 1,
          option: 3,
          check: "Stock Compensation 3",
          text: "New to the Street Group LLC has agreed to accept the following compensation in exchange for performing the services set forth on Schedule A: The Client agrees to pay New to the Street Group LLC INITIAL_PAYMENT due upon signing & RESTRICTED_SHARES restricted shares of common stock COMMON_STOCK. The client acknowledges that all monies and shares/stocks are deemed fully earned upon the execution of this Agreement.",
        },
        {
          category: 2,
          option: 1,
          check: "Crypto Compensation 1",
          text: "New to the Street Group LLC has agreed to accept the following compensation in exchange for performing the services set forth on Schedule A: The Client agrees to pay New to the Street Group LLC  INITIAL_PAYMENT in USDT due upon signing ( initial Filming & Editing), MONTHLY_PAYMENT in USDT before the 2nd interview, a total of 2 payments and $127k worth of COMMON_STOCK tokens. NTTS has agreed to only sell up to 10% of their  tokens holding per month starting after the 3rd interview for the length of the contract. The client acknowledges that all monies and tokens are deemed fully earned upon the execution of this Agreement.",
        },
        {
          category: 2,
          option: 2,
          check: "Crypto Compensation 2",
          text: "New to the Street Group LLC has agreed to accept the following compensation in exchange for performing the services set forth on Schedule A: The Client agrees to pay New to the Street Group LLC INITIAL_PAYMENT due upon signing ( initial Filming & Editing), MONTHLY_PAYMENT monthly (Due before each interview) & $215,000 in COMMON_STOCK tokens (Based on $0.000000040). New to the Street agrees to lock up (not sell) 40% of the COMMON_STOCK Tokens that they received, until 6 months after they receive the wallet transfer.",
        },
        {
          category: 2,
          option: 3,
          check: "Crypto Compensation 3",
          text: "New to the Street Group LLC agrees to provide the Services to the Client during the “Term” (as hereinafter defined.) New to the Street Group LLC agrees to provide such information, evaluation, and analysis, in accordance with the Services as will assist in maximizing the effectiveness of the Client’s business model. New to the Street Group LLC shall personally provide the Services and the Client understands that the nature of the services to be provided are part-time and that New to the Street Group LLC will be engaged in other business activities during the term of this agreement.",
        },
        {
          category: 2,
          option: 4,
          check: "Crypto Compensation 4",
          text: "New to the Street Group LLC has agreed to accept the following compensation in exchange for performing the services set forth on Schedule A: The Client agrees to pay New to the Street Group LLC  INITIAL_PAYMENT in USDT, BTC, COMMON_STOCK, or a hybrid of payments (client discretion) due upon signing for initial filming & editing, and paid monthly of MONTHLY_PAYMENT (due before each monthly interview), for a total of 12 payments. The client has the option to nullify further services after the third month (3rd payment) of production. The client acknowledges that all monies are deemed fully earned upon the execution of this agreement.",
        },
        {
          category: 2,
          option: 5,
          check: "Crypto Compensation 5",
          text: "3-Month starter series: $ in USDT (or any stablecoin) paid monthly (3 payments) of due before each interview (to cover production and airing costs) and MONTHLY_PAYMENT per month",
        },
      ],

      rules,
    };
  },

  computed: {
    company() {
      let company = this.companies.find((v) => v.id == this.items.company);
      if (company) {
        return company;
      }
      return {};
    },

    updateTableDataOrder() {
      return this.items.tableData.sort((a, b) => a.order - b.order);
    },
  },

  methods: {
    updateStep(to) {
      this.$emit("updateStep", to);
    },

    changeLabels() {
      Object.values(this.items.tableData).map(
        (v) => (v.serviceDuration = this.items.servicePlans)
      );
    },

    selectCompensationText() {
      let compensation = this.staticData.find(
        (v) => v.check == this.selectedCompensation
      );
      if (compensation) {
        this.items.editCompensation = compensation.text;
      }

      this.items.editCompensation = this.items.editCompensation
        .replace("COMMON_STOCK", this.company.commonStock)
        .replace("RESTRICTED_SHARES", this.items.restrictedShares)
        .replace("INITIAL_PAYMENT", `$${this.items.initialPayment}`)
        .replace("MONTHLY_PAYMENT", `$${this.items.monthlyPayment}`);
    },

    handleNumberChangeEvent(props) {
      let item = props.item;
      let editedField = agreementsTableData.find((v) => v.id == item.id);
      console.log("editedField", editedField);
      if (editedField) {
        let serviceType = editedField.serviceType;
        if (item.number != "") {
          item.serviceType = serviceType.replace("{{ number }}", item.number);
        } else {
          item.serviceType = serviceType;
        }
      }
    },
  },
};
</script>

<style scoped>
>>> .centered-input input {
  text-align: center !important;
}

>>> .v-data-table__wrapper .v-input {
  max-width: 70px !important;
  margin: 5px 0 5px 0 !important;
}

>>> .v-data-table__wrapper .v-input__slot {
  padding: 0 0px !important;
  min-height: 100% !important;
}
</style>
<template>
  <!-- :items="agreements" getting data from db via bind -->
  <div>
    <v-data-table
      :headers="headers"
      :items="agreements"
      :search="search"
      :loading="tableLoader"
      class="elevation-1"
      no-results-text="No Results"
    >
      <!-- :custom-sort="customSort" -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Completed Agreements</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>

          <div></div>

          <v-spacer></v-spacer>

          <div></div>
        </v-toolbar>
      </template>

      <template v-slot:item.agreementName="{ item }">
        <v-btn text :to="`/show-agreement/${item.id}`" small>
          {{ item.agreementName }}
        </v-btn>
      </template>

      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// Update Api
import axios from "axios";
import constants from "../data/constants";

export default {
  data() {
    return {
      tableLoader: false,
      search: "",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "agreementName",
        },
        { text: "Main Contact", value: "client.name" },
        { text: "Phone #", value: "client.number" },
        { text: "Email", value: "client.email" },
        { text: "Category", value: "categories" },
        {
          text: "Due Date",
          value: "date",
          sortable: true,
        },
      ],
      agreements: [],
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    // shows agreements on agreements page
    initialize() {
      this.tableLoader = true;
      axios
        .post(`${constants.apiHost}/collectionDoc`, {
          model: "['agreements']",
        })
        .then((res) => {
          this.tableLoader = false;
          this.agreements = Object.values(res.data).filter((v) => {
            if (v.xstate.status && v.xstate.status == "FINISH") {
              return v;
            } else {
              //
            }
          });
        });
    },
  },
};
</script>
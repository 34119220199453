export default [
    {
        "id": 0,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "HD NASDAQ interview(s)",
        "order": 0
    },
    {
        "id": 1,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "FOX Business Network interview(s)",
        "order": 3
    },
    {
        "id": 2,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "KRON4 interview(s)",
        "order": 4
    },
    {
        "id": 3,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "Bloomberg interview(s)",
        "order": 1
    },
    {
        "id": 4,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "Newsmax interview(s)",
        "order": 2
    },
    {
        "id": 5,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "RNN interview(s)",
        "order": 5
    },
    {
        "id": 6,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "NASDAQ Tech Reports",
        "order": 6
    },
    {
        "id": 7,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "Social Media Support",
        "order": 7
    },
    {
        "id": 8,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "Press releases",
        "order": 8
    },
    {
        "id": 9,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "Emerging Growth Articles",
        "order": 9
    },
    {
        "id": 10,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "NASDAQ on site interview",
        "order": 10
    },
    {
        "id": 11,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "NTTS Hosting",
        "order": 11
    },
    {
        "id": 14,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "155 billboards per month in Manhattan, NYC. 15-second commercials (no sound) ",
        'editable': false,
        "order": 12
    },
    {
        "id": 15,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "Production and placement of digital boards above Nasdaq (times square) entrance",
        'editable': false,
        "order": 13
    },
    {
        "id": 12,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "{{ number }} (30-second commercials) per month",
        'editable': true,
        "order": 14
    },
    {
        "id": 13,
        "required": false,
        "serviceDuration": 0,
        "serviceType": "{{ number }} (15 Seconds Commercials) on digital live boards per month",
        'editable': true,
        "order": 15
    }
];
import axios from 'axios';
import constants from '../data/constants';

// This intercepts all axios api calls get,post,delete...
// This will add the jwt token to the header of any axios api 
//
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  // Get token from localstorage
  let userData = localStorage.getItem("user_data");
  if (userData) {
    userData = JSON.parse(userData);
    if (userData.jwtToken) {
      config.headers['Authorization'] = `Bearer ${userData.jwtToken}`;
    }
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
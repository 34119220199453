<template>
  <div>
    <div v-if="error">
      <v-alert prominent type="error">
        <v-row align="center">
          <v-col class="grow">
            {{ error }}
          </v-col>
        </v-row>
      </v-alert>
    </div>
    <v-row v-else align="center" justify="center" class="my-4">
      <v-col cols="12" sm="8" md="6">
        <v-stepper v-model="step" vertical>
          <v-stepper-step
            step="1"
            :complete="step > 1"
            color="#006c5d"
            class="white--text"
          >
            Interviewee Details
          </v-stepper-step>

          <v-stepper-content step="1">
            <template v-for="(value, index) in intervieweeDetails">
              <v-row :key="index">
                <v-col cols="3">
                  <v-text-field
                    v-model="value.name"
                    :rules="[rules.required]"
                    label="Name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="value.email"
                    :rules="[rules.required, rules.email]"
                    label="Email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="value.role"
                    :rules="[rules.required]"
                    label="Role"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    v-if="intervieweeDetails.length > 1"
                    type="button"
                    color="red"
                    icon
                    @click="removeInterviewee(index)"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="index == intervieweeDetails.length - 1"
                    type="button"
                    color="#006c5d"
                    icon
                    @click="addMoreInterviewee"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>

            <v-btn
              type="submit"
              color="#006c5d"
              class="white--text"
              @click="step = 2"
              :disabled="validateIntervieweeStep"
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            step="2"
            :complete="step > 2"
            color="#006c5d"
            class="white--text"
          >
            Questions
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-form @submit.prevent="step = 3" lazy-validation>
              <v-img height="250" src="/questionsntts.jpeg"></v-img>
              <br />
              <p>
                Please fill in the interview questions for your upcoming
                interview. Once you have updated the questions, please select
                continue to schedule your interview. (A minimum of 4 questions
                is required)
              </p>

              <div v-for="(item, index) in questions" :key="index">
                <v-text-field
                  v-if="index + 1 <= 4"
                  v-model="item.response"
                  :rules="questionsRules"
                  :label="`Question ${index + 1}`"
                  required
                ></v-text-field>

                <v-text-field
                  v-else
                  v-model="item.response"
                  :label="`Question ${index + 1}`"
                ></v-text-field>
              </div>

              <v-btn text @click="step = 1"> Back </v-btn>
              <v-btn
                type="submit"
                color="#006c5d"
                class="white--text"
                :disabled="!step1validated"
              >
                Continue
              </v-btn>
            </v-form>
          </v-stepper-content>

          <v-stepper-step
            step="3"
            :complete="step > 3"
            color="#006c5d"
            class="white--text"
          >
            Date
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card elevation="0" :loading="loading">
              <template slot="progress">
                <v-progress-linear
                  color="#006c5d"
                  height="10"
                  class="white--text"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-img
                height="250"
                src="https://thumbor.forbes.com/thumbor/960x0/https%3A%2F%2Fspecials-images.forbesimg.com%2Fdam%2Fimageserve%2F1128442176%2F960x0.jpg%3Ffit%3Dscale"
              ></v-img>

              <v-card-title>
                Please select a date and time for your interview
              </v-card-title>

              <v-card-text>
                <div>Select a time slot and then press submit</div>
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <v-card-text>
                <h4>{{ filteredDateRange }}</h4>

                <template v-if="events.length == 0">
                  <h5>No Events found.</h5>
                </template>

                <template v-else>
                  <v-chip-group
                    v-model="dateSelection"
                    active-class="dark-green white--text"
                  >
                    <v-col class="pa-0">
                      <v-col
                        v-for="(event, index) in formattedEvents"
                        :key="index"
                        class="pa-0"
                      >
                        <v-card-title>{{ event.day }}</v-card-title>
                        <v-chip
                          v-for="(hour, index) in event.hours"
                          :key="index"
                          :disabled="hour.taken"
                        >
                          {{ hour.hour }}
                        </v-chip>
                      </v-col>
                    </v-col>
                  </v-chip-group>
                </template>
              </v-card-text>

              <v-row justify="space-between" class="mb-2">
                <v-col>
                  <v-btn
                    v-if="calendarDays > dateRange + initialDays + 1"
                    color="#006c5d"
                    class="white--text"
                    @click="filterEvents(-dateRange)"
                  >
                    Previous {{ dateRange }} Days
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn
                    color="#006c5d"
                    class="white--text"
                    @click="filterEvents()"
                  >
                    Next {{ dateRange }} Businss Days
                  </v-btn>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-btn text @click="step = 2"> Back </v-btn>
                <v-btn
                  color="#006c5d"
                  class="white--text"
                  @click="step = 4"
                  :disabled="step2validated"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-step
            step="4"
            color="#006c5d"
            :complete="step > 4"
            class="white--text"
          >
            Overview
          </v-stepper-step>
          <br /><br />
          <v-stepper-content step="4">
            Please review the below information. Press submit to confirm.
            <br /><br />

            <h4>Interviewee Details:</h4>
            <template v-for="interviewee in intervieweeDetails">
              <span :key="interviewee.email">
                {{ interviewee.name }} - {{ interviewee.role }} -
                {{ interviewee.email }}
                <br />
              </span>
            </template>
            <br />

            <template v-for="(que, index) in questions">
              <p :key="index">
                Question {{ index + 1 }}:
                <span class="font-weight-bold">{{ que.response }}</span>
              </p>
            </template>

            <v-row>
              <v-col>
                <p>
                  Selected Date:
                  <span class="font-weight-bold">{{ selectedDate }}</span>
                </p>
              </v-col>
            </v-row>

            <v-btn text @click="step = 3"> Back </v-btn>

            <v-btn
              color="#006c5d"
              class="white--text"
              :loading="scheduleBtnLoader"
              @click="handleSubmit()"
            >
              Schedule Interview
            </v-btn>
          </v-stepper-content>

          <v-stepper-step step="5" color="#006c5d" class="white--text">
            Finish
          </v-stepper-step>

          <v-stepper-content step="5">
            <v-alert :value="true" type="success">
              You have scheduled
              <span class="font-weight-bold">{{ selectedDate }}</span> to be
              interviewed.
            </v-alert>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Update Api
import axios from "axios";
import moment from "moment";
import constants from "../data/constants";
import helper from "../helper/helper";
import { rules } from "@/data/rules";

export default {
  name: "App",
  data() {
    return {
      error: null,
      loading: false,
      scheduleBtnLoader: false,
      params: null,
      uid: this.$route.query.uid ?? 0,
      interviewId: this.$route.query.interview_id ?? 0,
      step: 1,
      // Initial days should have the same value as calendarDays
      initialDays: 7,
      // How many days into the future the calendar shows
      calendarDays: 7,
      // Filter of day showed.  7 days will show 7 days at a time.
      dateRange: 7,
      questionsRules: [(value) => !!value || "Required"],
      questions: [
        {
          question: "",
          response: "",
        },
        {
          question: "",
          response: "",
        },
        {
          question: "",
          response: "",
        },
        {
          question: "",
          response: "",
        },
        {
          question: "",
          response: "",
        },
        {
          question: "",
          response: "",
        },
        {
          question: "",
          response: "",
        },
      ],
      intervieweeDetails: [
        {
          name: null,
          email: null,
          role: null,
        },
      ],
      allEvents: [],
      events: [],
      dateSelection: null,
      form: {},
      company: {},
      oldInterview: null,
      rules,
    };
  },

  computed: {
    validateIntervieweeStep() {
      for (let value of this.intervieweeDetails) {
        if (value.name == null || value.email == null || value.role == null) {
          return true;
        }
      }
      return false;
    },

    // function to make first 4 questions required
    step1validated() {
      let response = true;
      this.questions.forEach((v, i) => {
        if (i <= 3 && v.response == "") {
          response = false;
        }
      }, response);
      return response;
    },

    step2validated() {
      return this.dateSelection == null;
    },

    selectedDate() {
      if (this.getFormattedEventsDate(this.dateSelection))
        return moment(
          this.getFormattedEventsDate(this.dateSelection).startDate
        ).format("MMMM Do h:mmA");
      return null;
    },

    formattedEvents() {
      let formattedEvents = [];

      for (let i = 0; i < this.events.length; i++) {
        const item = this.events[i];
        const startDate = moment(item.start.dateTime);
        const endDate = moment(item.end.dateTime);

        const prevIndex = formattedEvents.findIndex(
          (e) => e.day == startDate.format("MMM D")
        );

        // formatted events is empty
        // 11 is red
        if (formattedEvents.length == 0) {
          formattedEvents.push({
            day: startDate.format("MMM D"),
            hours: [
              {
                id: item.id,
                hour: startDate.format("h:mm A"),
                startDate,
                endDate,
                taken: item.colorId == 11,
                colorId: item.colorId,
              },
            ],
          });
        }
        // only date which day reoccurs in formatted events
        else if (prevIndex != -1) {
          // if same date exists
          if (
            formattedEvents[prevIndex].hours.find(
              (item) =>
                item.startDate.format("YYYYMMDD h:mm A") ==
                startDate.format("YYYYMMDD h:mm A")
            )
          ) {
            continue;
          }

          formattedEvents[prevIndex].hours.push({
            id: item.id,
            hour: startDate.format("h:mm A"),
            startDate,
            endDate,
            taken: item.colorId == 11,
            colorId: item.colorId,
          });
          formattedEvents[prevIndex].hours.sort((a, b) =>
            a.startDate.diff(b.startDate)
          );
        } else {
          formattedEvents.push({
            day: startDate.format("MMM D"),
            hours: [
              {
                id: item.id,
                hour: startDate.format("h:mm A"),
                startDate,
                endDate,
                taken: item.colorId == 11,
                colorId: item.colorId,
              },
            ],
          });
        }
      }

      formattedEvents = formattedEvents.sort(
        (a, b) =>
          moment(a.day, "MMMD").format("YYYYMMDD") -
          moment(b.day, "MMMD").format("YYYYMMDD")
      );

      return formattedEvents;
    },

    // Text that shows the date range
    filteredDateRange() {
      let start = moment()
        .add(this.calendarDays - this.dateRange, "days")
        .format("MM/DD/YYYY");
      let end = moment()
        .add(this.calendarDays - 1, "days")
        .format("MM/DD/YYYY");

      return `${start} to ${end}`;
    },
  },

  created() {
    if (this.uid == 0) {
      this.error = "Invalid URL";
    }

    axios
      .get(`${constants.apiHost}/document`, {
        params: {
          id: [this.uid],
        },
      })
      .then((res) => {
        let data = res.data[0];
        this.form = data;

        axios
          .get(`${constants.apiHost}/document`, {
            params: {
              id: [this.form.company],
            },
          })
          .then((res) => {
            this.company = res.data[0];
          });
      });

    axios
      .post(`${constants.apiHost}/calendar-type`, {
        calendartype: "schedule-interview",
      })
      .then((response) => {
        axios
          .get(`${constants.apiHost}/events-from-primary`)
          .then((res) => {
            this.allEvents = JSON.parse(JSON.stringify(res.data.items));
            this.filterEvents(this.dateRange, true);
          })
          .catch((err) => {
            console.log(err);
          });
      });

    // old interview data
    if (this.interviewId != 0) {
      axios
        .post(`${constants.apiHost}/interviews`, {
          params: {
            id: [this.uid],
          },
        })
        .then((res) => {
          this.oldInterview = res.data.currentInterview;
        });
    }
  },

  methods: {
    addMoreInterviewee() {
      this.intervieweeDetails.push({
        name: null,
        email: null,
        role: null,
      });
    },

    removeInterviewee(index) {
      this.intervieweeDetails.splice(index, 1);
    },

    // Submits calendar data to CouchDB agreement document
    handleSubmit() {
      this.loading = true;
      this.scheduleBtnLoader = true;
      const event = this.getFormattedEventsDate(this.dateSelection);

      let anchor = "";
      if (event.colorId == 9) {
        anchor = "Anna Berry";
      } else if (event.colorId == 10) {
        anchor = "Jane King";
      }

      let form = JSON.parse(JSON.stringify(this.form));
      form.client.id = helper.userData().id;

      let increment = 0;
      if (this.interviewId == 0) {
        increment = 1;
      }

      let description =
        "Interview with " +
          this.company.name +
          "\n\n" +
          "Anchor: " +
          anchor +
          "\n" +
          this.intervieweeDetails.length ==
        1
          ? "Interviewee: "
          : "Interviewees: ";

      let intervieweeEmails = [];
      let intervieweeNames = [];
      for (let intervieweeDetail of this.intervieweeDetails) {
        intervieweeEmails.push({ email: intervieweeDetail.email });
        intervieweeNames.push(intervieweeDetail.name);
      }
      description += intervieweeNames.join(", ");

      description +=
        "\n\n Please contact new to the street if you have any questions about this interview";

      let interviewForm = {
        questions: this.questions,
        eventId: event.id,
        datetimeStart: event.startDate.toLocaleString(),
        datetimeEnd: event.endDate.toLocaleString(),
        intervieweeName: this.form.secondaryClient.name,
        company: this.company.name,
        attendeeEmailInterviewee: this.form.secondaryClient.email,
        attendeeEmailSigner: this.form.client.email,
        intervieweeEmails: intervieweeEmails,
        intervieweeDetails: this.intervieweeDetails,
        location: "Zoom",
        description: description,
        summary:
          "Interview " +
          (this.form.xstate.data.iteration + increment) +
          "/" +
          this.form.servicePlans +
          " with " +
          this.intervieweeDetails[0].name +
          " (" +
          this.company.name +
          ")",
      };

      let agreementDocuments = {
        documents: [
          {
            ...form,
            // interview: interviewForm,
          },
        ],
      };

      // update document with calendar info
      axios
        .post(`${constants.apiHost}/document`, agreementDocuments)
        .then((res) => {
          console.log("response from updated agreement::::", res);
        });

      interviewForm.uid = this.uid;

      console.log("form:::::::::::::", this.form);
      console.log("company:::::::::::::", this.company);

      // schedule interview
      if (this.interviewId == 0) {
        axios
          .post(`${constants.apiHost}/interview-form`, interviewForm)
          .then((res) => {
            this.loading = false;
            this.scheduleBtnLoader = false;
            this.step = 5;
            this.setInterviewDate();
            console.log("step 4");
          });
      } else {
        // reschedule interview
        const event = this.getFormattedEventsDate(this.dateSelection);

        let anchor = "";
        if (event.colorId == 9) {
          anchor = "Anna Berry";
        } else if (event.colorId == 10) {
          anchor = "Jane King";
        }

        let val = {
          ...this.oldInterview,
          anchor: anchor,
          colorId: event.colorId,
          questions: this.questions,
          calenderId: event.id,
          startTime: event.startDate,
          endTime: event.endDate,
          intervieweeDetails: this.intervieweeDetails,
        };

        axios
          .post(`${constants.apiHost}/document`, {
            documents: [val],
          })
          .then(() => {
            axios
              .post(`${constants.apiHost}/reschedule`, {
                eventId: this.oldInterview.calenderId,
                summary: "Interview",
                datetimeStart: this.oldInterview.startTime,
                datetimeEnd: this.oldInterview.endTime,
                colorId: this.oldInterview.colorId,
              })
              .then(() => {
                axios
                  .post(`${constants.apiHost}/interview-form`, interviewForm)
                  .then((res) => {
                    this.loading = false;
                    this.step = 5;
                    console.log("step 4");
                  });
              });
          });
      }
    },

    // Xstate - Creates the initial interviews document
    setInterviewDate() {
      const event = this.getFormattedEventsDate(this.dateSelection);
      console.log("Date Selection ", this.dateSelection);

      let anchor = "";
      if (event.colorId == 9) {
        anchor = "Anna Berry";
      } else if (event.colorId == 10) {
        anchor = "Jane King";
      }

      let val = {
        documents: [
          {
            anchor: anchor,
            colorId: event.colorId,
            questions: this.questions,
            calenderId: event.id,
            interviewee: this.form.secondaryClient.name,
            company: this.company.name,
            intervieweeDetails: this.intervieweeDetails,
            title: "Upcoming Interview",
            startTime: event.startDate,
            endTime: event.endDate,
            id: this.makeId(20),
            agreement: this.form.id,
            event: "setDate",
            collectionName: "interviews",
            completed: false,
          },
        ],
      };

      axios.post(`${constants.apiHost}/document`, val).then((res) => {
        let agreement = JSON.parse(JSON.stringify(this.form));
        helper.xstateMange(agreement);

        console.log("response from set interview date::::", res);
      });
    },

    makeId(length) {
      let result = "";
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    getFormattedEventsDate(input_index) {
      let index = 0;

      for (let event of this.formattedEvents) {
        for (let hour of event.hours) {
          if (index == input_index) return hour;
          index++;
        }
      }

      return null;
    },

    clearForm() {
      for (const question of this.questions) {
        question.response = "";
      }
    },

    // Next 7 Business and Previous 7 Business Day button
    // number argument will control how many days into the future events show
    filterEvents(number = this.dateRange, isFirstTime = false) {
      this.calendarDays += number;
      // only dates within 3 days and previous dates are filtered out
      // || item.colorId == 11
      // 11 is the color red
      // Add color ID's to the below or statement to add additional colors
      this.events = this.allEvents.filter(
        (item) =>
          item.colorId &&
          (item.colorId == 9 || item.colorId == 10) &&
          moment(item.start.dateTime) >=
            moment()
              .startOf("day")
              .add(this.calendarDays - 7, "days") &&
          moment(item.start.dateTime) <=
            moment()
              .endOf("day")
              .add(this.calendarDays - 1, "days")
      );

      if (this.events.length == 0 && isFirstTime) {
        this.filterEvents(number, isFirstTime);
      }
    },
  },

  watch: {
    step(val) {
      if (val == 5) {
        let self = this;
        setTimeout(function () {
          self.$router.push("/dashboard");
        }, 4000);
      }
    },
  },
};
</script>

<style scoped>
.dark-green {
  background-color: #006c5d;
}
</style>
<template>
  <div class="my-4">
    <v-row>
      <v-col>
        <v-text-field
          v-model="items.agreementName"
          :rules="[rules.required]"
          label="Agreement Name"
          required
          dense
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-autocomplete
          v-model="items.company"
          :items="companies.filter((v) => v.isActive)"
          :rules="[rules.required]"
          item-text="name"
          item-value="id"
          label="Client Company Name"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-textarea
        v-model="items.description"
        label="Contract Description"
        height="60px"
        dense
        filled
        auto-grow
        value=""
      ></v-textarea>
    </v-row>

    <v-row>
      <v-col>
        <v-menu
          v-model="dateMenu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="items.date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            >
              <template slot="label">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">Effective Date 🛈</div>
                  </template>
                  <span>Effective Date</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="items.date"
            @input="dateMenu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="dateMenu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="items.signDate"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            >
              <template slot="label">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">Sign Date 🛈</div>
                  </template>
                  <span>Sign Date</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="items.signDate"
            @input="dateMenu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-combobox
          v-model="items.categories"
          :items="['Media', 'Crypto', 'Interview', 'Commercial', 'Billboard']"
          :search-input.sync="categorySearch"
          hide-selected
          hint="Maximum of 3 tags"
          label="Category tags"
          multiple
          persistent-hint
          small-chips
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "<strong>{{ categorySearch }}</strong> ".
                  Press <kbd>enter</kbd> to create a new one
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>
    </v-row>

    <v-row>
      <template v-if="company.type == 'lead'">
        <v-col cols="4">
          <v-text-field
            :value="company.addressLine1"
            label="Address Line 1"
            disabled
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field :value="company.city" label="City" disabled dense>
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field :value="company.state" label="State" disabled dense>
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field :value="company.zip" label="Zipcode" disabled dense>
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field :value="company.country" label="Country" disabled dense>
          </v-text-field>
        </v-col>
      </template>

      <template v-if="company.type == 'crypto-lead'">
        <v-col cols="12">
          <v-text-field
            :value="company.addressLine2"
            label="Address line 2"
            disabled
            dense
          >
          </v-text-field>
        </v-col>
      </template>
    </v-row>

    <v-row>
      <v-col>
        <v-btn color="#006c5d" class="white--text" @click="updateStep(2)">
          Next
        </v-btn>
      </v-col>
    </v-row>

    <div class="text-center">
      <v-dialog v-model="newClientDialog" persistent width="700">
        <v-form ref="client_form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Create New Client
            </v-card-title>

            <v-card-text>
              <v-container class="teal lighten-5">
                <h4 class="mb-2">Company Details</h4>
                <br />

                <v-row>
                  <v-col cols="4">
                    <v-select
                      v-model="client.type"
                      :items="clientTypes"
                      :rules="requiredRules"
                      label="Client Type"
                      item-value="id"
                      item-text="name"
                      dense
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      v-model="client.name"
                      :rules="requiredRules"
                      label="Client Name"
                      required
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      v-model="client.email"
                      :rules="emailRules"
                      label="Client Email"
                      required
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      v-model="client.commonStock"
                      :label="
                        client.type == 'crypto-lead'
                          ? 'Crypto Ticker'
                          : 'Stock Ticker'
                      "
                      :rules="requiredRules"
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>

              <v-container v-if="client.type == 'lead'" class="teal lighten-4">
                <h4 class="mb-2">Primary Address</h4>
                <br />

                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="client.addressLine1"
                      label="Address Line 1"
                      :rules="requiredRules"
                      required
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      v-model="client.city"
                      label="City"
                      :rules="requiredRules"
                      required
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      v-model="client.state"
                      :rules="requiredRules"
                      label="State"
                      required
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      v-model="client.zip"
                      :rules="requiredRules"
                      label="Zip"
                      required
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-select
                      v-model="client.country"
                      :items="countries"
                      :rules="requiredRules"
                      label="Country"
                      item-text="name"
                      dense
                    >
                      <template v-slot:item="slotProps">
                        <i :class="['mr-2', 'em', slotProps.item.flag]"></i>
                        {{ slotProps.item.name }}
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>

              <v-container
                v-if="client.type == 'crypto-lead'"
                class="teal lighten-4"
              >
                <h4 class="mb-2">Secondary Address</h4>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="client.addressLine2"
                      label="Address Line 2"
                      :rules="requiredRules"
                      required
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6"></v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="validate"
              >
                Create
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  () => {
                    newClientDialog = false;
                    items.company = null;
                  }
                "
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { rules } from "@/data/rules";
import { countries } from "@/data/countries";
import constants from "../../data/constants";
import helper from "../../helper/helper";
import axios from "axios";

export default {
  props: {
    step: Number,
    items: Object,
    companies: Array,
  },

  data() {
    return {
      represantiveItems: ["Eric", "John"], // repr array values for select element
      countries, // country array values for select element
      dateMenu1: false, // bolean value to check if info menu is open
      dateMenu2: false,
      rules,
      categorySearch: null,
      newClientDialog: false,
      client: {
        id: null,
        city: null,
        collectionName: "companies",
        contactName: null,
        contactNumber: null,
        contactEmail: null,
        contactRole: null,
        industry: null,
        isActive: true,
        logo: null,
        commonStock: null,
        name: null,
        email: null,
        number: null,
        state: null,
        street: null,
        addressLine1: null,
        addressLine2: null,
        timezone: null,
        vertical: null,
        zip: null,
        country: null,
        type: null,
      },
      clientTypes: helper.clientTypes,
      valid: true,
      nameRules: [(v) => !!v || "Name is required"],
      requiredRules: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      countries: countries,
    };
  },

  computed: {
    company() {
      let company = this.companies.find((v) => v.id == this.items.company);
      if (company) {
        if (company.id == "new-company") {
          this.newClientDialog = true;
        }
        return company;
      }
      return {};
    },
  },

  methods: {
    updateStep(to) {
      this.$emit("updateStep", to);
    },

    validate() {
      if (this.$refs.client_form.validate()) {
        let id = helper.makeid(20);
        this.client.id = id;
        axios
          .post(`${constants.apiHost}/document`, {
            documents: [this.client],
          })
          .then((res) => {
            this.$emit("refetchCompanies", id);
            this.newClientDialog = false;
            this.resetClient();
          });
      }
    },

    resetClient() {
      this.client = {
        id: null,
        city: null,
        collectionName: "companies",
        contactName: null,
        contactNumber: null,
        contactEmail: null,
        contactRole: null,
        industry: null,
        isActive: true,
        logo: null,
        commonStock: null,
        name: null,
        email: null,
        number: null,
        state: null,
        street: null,
        addressLine1: null,
        addressLine2: null,
        timezone: null,
        vertical: null,
        zip: null,
        country: null,
        type: null,
      };
    },
  },

  watch: {
    items: {
      deep: true,
      handler: (val, oldVal) => {
        if (val.categories.length > 3) {
          val.categories.pop();
        }
      },
    },
  },
};
</script>
<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container>
        <v-row class="mt-5">
          <v-col cols="4"> </v-col>

          <v-col v-if="$route.params.id" cols="8" class="text-right">
            <!-- Passed the Lead ID to the leads calendar page on the click of the button  -->
            <v-btn
              :to="`/leadscalendar?leadName=${cryptoLead.id}`"
              class="mr-4 white--text"
              color="#206a5d"
            >
              Schedule Event
            </v-btn>

            <v-btn
              color="#206a5d"
              @click="dialog = true"
              class="mr-4 white--text"
            >
              Notes
            </v-btn>

            <v-btn
              color="#206a5d"
              @click="attachmentDialog = true"
              class="mr-4 white--text"
            >
              attachments
            </v-btn>

            <v-btn
              :loading="createAgreementLoading"
              @click="createAgreement"
              class="mr-4 white--text"
              color="#206a5d"
            >
              Create Agreement
            </v-btn>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#206a5d"
                  class="white--text"
                  v-bind="attrs"
                  v-on="on"
                >
                  Status
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in cryptoLeadStatuses"
                  :key="index"
                  link
                  @click="updateLeadStatus(item.value)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>

      <v-navigation-drawer
        v-model="dialog"
        absolute
        right
        temporary
        width="700"
        clipped
      >
        <v-toolbar color="#006c5d" class="white--text">
          <v-toolbar-title>{{ cryptoLead.name }} Notes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <!-- beginning timeline -->
        <v-container>
          <v-text-field
            v-model="eventInput"
            hide-details
            flat
            label="Note"
            outlined
            placeholder="Enter here..."
            @keydown.enter="comment"
          >
          </v-text-field>

          <v-btn
            class="my-3 white--text"
            color="#206a5d"
            @click="comment"
            :disabled="eventInput == ''"
          >
            Post
          </v-btn>

          <v-card flat fixed class="overflow-y-auto" max-height="500">
            <template v-for="event in timeline">
              <v-card class="my-3" :key="event.timestamp">
                <v-card-title class="mb-2">
                  <v-row align="center" justify="space-between">
                    <div>
                      <v-avatar color="indigo mr-2" size="36">
                        <span class="white--text text-h6">
                          {{ event.userInitials }}
                        </span>
                      </v-avatar>
                    </div>
                    <div style="font-size: 12px">
                      <span v-text="event.timestamp"></span>
                    </div>
                  </v-row>
                </v-card-title>

                <v-card-text>
                  {{ event.text }}
                </v-card-text>
              </v-card>
            </template>
          </v-card>
        </v-container>

        <!-- End timeline -->
      </v-navigation-drawer>

      <v-container class="teal lighten-4">
        <h4 class="mb-2">Sources</h4>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="cryptoLead.companySearch"
              label="Search"
              clearable
              persistent-hint
              hint="Enter the crypto currency name"
            ></v-text-field>
          </v-col>

          <v-col cols="12" v-if="cryptoLead.companySearch != ''">
            <v-btn
              :href="`https://www.coingecko.com/en/coins/${companySearchUrl}`"
              target="_blank"
              class="mr-2"
            >
              https://www.coingecko.com/en/coins/{{ companySearchUrl }}</v-btn
            >
            <v-btn
              :href="`https://coinmarketcap.com/currencies/${companySearchUrl}`"
              target="_blank"
            >
              https://coinmarketcap.com/currencies/{{ companySearchUrl }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="teal lighten-5">
        <h4 class="mb-2">Crypto Lead Details</h4>
        <br />

        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="cryptoLead.name"
              :rules="nameRules"
              label="Company Name"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="cryptoLead.commonStock"
              label="Crypto Symbol"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="cryptoLead.email"
              :rules="emailRules"
              label="Company Email"
              required
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="teal lighten-4">
        <h4 class="mb-2">Contact Person Details</h4>
        <br />
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="cryptoLead.contactName"
              label="Contact Name"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="cryptoLead.contactNumber"
              label="Contact Number"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="cryptoLead.contactEmail"
              label="Contact Email"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="cryptoLead.contactRole"
              label="Contact Role"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-select
              v-model="owners"
              :items="employees"
              :menu-props="{ maxHeight: '400' }"
              label="Select Owner"
              multiple
              chips
              dense
              item-value="id"
              item-text="name"
            ></v-select>
          </v-col>

          <v-col cols="6">
            <v-menu
              ref="datePicker"
              v-model="datePicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="cryptoLead.date"
                @input="datePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="teal lighten-5">
        <h4 class="mb-2">Primary Address</h4>
        <br />

        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="cryptoLead.addressLine1"
              label="Address Line 1"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="cryptoLead.city"
              label="City"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="cryptoLead.state"
              label="State"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="cryptoLead.zip"
              label="Zip"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-select
              v-model="cryptoLead.country"
              :items="countries"
              label="Country"
              item-text="name"
              dense
            >
              <template v-slot:item="slotProps">
                <i :class="['mr-2', 'em', slotProps.item.flag]"></i>
                {{ slotProps.item.name }}
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="teal lighten-4">
        <h4 class="mb-2">Secondary Address</h4>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="cryptoLead.addressLine2"
              label="Address Line 2"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6"></v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-btn
          :disabled="!valid"
          color="#206a5d"
          class="mr-4 white--text"
          @click="validate"
        >
          Save
        </v-btn>
      </v-container>
    </v-form>

    <lead-attachment
      :dialog="attachmentDialog"
      :lead="cryptoLead"
      @close="attachmentDialog = false"
    />
  </div>
</template>

<script>
// Update Api
import axios from "axios";
import { countries } from "../../data/countries";
import moment from "moment";
import constants from "../../data/constants";
import LeadAttachment from "../../components/LeadAttachment.vue";

export default {
  components: { LeadAttachment },
  data() {
    return {
      extractionData: null,
      valid: true,
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      countries: countries,
      employees: [],
      owners: [],
      // cryptoLead object before creation
      cryptoLead: {
        id: this.$route.params.id ?? 0,
        companySearch: "",
        companyId: "",
        city: null,
        collectionName: "cryptoLeads",
        contactName: null,
        contactNumber: null,
        contactEmail: null,
        contactRole: null,
        industry: null,
        isActive: true,
        logo: null,
        commonStock: null,
        name: null,
        email: null,
        number: null,
        state: null,
        street: null,
        addressLine1: null,
        addressLine2: null,
        timezone: null,
        vertical: null,
        zip: null,
        country: null,
        status: 1,
        owners: [],
        ownerNames: null,
        events: [],
        attachments: [],
        date: null,
      },

      dialog: false,
      datePicker: false,
      createAgreementLoading: false,
      eventInput: "",

      cryptoLeadStatuses: [
        { title: "Lead-Intro", value: 1, color: "amber lighten-2" },
        { title: "Contract Sent", value: 2, color: "orange" },
        { title: "IR Companies", value: 3, color: "teal" },
        { title: "Proposal", value: 4, color: "green" },
        { title: "Closed-Won", value: 5, color: "blue" },
        { title: "Future Follow up", value: 6, color: "purple" },
        { title: "Dead-Deals", value: 7, color: "black" },
      ],

      attachmentDialog: false,
    };
  },

  computed: {
    timeline() {
      return this.cryptoLead.events.sort((a, b) => {
        let timeA = moment(a.timestamp, "hh:mm:ss a, MM-DD-YYYY");
        let timeB = moment(b.timestamp, "hh:mm:ss a, MM-DD-YYYY");
        return timeA.isBefore(timeB) ? 1 : -1;
      });
    },

    companySearchUrl() {
      let search = this.cryptoLead.companySearch;
      search = search.toLowerCase().replace(" ", "-");
      return search;
    },

    userData() {
      let userData = localStorage.getItem("user_data");
      let user = {
        firstName: "No",
        lastName: "User",
        name: "No User",
        id: null,
      };
      if (userData) {
        user = JSON.parse(userData);
      }
      return user;
    },

    userInitials() {
      let user = this.userData;
      return user.firstName.charAt(0) + user.lastName.charAt(0);
    },

    computedDateFormatted() {
      return this.formatDate(this.cryptoLead.date);
    },
  },

  methods: {
    fetchEmployees() {
      axios.post(`${constants.apiHost}/employees`).then((res) => {
        this.employees = res.data;
      });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}-${day}-${year}`;
    },

    validate() {
      if (this.$refs.form.validate()) {
        let ownerList = [];
        let ownerNames = [];
        this.owners.forEach((owner) => {
          let employee = this.employees.find((v) => v.id == owner);
          if (employee) {
            ownerList.push({
              id: employee.id,
              name: employee.name,
            });
            ownerNames.push(employee.name);
          }
        });

        this.cryptoLead.owners = ownerList;
        this.cryptoLead.ownerNames = ownerNames.join(', ');

        if (this.$route.params.id) {
          axios
            .post(`${constants.apiHost}/document`, {
              documents: [this.cryptoLead],
            })
            .then((res) => {
              if ("status" in res && res.status != "errors") {
                this.$router.push("/crypto-leads");
              }
            });
        } else {
          axios
            .post(`${constants.apiHost}/document`, {
              documents: [this.cryptoLead],
            })
            .then((res) => {
              this.$router.push("/crypto-leads");
            });
        }
      }
    },

    // called when a uesr uploads a new image file
    handleImage(e) {
      if (e) {
        this.createBase64Image(e);
      } else {
        this.cryptoLead.logo = null;
      }
    },

    // intakes a image file
    // returns a base64 string
    //https://medium.com/js-dojo/how-to-upload-base64-images-in-vue-nodejs-4e89635daebc
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.cryptoLead.logo = e.target.result;
      };

      reader.readAsDataURL(fileObject);
    },

    // created unique id for new crypto lead
    makeid(length) {
      let result = "";
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    comment() {
      if (this.eventInput.trim() != "") {
        let time = moment().format("hh:mm:ss a, MM-DD-YYYY");

        let newEvent = {
          text: this.eventInput,
          userId: this.userData.id,
          userInitials: this.userInitials,
          timestamp: time,
        };

        this.cryptoLead.events.push(newEvent);

        let submit = {
          documents: [this.cryptoLead],
        };

        axios.post(`${constants.apiHost}/document`, submit).then((res) => {});
      }
      this.eventInput = "";
    },

    createAgreement() {
      this.createAgreementLoading = true;

      let id = this.makeid(20);
      if (this.cryptoLead.companyId != 0) {
        id = this.cryptoLead.companyId;
      }

      let val = {
        id: id,
        city: this.cryptoLead.city,
        collectionName: "companies",
        email: this.cryptoLead.email,
        number: this.cryptoLead.number,
        industry: this.cryptoLead.industry,
        isActive: this.cryptoLead.isActive,
        logo: this.cryptoLead.logo,
        commonStock: this.cryptoLead.commonStock,
        name: this.cryptoLead.name,
        state: this.cryptoLead.state,
        addressLine1: this.cryptoLead.addressLine1,
        addressLine2: this.cryptoLead.addressLine2,
        timezone: this.cryptoLead.timezone,
        vertical: this.cryptoLead.vertical,
        zip: this.cryptoLead.zip,
        country: this.cryptoLead.country,

        // contact person details
        contactName: this.cryptoLead.contactName,
        contactNumber: this.cryptoLead.contactNumber,
        contactEmail: this.cryptoLead.contactEmail,
        contactRole: this.cryptoLead.contactRole,
      };

      if (this.cryptoLead.companyId == "") {
        axios
          .post(`${constants.apiHost}/document`, {
            documents: [val],
          })
          .then((res) => {
            if (this.cryptoLead.companyId == "") {
              this.cryptoLead.companyId = id;
              this.cryptoLead.status = 5;
              let submit = {
                documents: [this.cryptoLead],
              };

              axios.post(`${constants.apiHost}/document`, submit);
            }

            let router = this.$router;
            setTimeout(function () {
              this.createAgreementLoading = false;
              router.push(`/create-agreement?company=${val.id}`);
            }, 2000);
          })
          .catch((err) => {
            this.createAgreementLoading = false;
            console.log(err);
          });
      } else {
        axios
          .post(`${constants.apiHost}/document`, {
            documents: [val],
          })
          .then((res) => {
            if (this.cryptoLead.companyId == "") {
              this.cryptoLead.companyId = id;
              this.cryptoLead.status = 5;
              let submit = {
                documents: [this.cryptoLead],
              };

              axios.post(`${constants.apiHost}/document`, submit);
            }

            let router = this.$router;
            setTimeout(function () {
              this.createAgreementLoading = false;
              router.push(`/create-agreement?company=${val.id}`);
            }, 2000);
          })
          .catch((err) => {
            this.createAgreementLoading = false;
            console.log(err);
          });
      }
    },

    updateLeadStatus(status) {
      this.cryptoLead.status = status;
      let submit = {
        documents: [this.cryptoLead],
      };

      axios.post(`${constants.apiHost}/document`, submit).then((res) => {
        if ("status" in res && res.status != "errors") {
          this.$router.push("/crypto-leads");
        }
      });
    },
  },

  // call api for crypto lead data when page is mounted
  mounted() {
    if (this.$route.params.id) {
      axios
        .get(`${constants.apiHost}/document`, {
          params: {
            id: [this.$route.params.id],
          },
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.cryptoLead = res.data[0];
            if (!this.cryptoLead.attachments) {
              this.cryptoLead.attachments = [];
            }

            if (this.cryptoLead.owners.length > 0) {
              this.cryptoLead.owners.forEach((owner) => {
                this.owners.push(owner.id);
              });
            }
          } else {
            this.$router.push("/crypto-leads");
          }
        });
    } else {
      this.cryptoLead.id = this.makeid(20);
    }

    this.fetchEmployees();
  },
};
</script>

<style>
</style>
<template>
  <div>
    <h1>Admin Dashboard</h1>

    <v-btn depressed color="primary" @click="refreshGoogleApi">
      Refresh Google Api
    </v-btn>
    <br><br><br>
    <v-btn depressed color="primary" @click="xstateCreateAgreement">
      Create Agreement
    </v-btn>
<br><br><br>
    <v-btn depressed color="primary" @click="xstateSignDoc">
      Sign Document
    </v-btn>
<br><br><br>
    <v-btn depressed color="primary" @click="xstateOnboarding">
      Onboarding
    </v-btn>
<br><br><br>
    <v-btn depressed color="primary" @click="xstateSetInterviewDate">
      Set Interview Date
    </v-btn>
<br><br><br>
    <v-btn depressed color="primary" @click="xstateinterviewCompleted">
      Interview Completed
    </v-btn>
  </div>
</template>

<script>
// Update Api
import axios from "axios";
import constants from '../../data/constants';

export default {
  methods: {
    refreshGoogleApi() {
      axios
        .post(`${constants.apiHost}/refreshCalendar`)
        .then((res) => {
          console.log(res);
        });
    },
    xstateCreateAgreement() {
      axios
        .post(`${constants.apiHost}/createAgreement`, {"Event": "Create Agreement"})
        .then((res) => {
          console.log(res);
        });
    },
    xstateSignDoc() {
      axios
        .post(`${constants.apiHost}/signDoc`, {"Event": "Sign Document"})
        .then((res) => {
          console.log(res);
        });
    },
    xstateOnboarding() {
      axios
        .post(`${constants.apiHost}/onBoarding`, {"Event": "onBoarding"})
        .then((res) => {
          console.log(res);
        });
    },
    xstateSetInterviewDate() {
      axios
        .post(`${constants.apiHost}/setInterviewDate`, {"Event": "Set Interview Date"})
        .then((res) => {
          console.log(res);
        });
    },
    xstateinterviewCompleted() {
      axios
        .post(`${constants.apiHost}/interviewCompleted`, {"Event": "Interview Completed"})
        .then((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style>
</style>
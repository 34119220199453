<template>
  <div>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10">
          <v-card class="elevation-6 mt-6">
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-text class="mt-6">
                      <v-row align="center" justify="center">
                        <v-col cols="8">
                          <v-img width="300" src="/ntts-logo.png"></v-img>
                        </v-col>
                      </v-row>
                      <h4 class="text-center">Login in to Your Account</h4>
                      <v-row align="center" justify="center">
                        <v-col cols="12" sm="8">
                          <v-text-field
                            v-model="loginForm.email"
                            label="Email"
                            outlined
                            dense
                            color="blue"
                            autocomplete="false"
                            class="mt-16"
                          />
                          <v-text-field
                            v-model="loginForm.password"
                            label="Password"
                            outlined
                            dense
                            color="blue"
                            autocomplete="false"
                            type="password"
                          />
                          <v-row>
                            <v-col cols="12" sm="7">
                              <v-checkbox
                                v-model="loginForm.rememberMe"
                                label="Remember Me"
                                class="mt-n1"
                                color="#006c5d"
                              >
                              </v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="5">
                              <span
                                @click="forgotPasswordDialog = true"
                                class="caption darkgreen--text"
                              >
                                Forgot password
                              </span>
                            </v-col>
                          </v-row>
                          <v-btn
                            color="#006c5d"
                            @click="loginFormSubmit"
                            class="white--text"
                            block
                            tile
                          >
                            Log in
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    style="background-color: #206a5d"
                    class="rounded-bl-xl"
                  >
                    <div style="text-align: center; padding: 180px 0">
                      <v-card-text class="white--text">
                        <h3 class="text-center">Don't Have an Account Yet?</h3>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn
                          color="#006c5d"
                          class="white--text"
                          outlined
                          @click="step++"
                        >
                          SIGN UP
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item :value="2">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    style="background-color: #206a5d"
                    class="rounded-br-xl"
                  >
                    <div style="text-align: center; padding: 180px 0">
                      <v-card-text class="white--text">
                        <h3 class="text-center">Already Signed up?</h3>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn
                          color="#006c5d"
                          class="white--text"
                          outlined
                          @click="step--"
                        >
                          Log in
                        </v-btn>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-card-text class="mt-0">
                      <v-row align="center" justify="center">
                        <v-col cols="8">
                          <v-img width="300" src="/ntts-logo.png"></v-img>
                        </v-col>
                      </v-row>
                      <h4 class="text-center">Sign Up for an Account</h4>
                      <v-form ref="signupForm">
                        <v-row align="center" justify="center">
                          <v-col cols="12" sm="8">
                            <v-row>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="signupForm.firstName"
                                  label="First Name"
                                  outlined
                                  dense
                                  color="blue"
                                  autocomplete="false"
                                  class="mt-4"
                                />
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="signupForm.lastName"
                                  label="Last Name"
                                  outlined
                                  dense
                                  color="blue"
                                  autocomplete="false"
                                  class="mt-4"
                                />
                              </v-col>
                            </v-row>
                            <v-text-field
                              v-model="signupForm.email"
                              label="Email"
                              outlined
                              dense
                              color="blue"
                              autocomplete="false"
                            />

                            <v-text-field
                              v-model="signupForm.password"
                              label="Password"
                              :rules="passwordRule"
                              outlined
                              dense
                              color="#206a5d"
                              autocomplete="false"
                              type="password"
                            />

                            <v-text-field
                              v-model="signupForm.password_confirmation"
                              label="Re-enter password"
                              :rules="confirmPasswordRule"
                              outlined
                              dense
                              color="#206a5d"
                              autocomplete="false"
                              type="password"
                            />

                            <v-row>
                              <v-col cols="12" sm="7">
                                <v-checkbox
                                  v-model="signupForm.accept"
                                  label="I Accept"
                                  class="mt-n1"
                                  color="#006c5d"
                                >
                                </v-checkbox>
                              </v-col>
                              <v-col cols="12" sm="5">
                                <v-dialog v-model="termsDialog" max-width="400">
                                  <template v-slot:activator="{ on, attrs }">
                                    <span
                                      class="caption darkgreen--text ml-n4"
                                      v-bind="attrs"
                                      v-on="on"
                                      >Terms & Conditions</span
                                    >
                                  </template>
                                  <v-card>
                                    <v-card-title class="text-h5">
                                      Terms & Conditions
                                    </v-card-title>
                                    <v-card-text
                                      >Let Google help apps determine location.
                                      This means sending anonymous location data
                                      to Google, even when no apps are
                                      running.</v-card-text
                                    >
                                  </v-card>
                                </v-dialog>
                              </v-col>
                            </v-row>
                            <v-btn
                              color="#006c5d"
                              @click="signupFormSubmit"
                              class="white--text"
                              block
                              tile
                            >
                              Sign up
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="forgotPasswordDialog" persistent max-width="400">
      <v-card>
        <v-form ref="forgotPasswordForm">
          <v-card-title> Forgot Password </v-card-title>
          <v-card-text class="pb-0">
            <v-text-field
              v-model="forgotPasswordForm.email"
              label="Email"
              outlined
              dense
              color="blue"
              autocomplete="false"
              class="mt-3"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="forgotPasswordDialog = false"> Cancel </v-btn>
            <v-btn
              color="#006c5d"
              @click="forgotPasswordFormSubmit"
              class="white--text"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Update Api
import axios from "axios";
import nttslogo from "/Users/eric/.node-red/projects/Vuetifystepper/uibuilder/agreement-template/src/assets/ntts-logo.png";
import constants from "../data/constants";

export default {
  data() {
    return {
      logo: nttslogo,
      step: 1,
      termsDialog: false,
      forgotPasswordDialog: false,
      snackbar: false,
      snackbarMessage: "",
      loginForm: {
        email: null,
        password: "password",
        rememberMe: false,
        bucketName: constants.bucketName,
      },
      signupForm: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        password_confirmation: null,
        accept: false,
      },
      forgotPasswordForm: {
        email: null,
      },

      passwordRule: [(v) => !!v || "Password is required"],
      confirmPasswordRule: [
        (v) => !!v || "Password is required",
        (v) =>
          v == this.signupForm.password ||
          "The password confirmation does not match.",
      ],
    };
  },

  props: {
    source: String,
  },

  mounted() {
    let redirectTo = this.$route.fullPath;

    console.log(
      decodeURIComponent(this.$route.fullPath.replace("/login?redirectTo=", ""))
    );
  },

  methods: {
    loginFormSubmit() {
      // Login auth axios call
      // this.$router.push("/dashboard");

      let redirectTo = "";
      if (this.$route.query.redirectTo) {
        redirectTo = decodeURIComponent(
          this.$route.fullPath.replace("/login?redirectTo=", "")
        );
      }

      let me = this;
      axios
        .post(`${constants.host}/api/auth/login`, this.loginForm)
        .then(function (response) {
          // handle success
          let data = response.data;
          if ("status" in data) {
            me.snackbar = true;
            me.snackbarMessage = data.message;
          }
          if ("data.user" in data) {
            // sets user data to localStorage
            localStorage.setItem(
              "user_data",
              JSON.stringify(data["data.user"])
            );
            if (redirectTo != "") {
              me.$router.push(redirectTo);
            } else {
              me.$router.push("/dashboard");
            }
          }
        })
        .catch(function (error) {
          me.snackbar = true;
          me.snackbarMessage = error;
          console.log(error);
        });
    },

    signupFormSubmit() {
      console.log("signup Form Data ::::::::::::::::::::::", this.signupForm);

      if (this.$refs.signupForm.validate()) {
        // Registeration auth axios call
        let val = {
          name: `${this.signupForm.firstName} ${this.signupForm.lastName}`,
          firstName: this.signupForm.firstName,
          lastName: this.signupForm.lastName,
          email: this.signupForm.email,
          password: this.signupForm.password,
          bucketName: constants.bucketName,
        };
        let me = this;

        axios
          .post(`${constants.host}/api/user/register`, val)
          .then(function (response) {
            // handle success
            let data = response.data;
            console.log(data);
            if ("status" in data) {
              me.snackbar = true;
              me.snackbarMessage = data.message;
            }

            me.step--;
          })
          .catch(function (error) {
            me.snackbar = true;
            me.snackbarMessage = error;
            console.log(error);
          });
      }
    },

    forgotPasswordFormSubmit() {
      console.log(this.forgotPasswordForm);

      if (this.$refs.forgotPasswordForm.validate()) {
        // Registeration auth axios call
        let val = {
          email: this.forgotPasswordForm.email,
          bucketName: constants.bucketName,
          baseUrl: constants.frontEndHost,
        };
        let me = this;

        axios
          .post(`${constants.host}/api/user/resetPasswordMail`, val)
          .then(function (response) {
            // handle success
            let data = response.data;
            console.log(data);
            if ("status" in data) {
              me.snackbar = true;
              me.snackbarMessage = data.message;
            }
            me.forgotPasswordDialog = false;
          })
          .catch(function (error) {
            me.snackbar = true;
            me.snackbarMessage = error;
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.v-application .rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
  color: #206a5d;
}
.v-application .rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}
</style>
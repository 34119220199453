<template>
  <v-container>
    <v-card shaped>
      <v-toolbar color="teal" dark dense>
        <v-toolbar-title>Press Releases</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn class="pt-2" icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar>

      <v-timeline align-top dense>
        <v-timeline-item
          v-for="item in items"
          :key="item.sequence"
          :color="item.color"
          :icon="item.icon"
          fill-dot
        >
          <v-row class="pt-1">
            <v-col cols="3">
              <strong>{{ item.timestamp }}</strong>
            </v-col>
            <v-col>
              <strong>{{ item.event }}</strong>
              <div>
                <v-img max-height="45" max-width="65" :src="item.image"></v-img>
              </div>
              <div>
                <v-btn outlined :color="item.color" text :to="item.route">{{
                  item.title
                }}</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        sequence: 1,
        timestamp: "09/17/2021",
        event:
          "GlobeX Data Ltd.’s (OTCQB:SWISF) (CSE:SWIS) (FRA:GDT) interview with Mr. Alain Ghiai, CEO",
        icon: "mdi-draw",
        image: "",
        color: "red",
        route: "/press-release",
        title: "View Press Release",
      },{
        sequence: 2,
        timestamp: "09/15/2021",
        event:
          "N-Street Capital Research Initiates Coverage on PetVivo Holdings, Inc. and GlobeX Data Ltd.",
        icon: "mdi-draw",
        image: "",
        color: "red",
        route: "/press-release",
        title: "View Press Release",
      },{
        sequence: 3,
        timestamp: "09/14/2021",
        event:
          "New to the Street 228, September 12, 2021 | Sologenic, GlobeX Data, Black Bird Biotech, American Premium Water Corporation, Pink Panda Holdings, StackOS,...",
        icon: "mdi-draw",
        image: "",
        color: "red",
        route: "/press-release",
        title: "View Press Release",
      },
    ],
  }),
  methods: {
    rescheduleInterview() {
      console.log("reschedule");
    },

    cancelInterview() {
      console.log("cancel");
    },
    saveQuestions() {
      console.log("reschedule");
    },

    discardChanges() {
      console.log("cancel");
    },
  },
};
</script>

<style scoped></style>

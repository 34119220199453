<template>
  <v-app>
    <div>
      <v-app-bar
        absolute
        color="#006c5d"
        class="white--text"
        hide-on-scroll
        prominent
        height="64px"
        >
        <!-- scroll-target="#scrolling-techniques-4"-->
        <v-toolbar-title>NewToTheStreet</v-toolbar-title>

        <v-spacer></v-spacer>

        <template v-if="checkLogin">
          <v-dialog v-model="logoutDialog" persistent max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="white--text" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-logout</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h6"> Logging Out </v-card-title>
              <v-card-text>Are you sure you would like to logout?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="logoutDialog = false"
                >
                  No
                </v-btn>
                <v-btn color="green darken-1" text @click="handleLogoutEvent">
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-app-bar>
    </div>
    <br /><br />

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import helper from "../helper/helper";

export default {
  data() {
    return {
      logoutDialog: false,
    };
  },

  computed: {
    checkLogin() {
      if (helper.userData() != null) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    this.$vuetify.theme.dark = false;
  },

  methods: {
    handleLogoutEvent() {
      this.logoutDialog = false;

      // remove user_data from local storage and sends user back to login page
      localStorage.removeItem("user_data");
      this.$router.push("/login");
    },
  },
};
</script>

<template>
  <v-container>
    <v-card shaped>
      <v-toolbar color="teal" dark dense>
        <v-toolbar-title>Billing Log</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn class="pt-2" icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Invoice
              </th>
              <th class="text-left">
                Interview Sequence
              </th>
              <th class="text-left">
                Paid?
              </th>
              <th class="text-left">
                Paid Amount
              </th>
              <th class="text-left">
                Due On
              </th>
              <th class="text-left">
                Paid On
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in desserts" :key="item.sequence">
              <td>{{ item.invoice }}</td>
              <td>{{ item.sequence }}</td>
              <td>
                <v-chip :color="item.color" text-color="white">
                  {{ item.paid }}
                </v-chip>
              </td>
              <td>{{ item.dueOn }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.paidOn }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      desserts: [
        {
          invoice: "1929129212",
          sequence: "1 of 6",
          paid: true,
          amount: 1500,
          color: "green",
          dueOn: "10/10/2021",
          paidOn: "10/10/2021",
        },
        {
          invoice: "76767676777",
          sequence: "2 of 6",
          paid: true,
          amount: 1500,
          color: "green",
          dueOn: "10/10/2021",
          paidOn: "11/10/2021",
        },
        {
          invoice: "64545667676",
          sequence: "3 of 6",
          paid: true,
          amount: 1500,
          color: "green",
          dueOn: "10/10/2021",
          paidOn: "12/10/2021",
        },
        {
          invoice: "98986756444444",
          sequence: "4 of 6",
          paid: false,
          amount: 0,
          color: "red",
          dueOn: "10/10/2021",
          paidOn: "N/A",
        },
        {
          invoice: "12473642764734",
          sequence: "5 of 6",
          paid: false,
          amount: 0,
          color: "red",
          dueOn: "10/10/2021",
          paidOn: "N/A",
        },
        {
          invoice: "8987495839233",
          sequence: "6 of 6",
          paid: false,
          amount: 0,
          color: "red",
          dueOn: "10/10/2021",
          paidOn: "N/A",
        },
      ],
    };
  },
  methods: {
    sumField(key) {
      // sum data in give key (property)
      return this.desserts.reduce((a, b) => a + (b[key] || 0), 0);
    },
  },
};
</script>
<template>
  <div>
    <v-row>
      <h1>Interview Calendar</h1>
      <!-- toolbar schedule start -->
      <v-toolbar flat>
        <v-spacer></v-spacer>

        <v-btn outlined color="grey darken-2" @click="createEvent">
          <span>Schedule</span>
          <v-icon right> mdi-calendar-account </v-icon>
        </v-btn>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template> </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Schedule Meeting</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <div class="mb-4">
                  <b>Interviewer: </b>
                  {{ interviewer }}
                </div>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Event Title"
                      required
                      v-model="scheduleEvent.summary"
                      dense
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Description"
                      required
                      v-model="scheduleEvent.description"
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      label="Start Time"
                      required
                      v-model="scheduleEvent.startDateTime"
                      hint="MM/DD/YYYY hh:mm"
                      persistent-hint
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="End Time"
                      hint="MM/DD/YYYY hh:mm"
                      persistent-hint
                      v-model="scheduleEvent.endDateTime"
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>

                  <template v-for="(attendee, i) in scheduleEvent.attendees">
                    <v-col cols="12" sm="6" md="4" :key="i">
                      <v-text-field
                        :label="`Attendee ${i + 1} Email`"
                        required
                        v-model="attendee.email"
                        dense
                      ></v-text-field>
                    </v-col>
                  </template>

                  <v-col cols="12" sm="6">
                    <v-select
                      :items="['Nasdaq', 'Zoom']"
                      label="Location"
                      required
                      v-model="scheduleEvent.location"
                      dense
                    ></v-select>
                  </v-col>

                  <v-col v-if="scheduleEvent.location === 'Zoom'" cols="12">
                    <v-text-field
                      label="Zoom Link"
                      required
                      v-model="scheduleEvent.zoomLink"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="submitEvent()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </v-row>
    <!-- toolbar schedule end -->

    <!-- Today / Month toolbar start -->
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              outlined
              dense
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>

            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>

            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>

            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <!-- Today / Month toolbar end -->
        </v-sheet>

        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          ></v-calendar>

          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-btn icon @click="editselectedEvent">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>

                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon @click="cancelselectedEvent">
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </v-toolbar>

              <v-card-text>
                <b>Description</b> {{ selectedEvent.description }} <br />
                <b>Location: </b> {{ selectedEvent.location }} <br />
                <template v-if="selectedEvent.location == 'Zoom'">
                  <b>Zoom Link: </b>
                  <a :href="selectedEvent.zoomLink" target="_blank">
                    {{ selectedEvent.zoomLink }} </a
                  ><br />
                </template>
                <b>Attendees: </b>
                <span v-html="eventAttendees"></span>
              </v-card-text>

              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import axios from "axios";
import moment from "moment";
import constants from '../data/constants';

export default {
  data: () => ({
    // Calendar Submit
    scheduleEvent: {
      summary: "",
      location: "",
      description: "",
      startDate: "",
      startTime: "",

      // added
      startDateTime: "",
      endDateTime: "",
      endDate: "",
      endTime: "",
      attendee1: null,
      attendee2: null,
      attendee3: null,
      attendees: [{ email: "" }, { email: "" }, { email: "" }],
      leadName: "",
      id: "",
      zoomLink: "",
    },
    startDate: false,
    endDate: false,
    dialog: false,
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,

    startDateTime: false,
    showStartDatepicker: false,
    showStartTimePicker: false,

    // Event Date Format
    // It must be a Date, number of seconds since Epoch, or a string in the format of YYYY-MM-DD or YYYY-MM-DD hh:mm.
    //Zero-padding is optional and seconds are ignored.
    events: [],

    // brown has no color id
    colors: [
      { colorId: 1, color: "purple lighten-3" },
      { colorId: 2, color: "green lighten-3" },
      { colorId: 3, color: "purple darken-3" },
      { colorId: 4, color: "pink" },
      { colorId: 5, color: "yellow" },
      { colorId: 6, color: "orange" },
      { colorId: 7, color: "blue lighten-3" },
      { colorId: 8, color: "grey" },
      { colorId: 9, color: "blue darken-3", interviewer: "Anna" },
      { colorId: 10, color: "green darken-3", interviewer: "Jane" },
      { colorId: 11, color: "red" },
    ],
  }),

  computed: {
    eventAttendees() {
      var res = "";
      if (
        "attendees" in this.selectedEvent &&
        this.selectedEvent.attendees != undefined
      ) {
        this.selectedEvent.attendees.forEach((v, i) => {
          if (i == 0) {
            res += `${v.email}`;
          } else {
            res += `<br /> ${v.email}`;
          }
        });
      }
      return res;
    },

    interviewer() {
      if (this.selectedEvent.colorId) {
        let color = this.colors.find(
          (v) => v.colorId == this.selectedEvent.colorId
        );
        if (color) {
          return color.interviewer;
        }
      }
      return "";
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },

  methods: {
    // Reset event modal input fields
    createEvent() {
      this.scheduleEvent = {
        summary: "",
        location: "",
        description: "",
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        attendee1: null,
        attendee2: null,
        attendee3: null,
        attendees: [{ email: "" }, { email: "" }, { email: "" }],
        leadName: "",
        id: "",
        zoomLink: "",
      };

      this.dialog = true;
    },

    // Puts the selectedEvent data into the Schedule Event Form
    editselectedEvent() {
      console.log(this.selectedEvent);
      this.scheduleEvent.summary = this.selectedEvent.name;
      this.scheduleEvent.location = this.selectedEvent.location;
      this.scheduleEvent.description = this.selectedEvent.description;
      this.scheduleEvent.zoomLink = this.selectedEvent.zoomLink;
      this.scheduleEvent.startDateTime = moment(
        this.selectedEvent.start
      ).format("MM/DD/YYYY HH:mm");
      this.scheduleEvent.endDateTime = moment(this.selectedEvent.end).format(
        "MM/DD/YYYY HH:mm"
      );

      this.scheduleEvent.attendees = [
        { email: "" },
        { email: "" },
        { email: "" },
      ];

      if (
        "attendees" in this.selectedEvent &&
        this.selectedEvent.attendees != undefined
      ) {
        this.selectedEvent.attendees.forEach((v, i) => {
          this.scheduleEvent.attendees[i].email = v.email;
        });
      }

      this.scheduleEvent.id = this.selectedEvent.id;
      this.scheduleEvent.colorId = parseInt(this.selectedEvent.colorId);

      this.dialog = true;
    },

    // cancel selected event modal
    cancelselectedEvent() {
      let val = {
        id: this.selectedEvent.id,
      };

      axios
        .post(`${constants.apiHost}/leads-cancel`, val)
        .then((res) => {
          this.selectedOpen = false;
          this.events = this.events.filter((v) => v.id != val.id);
        });
    },

    // Save event details and store into database
    submitEvent() {
      let val = JSON.parse(JSON.stringify(this.scheduleEvent));
      val.attendees = val.attendees.filter((v) => v.email != "");
      let zoom = `\n\n Zoom Link: ${val.zoomLink}`;
      val.description = `${val.description} ${zoom}`;
      delete val.zoomLink;
      // This is used to set the event ID in the calendar event so we can identify the company

      axios
        .post(
          `${constants.apiHost}/leads-create-update`,
          val
        )
        .then((res) => {
          let color = "brown";
          if ("colorId" in res.data) {
            let getColor = this.colors.find(
              (v) => v.colorId == res.data.colorId
            );
            if (getColor) {
              color = getColor.color;
            }
          }

          let zoomLink = "";
          let description = res.data.description;
          if (
            res.data.description &&
            res.data.description.includes(" Zoom Link: ")
          ) {
            zoomLink = res.data.description.split(" Zoom Link: ").at(-1);
            description = description.replace(` Zoom Link: ${zoomLink}`, "");
          }

          let newObj = {
            name: res.data.summary,
            description: description,
            zoomLink: zoomLink,
            location: res.data.location,
            attendees: res.data.attendees,
            start: new Date(res.data.start.dateTime),
            end: new Date(res.data.end.dateTime),
            id: res.data.id,
            color: color,
            colorId: res.data.colorId,
            timed: "false",
            source: res.data.source,
          };

          // pushes event to local varaiable newObj so there is no need to fetch data from google api
          let createEvent = true;
          if (val.id == "") {
            this.events.push(newObj);
          } else {
            createEvent = false;
            this.events = this.events.map((v) => {
              if (v.id == newObj.id) {
                return newObj;
              }
              return v;
            });
            this.selectedEvent = newObj;
          }
        })
        .catch((err) => {
          if (err.error && err.error.message) {
            this.snackbar = true;
            this.snackbarMessage = err.error.message;
          }
        });

      this.dialog = false;
    },

    // default vuetify calendar function
    getDate(date) {
      return new Date(date);
    },

    // default vuetify calendar function
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    // default vuetify calendar function
    getEventColor(event) {
      return event.color;
    },

    // default vuetify calendar function
    setToday() {
      this.focus = "";
    },

    // default vuetify calendar function
    prev() {
      this.$refs.calendar.prev();
    },

    // default vuetify calendar function
    next() {
      this.$refs.calendar.next();
    },

    // handles when an event is clicked on the calendar
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        console.log(this.selectedEvent);

        let zoomLink = "";
        let description = this.selectedEvent.description;
        if (
          this.selectedEvent.description &&
          this.selectedEvent.description.includes(" Zoom Link: ")
        ) {
          zoomLink = this.selectedEvent.description
            .split(" Zoom Link: ")
            .at(-1);
          description = description.replace(` Zoom Link: ${zoomLink}`, "");
        }

        this.selectedEvent.description = description;
        this.selectedEvent.zoomLink = zoomLink;

        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },

  created() {
    // Calls google calendar api to get events
    axios
      .post(`${constants.apiHost}/calendar-type`, {
        calendartype: "schedule-interview",
      })
      .then((response) => {
        axios
          .get(`${constants.apiHost}/events-from-primary`)
          .then((res) => {
            this.allEvents = JSON.parse(JSON.stringify(res.data.items));

            // Iterates through Events and puts them in proper format for vuetify prop
            this.allEvents.forEach((item) => {
              let color = "brown";
              if ("colorId" in item) {
                let getColor = this.colors.find(
                  (v) => v.colorId == item.colorId
                );
                if (getColor) {
                  color = getColor.color;
                }
              }

              let newObj = {
                name: item.summary,
                description: item.description,
                location: item.location,
                attendees: item.attendees,
                start: new Date(item.start.dateTime),
                end: new Date(item.end.dateTime),
                id: item.id,
                color: color,
                colorId: item.colorId,
                timed: "false",
              };
              this.events.push(newObj);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
  },
};
</script>

<style>
</style>
<template>
  <div class="my-4">
    <h4 class="mb-2">Authorized Signer Details</h4>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="items.client.name"
          :rules="[rules.required]"
          label="Name"
          required
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="items.client.number"
          :rules="[rules.required]"
          label="Mobile Phone"
          required
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="items.client.email"
          :rules="[rules.required, rules.email]"
          label="Email"
          required
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="items.client.role"
          :rules="[rules.required]"
          label="Role"
          required
          dense
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- <h4 class="mb-2">Interviewee Details</h4>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="items.secondaryClient.name"
          :rules="[rules.required]"
          label="Name"
          required
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="items.secondaryClient.number"
          :rules="[rules.required]"
          label="Mobile Phone"
          required
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="items.secondaryClient.email"
          :rules="[rules.required, rules.email]"
          label="Email"
          required
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="items.secondaryClient.role"
          :rules="[rules.required]"
          label="Role"
          required
          dense
        ></v-text-field>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col>
        <v-btn text @click="updateStep(1)" class="mr-2"> Back </v-btn>

        <v-btn color="#006c5d" class="white--text" @click="updateStep(3)">
          Next
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { rules } from "@/data/rules";

export default {
  props: {
    step: Number,
    items: Object,
  },
  data() {
    return {
      rules,
    };
  },
  methods: {
    updateStep(to) {
      this.$emit("updateStep", to);
    },
  },
};
</script>
<template>
  <div>
    <v-alert v-if="error" prominent type="error">
      <v-row align="center">
        <v-col class="grow">
          {{ error }}
        </v-col>
      </v-row>
    </v-alert>
    <v-row v-else class="my-4" justify="center">
      <v-col cols="12" sm="8">
        <v-stepper v-if="step == 1 || step == 2" v-model="stepper" alt-labels>
          <!-- STEPPER HEADER -->
          <v-stepper-header>
            <v-stepper-step
              :complete="stepper > 1"
              step="1"
              color="#006c5d"
              class="white--text"
            >
              <small>Agreement</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="stepper > 2"
              step="2"
              color="#006c5d"
              class="white--text"
            >
              <small>Sign</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="stepper > 3"
              step="3"
              color="#006c5d"
              class="white--text"
            >
              <small>Overview</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="4" color="#006c5d" class="white--text">
              <small>Finish</small>
            </v-stepper-step>
          </v-stepper-header>

          <!-- Step 1 -->
          <v-stepper-content step="1">
            <div class="pa-4">
              <v-row>
                <v-col>
                  <p v-if="step == 1">
                    Please review the below agreement from New to the Street.
                    Once you have reviewed, please check agree below.
                  </p>
                  <p v-else>
                    Please review the below agreement. Once you have reviewed,
                    please check agree below.
                  </p>

                  <iframe :src="pdfSrc" width="100%" frameborder="0"></iframe>

                  <v-checkbox
                    v-model="agreed"
                    color="#006c5d"
                    class="white--text"
                  >
                    <template v-slot:label>
                      <span> I agree to the terms of this contract </span>
                    </template>
                  </v-checkbox>

                  <div class="text-right">
                    <v-btn
                      :disabled="!agreed"
                      color="#006c5d"
                      @click="stepper = 2"
                      class="px-6 white--text"
                    >
                      Next
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-stepper-content>

          <!-- STEP 2 -->
          <v-stepper-content step="2">
            <div class="pa-4">
              <v-row>
                <v-col>
                  <h1 class="text-h5">Sign</h1>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="2">
                  <v-text-field
                    v-model="initialLetter"
                    dense
                    label="Initial Letter"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">
                  <p>Your signature here!</p>
                  <SignaturePad ref="signaturePad" :step="stepper" />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="text-right">
                  <v-btn
                    color="#006c5d"
                    text
                    @click="stepper = 1"
                    class="mr-2 white--text"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    dark
                    color="#006c5d"
                    :loading="signBtnLoader"
                    @click="handleSign()"
                    class="px-6 white--text"
                  >
                    Sign
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-stepper-content>

          <!-- STEP 3 -->
          <v-stepper-content step="3">
            <div class="pa-4">
              <v-row>
                <v-col>
                  <p>
                    Please carefully review the document one last time and click
                    agree once you're finished.
                  </p>
                  <iframe
                    :src="signedPdfSrc"
                    width="100%"
                    frameborder="0"
                  ></iframe>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="text-right">
                  <v-btn
                    color="#006c5d"
                    text
                    @click="stepper = 2"
                    class="mr-2 white--text"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    dark
                    color="#006c5d"
                    :loading="agreeBtnLoader"
                    @click="updateContract()"
                    class="px-6 white--text"
                  >
                    Agree
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-stepper-content>

          <!-- STEP 4 -->
          <v-stepper-content step="4">
            <v-alert :value="true" type="success">
              <div v-if="step == 1">
                You have successfully completed the agreement. You will recieve
                a copy of the finalized agreement once it has been signed by all
                parties. You may now close this window.
              </div>
              <div v-else>
                You have successfully completed the agreement. A copy of the
                finalized agreement has been emailed to you. You can now close
                the window.
              </div>
            </v-alert>
          </v-stepper-content>
        </v-stepper>

        <div v-else-if="step == 3">
          <br /><br />
          <p>Finalized Agreement</p>
          <iframe :src="pdfSrc" width="100%" frameborder="0"></iframe>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Update Api

import axios from "axios";
import SignaturePad from "@/components/SignaturePad.vue";
import helper from "../helper/helper";
import constants from "../data/constants";

const params = new URLSearchParams(window.location.search);

export default {
  name: "App",

  components: {
    SignaturePad,
  },

  data() {
    return {
      stepper: 1, // this.step is steppers step. This is different from params.get('step') which represents client or host
      step: this.$route.query.step ?? 0, // this is based on params.get('step') and represents client or host or preview
      uid: this.$route.query.uid ?? 0,
      signBtnLoader: false,
      agreeBtnLoader: false,

      error: null,

      agreed: false,
      signed: false,

      pdfSrc: null,
      signedPdfSrc: null,

      initialLetter: null,
      form: null,

      previewPdfForm: {},

      userId: null,
      managingMember: {},
    };
  },

  computed: {},

  created() {
    if (this.uid == 0 || !["1", "2", "3"].includes(this.step)) {
      this.error = "Invalid URL";
    }

    // Added1027
    // Update Needed:  Need to add function on this page that checks if the User data matches either the client ID
    // or secondaryclient and then adds the agreement ID to the proper object
    // At the moment it is just being added to the client.id

    axios
      .get(`${constants.apiHost}/document`, {
        params: {
          id: [this.uid],
        },
      })
      .then((res) => {
        let data = res.data[0];
        this.form = data;
        console.log("this.form console: ", this.form);

        let company = {};
        axios
          .get(`${constants.apiHost}/document`, {
            params: {
              id: [this.form.company],
            },
          })
          .then((res) => {
            let data = res.data[0];
            company = data;

            this.previewPdfForm = {
              ...this.form,
              contractingCompany: "New to the Street Group LLC",
              clientCompanyName: company.name,
              clientAddressCity: company.city,
              clientAddressCountry: company.country,
              clientAddressLine1: company.addressLine1,
              clientAddressLine2: company.addressLine2,
              clientAddressState: company.state,
              clientAddressZipCode: company.zip,
              commonStock: company.commonStock,
              clientMainContactRole: this.form.client.role,
              clientMainContactEmail: this.form.client.email,
              clientMainContactMobilePhone: this.form.client.number,
              clientMainContactName: this.form.client.name,
              clientSignature: this.form.clientSignature
                ? this.form.clientSignature
                : null,
              contractSignature:
                this.form.contractSignature != "data:,"
                  ? this.form.contractSignature
                  : null,
              generateInvoice: false,
              submit: null,
              type: "agreement",
            };

            // /previewPDF

            axios
              .post(`${constants.apiHost}/previewPDF`, this.previewPdfForm)
              .then((res) => {
                this.pdfSrc = "data:application/pdf;base64," + res.data;
              });
          });
      });
  },

  methods: {
    handleSign() {
      const { isEmpty, signature } = this.$refs.signaturePad.save();

      if (!isEmpty) {
        this.signBtnLoader = true;
        if (this.step == 1) {
          this.previewPdfForm.clientSignature = signature;
          this.previewPdfForm.clientInitial = this.initialLetter;
          this.form.clientSignature = signature;
          this.form.clientInitial = this.initialLetter;
        } else {
          this.managingMember = {
            id: helper.userData().id,
            name: helper.userData().name,
          };

          this.previewPdfForm.contractSignature = signature;
          this.previewPdfForm.contractInitial = this.initialLetter;
          this.previewPdfForm.managingMember = this.managingMember;

          this.form.contractSignature = signature;
          this.form.contractInitial = this.initialLetter;
          this.form.managingMember = this.managingMember;
        }

        // /previewPDF
        axios
          .post(`${constants.apiHost}/previewPDF`, this.previewPdfForm)
          .then((res) => {
            this.signedPdfSrc = "data:application/pdf;base64," + res.data;
            this.form.document = "data:application/pdf;base64," + res.data;
            this.stepper = 3;
            this.signBtnLoader = false;
          });
      }
    },

    updateContract() {
      let val = {
        documents: [this.form],
      };
      this.agreeBtnLoader = true;

      axios.post(`${constants.apiHost}/document`, val).then((res) => {
        let obj = {
          agreement: this.form.id,
          id: helper.makeid(20),
          code: "",
          collectionName: "milestones",
          description: "",
          timestamp: Date.now(),
        };

        if (this.step == 1) {
          obj.code = "Step-1.5";
          obj.description = `${this.form.client.name} has signed the agreement`;
        } else {
          obj.code = "Step-2.5";
          obj.description = `${this.form.managingMember.name} has signed the agreement`;
        }

        let milestone = {
          documents: [obj],
        };

        axios.post(`${constants.apiHost}/document`, milestone).then(() => {
          this.handleSignDocument();
        });
      });
    },
    // Check if all parties have signed. If all parties have signed then it calls the onboarding x-state API
    checkSignatures() {
      let onboardingVal = {
        event: "onBoardingComplete",
        id: this.form.id,
      };

      if (
        this.form.clientInitial &&
        this.form.clientSignature &&
        this.form.contractSignature &&
        this.form.contractInitial
      ) {
        console.log("All Parties have Signed :::::::");
        //XSTATE
        axios
          .post(`${constants.apiHost}/onBoarding`, onboardingVal)
          .then(() => {
            //
          });
      } else {
        console.log("All Parties have not Signed :::::::");
      }
    },

    // xstate
    handleSignDocument() {
      const { isEmpty, signature } = this.$refs.signaturePad.save();

      let sign = signature;
      if (this.step == 1) {
        sign = this.form.clientSignature;
      } else {
        sign = this.form.contractSignature;
      }

      let val = {
        event: "signed",
        id: this.form.id,
        step: this.step,
        //   signature: sign,
        //   initials: this.initialLetter,
        //   document: this.signedPdfSrc,
      };
      //XSTATE

      let agreement = JSON.parse(JSON.stringify(this.form));
      helper.xstateMange(agreement);

      this.stepper = 4;
      this.agreeBtnLoader = false;

      // axios.post(`${constants.apiHost}/signDoc`, val).then((res) => {
      //   this.checkSignatures();
      //   this.stepper = 4;
      //   this.agreeBtnLoader = false;
      //   console.log("jump to step 4");
      //   console.log("response form sign doc api:::::", res);
      // });
    },
  },

  watch: {
    stepper(val) {
      if (val == 4) {
        let self = this;
        setTimeout(function () {
          self.$router.push("/dashboard");
        }, 4000);
      }
    },
  },
};
</script>

<style>
iframe {
  aspect-ratio: 1 / 1.05;
}
</style>
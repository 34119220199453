<template>
<v-container>
  <v-card shaped
  >

      <v-toolbar color="teal" dark dense>
        <v-toolbar-title>Events Timeline</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn class="pt-2" icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar>


    <v-timeline align-top dense>
    <v-timeline-item
      v-for="item in items"
      :key="item.sequence"
      :color="item.color"
      :icon="item.icon"
      fill-dot
    >
      <v-row class="pt-1">
        <v-col cols="3">
          <strong>{{ item.timestamp }}</strong>
        </v-col>
        <v-col>
          <strong>{{ item.event }}</strong>
          <div>
 
            <v-img max-height="45" max-width="65"
              :src="item.image"
            ></v-img>
        
          </div>
          <div>
            <v-btn outlined :color="item.color" text :to="item.route">{{item.title}}</v-btn>
          </div>
        </v-col>
        
      </v-row>
    </v-timeline-item>
  </v-timeline>
  </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        sequence: 1,
        timestamp: "10/10/2021 08:00 AM",
        event: "Agreement Signed",
        icon: "mdi-draw",
        image: "",
        color: "red",
        route: "/agreement",
        title: "View Agreement",
      },
      {
        sequence: 2,
        timestamp: "10/11/2021 12:00 AM",
        event: "First Interview Scheduled with Jane King",
        icon: "mdi-calendar-account-outline",
        image: "jane-king.gif",
        color: "primary",
        route: "https://www.confluence.myorg.com",
        title: "View Schedule",
      },
      {
        sequence: 3,
        timestamp: "10/17/2021 12:00 AM",
        event: "First Interview Aired",
        icon: "mdi-broadcast",
        image: "CNN-logo.png",
        color: "success",
        route: "https://www.confluence.myorg.com",
        title: "Watch it now!",
      },
    ],
  }),
  methods: {
    rescheduleInterview() {
      console.log("reschedule");
    },

    cancelInterview() {
      console.log("cancel");
    },
    saveQuestions() {
      console.log("reschedule");
    },

    discardChanges() {
      console.log("cancel");
    },
  },
};
</script>

<style scoped></style>
<template>
  <div>
    <interviewee-card
      v-if="profileData != null"
      :user="profileData"
    ></interviewee-card>
  </div>
</template>





<script>
// Update Api
import axios from "axios";
import IntervieweeCard from "../components/dashboard/IntervieweeCard.vue";
import constants from "../data/constants";

export default {
  components: { IntervieweeCard },

  data() {
    return {
      profileData: null,
      userDisplay: null,
    };
  },
  created() {
    let userData = localStorage.getItem("user_data");
    userData = JSON.parse(userData);
    let uid = userData.id;
    console.log("uid", uid);
    axios
      .get(`${constants.apiHost}/document`, {
        params: {
          id: ["cfcd1205-148c-47a2-8c74-c1f56f01f783"],
        },
      })
      .then((res) => {
        this.profileData = res.data[0];
      });
  },
  methods: {
    getUserData() {
      let userData = localStorage.getItem("user_data");
      userData = JSON.parse(userData);
      this.userDisplay = userData;
    },
  },
};
</script>

<style>
</style>

    <template>
  <billing-events />
</template>
    
<script>
export default {
  components: {
    "billing-events": require("@/components/billing/billingEvents").default,
  },
};
</script>
<template>
  <div>
    <v-navigation-drawer
      v-model="dialog"
      absolute
      right
      temporary
      width="700"
      clipped
    >
      <v-toolbar color="#006c5d" class="white--text">
        <v-toolbar-title>
          {{ lead.companyName ? lead.companyName : lead.name }}
          Attachments
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- beginning timeline -->
      <v-container>
        <v-file-input
          label="File input"
          v-model="input"
          @change="handleImage"
          hide-details
          outlined
          dense
          @keydown.enter="comment"
        ></v-file-input>

        <v-btn
          class="my-3 white--text"
          color="#206a5d"
          @click="comment"
          :disabled="input == null"
        >
          Post
        </v-btn>

        <v-card flat fixed class="overflow-y-auto" max-height="500">
          <template v-for="event in attachments">
            <v-card class="my-3" :key="event.timestamp">
              <v-card-title class="mb-2">
                <v-row align="center" justify="space-between">
                  <div>
                    <v-avatar color="indigo mr-2" size="36">
                      <span class="white--text text-h6">
                        {{ event.userInitials }}
                      </span>
                    </v-avatar>
                  </div>
                  <div style="font-size: 12px">
                    <span v-text="event.timestamp"></span>
                  </div>
                </v-row>
              </v-card-title>

              <v-card-text>
                <a :href="event.file" target="_blank">
                  {{
                    event.file_name ? event.file_name : "Download Attachment"
                  }}
                </a>
              </v-card-text>
            </v-card>
          </template>
        </v-card>
      </v-container>

      <!-- End timeline -->
    </v-navigation-drawer>
  </div>
</template>

<script>
import moment from "moment";
import constants from "../data/constants";
import helper from "../helper/helper";
import axios from "axios";
export default {
  props: {
    dialog: false,
    lead: {},
  },

  data() {
    return {
      input: null,
      attachment: null,
    };
  },

  mounted() {
    console.log(this.lead);
  },

  computed: {
    userData() {
      return helper.userData();
    },

    userInitials() {
      let user = this.userData;
      return user.firstName.charAt(0) + user.lastName.charAt(0);
    },

    attachments() {
      // return this.lead.attachments.reverse();
      console.log("test");
      return this.lead.attachments.sort((a, b) => {
        let timeA = moment(a.timestamp, "hh:mm:ss a, MM-DD-YYYY");
        let timeB = moment(b.timestamp, "hh:mm:ss a, MM-DD-YYYY");
        return timeA.isBefore(timeB) ? 1 : -1;
      });
    },
  },

  methods: {
    //timeline in notes
    comment() {
      let time = moment().format("hh:mm:ss a, MM-DD-YYYY");

      let newEvent = {
        file_name: this.input.name,
        file: this.attachment,
        userId: this.userData.id,
        userInitials: this.userInitials,
        timestamp: time,
      };

      if (!("attachments" in this.lead)) {
        this.lead.attachments = [];
      }

      this.lead.attachments.push(newEvent);
      console.log(this.lead);

      let submit = {
        documents: [this.lead],
      };

      axios.post(`${constants.apiHost}/document`, submit).then((res) => {});

      this.input = null;
    },

    // called when a uesr uploads a new image file
    handleImage(e) {
      this.createBase64Image(e);
    },

    // intakes a image file
    // returns a base64 string
    //https://medium.com/js-dojo/how-to-upload-base64-images-in-vue-nodejs-4e89635daebc
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.attachment = e.target.result;
      };

      reader.readAsDataURL(fileObject);
    },
  },
};
</script>

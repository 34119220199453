<template>
  <div>
    <v-row justify="center" class="mt-5">
      <v-col cols="6">
        <v-row justify="center">
          <v-card class="mx-auto" max-width="434" tile>
            <v-img
              height="100%"
              src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"
            >
              <v-row align="end" class="fill-height">
                <v-col align-self="start" class="pa-0" cols="12">
                  <v-avatar class="profile" color="grey" size="164" tile>
                    <v-img :src="image"></v-img>
                  </v-avatar>
                </v-col>
                <v-col class="py-0">
                  <v-list-item color="rgba(0, 0, 0, .4)" dark>
                    <v-list-item-content>
                      <v-list-item-title class="text-h6">
                        {{ firstName }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ title }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-img>
          </v-card>
        </v-row>

        <v-form ref="form" class="mt-5" v-model="valid" lazy-validation>
          <v-file-input
            v-model="newFile"
            @change="handleImage"
            accept="image/*"
            label="File input"
          ></v-file-input>

          <v-text-field
            v-model="title"
            :rules="titleRules"
            label="Title"
            required
          ></v-text-field>

          <v-text-field
            v-model="firstName"
            :rules="firstNameRules"
            label="First Name"
            required
          ></v-text-field>

          <v-text-field
            v-model="lastName"
            :rules="lastNameRules"
            label="Last Name"
            required
          ></v-text-field>

          <v-text-field
            v-model="phoneNumber"
            :rules="phoneNumberRules"
            label="Phone Number"
            required
          ></v-text-field>

          <v-text-field
            v-model="workPhone"
            :rules="workPhoneRules"
            label="Work Phone"
            required
          ></v-text-field>

          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
            readonly
          ></v-text-field>

          <v-btn
            :disabled="!valid"
            color="#006c5d"
            class="mr-4 white--text"
            @click="validate"
          >
            Submit
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// Update Api
import axios from "axios";
import helper from "../helper/helper";
import constants from '../data/constants';

export default {
  data() {
    return {
      valid: true,
      title: null,
      titleRules: [(v) => !!v || "This is required"],
      firstName: null,
      firstNameRules: [(v) => !!v || "This is required"],
      lastName: null,
      lastNameRules: [(v) => !!v || "This is required"],
      email: null,
      emailRules: [(v) => !!v || "This is required"],
      phoneNumber: null,
      phoneNumberRules: [(v) => !!v || "This is required"],
      workPhone: null,
      workPhoneRules: [(v) => !!v || "This is required"],
      image: null,
      imageRules: [(v) => !!v || "This is required"],

      newFile: null,

      snackbar: false,
      snackbarMessage: "Profile updated",
    };
  },

  computed: {
    // this creates the uid variable from local strage when user_data changes
    // This will always get the current logged in user uid
    uid() {
      let userData = localStorage.getItem("user_data");
      userData = JSON.parse(userData);
      return userData.id;
    },
  },
  // This api is called when the page is loaded
  // I need to replace the static id with the uid
  mounted() {
    console.log("uid", this.uid);
    axios
      .get(`${constants.apiHost}/document`, {
        params: {
          id: [this.uid],
        },
      })
      .then((res) => {
        let data = res.data[0];
        this.title = data.title;
        this.name = `${data.firstName} ${data.lastName}`;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.phoneNumber = data.phoneNumber;
        this.workPhone = data.workPhone;
        this.image = data.image;
      });
  },

  methods: {
    // called when a uesr uploads a new image file
    handleImage(e) {
      this.createBase64Image(e);
    },
    // intakes a image file
    // returns a base64 string
    //https://medium.com/js-dojo/how-to-upload-base64-images-in-vue-nodejs-4e89635daebc
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };

      reader.readAsDataURL(fileObject);
    },
    // make sure to update with computed uid
    // this function formats the data
    validate() {
      let userProfile = {
        ...helper.userData(),
        title: this.title,
        name: `${this.firstName} ${this.lastName}`,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        workPhone: this.workPhone,
        image: this.image,
      };

      let val = {
        documents: [userProfile],
      };

      console.log(val);

      axios
        .post(`${constants.apiHost}/document`, val)
        .then((res) => {
          localStorage.setItem("user_data", JSON.stringify(userProfile));
          this.snackbar = true;
        });
    },
  },
};
</script>
<template>
  <div>
    <v-app id="inspire">
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$vuetify.theme.dark = false;
  },
};
</script>

<template>
  <v-form v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="searchAgreement"
            :rules="nameRules"
            label="Search Agreement here"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn class="mr-4" @click="submit"> submit </v-btn>
    </v-container>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      searchAgreement: null,
      nameRules: [(v) => !!v || "Name is required"],
    };
  },

  methods: {
    submit() {
      console.log(this.searchAgreement);
    },
  },
};
</script>

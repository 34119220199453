<template>
  <p class="mb-0">{{label}}: <span class="font-weight-bold">{{ value }}</span></p>
</template>

<script>
export default {
  props: {
    label: String,
    value: String
  }
}
</script>
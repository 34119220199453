// Defines which logged in users can access specific pages 

export default function role({ to, next, userData }) {
    //[1, 2 ,3].includes(3); this will return true
    // The code below checks if the users role is in the array of allowed roles for a route
    if (to.meta.role.includes(userData.role)) {
        return next();
    }
    return next('/profile');

}

<template>
  <v-container fluid>
    <v-card
      elevation="2"
      shaped
      :loading="loading"
      class="mx-auto"
      max-width="374"
    >
      <v-toolbar color="teal" dark dense>
              <v-toolbar-title>Upcoming Interview </v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>

            
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-img height="250" :src="anchorPhoto"></v-img>

      <v-card-title>
        <v-icon class="pa-3">
          mdi-account-voice
        </v-icon>
        Anchor: {{ anchorName }}
      </v-card-title>

      <v-divider class="mx-4"></v-divider>

      <v-card-title>
        <v-icon class="pa-3"> mdi-calendar-clock </v-icon>Interview Scheduled
        on:
      </v-card-title>

      <v-card-text class="ml-14">
        <v-chip color="teal white--text">{{ schedule }}</v-chip>
      </v-card-text>

      <v-card-actions>
        <v-btn color="red" text @click="rescheduleInterview">
          Reschedule
        </v-btn>
        <v-btn color="grey" text @click="cancelInterview">
          Cancel
        </v-btn>
      </v-card-actions>
      <v-divider class="mx-4"></v-divider>

      <v-card-actions>
        <v-card-title>Interview Questions:</v-card-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
          <v-card max-width="650">
            <v-expansion-panels accordion>
              <v-expansion-panel v-for="item in questions" :key="item.sequence">
                <v-expansion-panel-header>
                  <h3>Question: {{ item.sequence }}</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-textarea
                    name="question"
                    :value="item.question"
                    hint="Type the question you want to be asked"
                    prepend-inner-icon="mdi-message-question-outline"
                    class="mx-2"
                    rows="2"
                  ></v-textarea>
                  <h3>Answer: {{ item.sequence }}</h3>
                  <v-textarea
                    name="question"
                    :value="item.answer"
                    hint="Type you main answer to the question"
                    prepend-inner-icon="mdi-comment"
                    placeholder="Type here your answer..."
                    class="mx-2"
                    rows="2"
                  ></v-textarea>
                  <v-combobox
                    v-model="model"
                    :items="comments"
                    :search-input.sync="search"
                    hide-selected
                    hint="Enter keywords and topics to mention"
                    label="Add comments/highlights..."
                    multiple
                    persistent-hint
                    small-chips
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ search }}</strong
                            >". Press <kbd>enter</kbd> to create a new one
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-card-actions>
              <v-btn color="deep-purple lighten-2" text @click="saveQuestions">
                Save Changes
              </v-btn>
              <v-btn color="deep-purple lighten-2" text @click="discardChanges">
                Discard
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-expand-transition>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    Questions: {
      type: Object,
      default: null,
    },
    Anchor: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    anchorName: "Jane King",
    anchorPhoto: "jane-king.gif",
    title: "Upcoming Interview",
    schedule: "10/10/2021 07:00 AM",
    model: "",
    search: "",
    show: false,
    loading: false,
    comments: ["stock increase rate", "reorganization of the company", "improved customer service"],
    questions: [
      {
        sequence: 1,
        question: "Why should customers want to use GlobeX data services?",
        answer: "",
      },
      {
        sequence: 2,
        question: "When are you planning to go public?",
        answer: "",
      },
      {
        sequence: 3,
        question:
          "Are there any upcoming new services and features for your main product?",
        answer: "",
      },
      {
        sequence: 4,
        question: "What value add is GlobeX bringing to its customers?",
        answer: "",
      },
      {
        sequence: 5,
        question: "What makes GlobeX data different from its competition?",
        answer: "",
      },
      {
        sequence: 6,
        question: "Can you give an overview of the roadmap GlobeX commited to?",
        answer: "",
      },
    ],
  }),
  methods: {
    rescheduleInterview() {
      console.log("reschedule");
    },

    cancelInterview() {
      console.log("cancel");
    },
    saveQuestions() {
      console.log("reschedule");
    },

    discardChanges() {
      console.log("cancel");
    },
  },
};
</script>

<style scoped></style>
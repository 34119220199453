<template>
  <v-container fluid>
    <v-card shaped>
      <v-toolbar color="#006c5d" dark dense>
        <v-toolbar-title>Profile </v-toolbar-title>
      </v-toolbar>
      <v-img max-height="200" max-width="314" :src="user.image"> </v-img>
      <v-card-title class="pl-2 pt-2">
        <h2 class="pa-2">{{ user.name }}</h2>
      </v-card-title>

      <v-card-subtitle class="pl-2 pt-2">
        <h3 class="pa-2">{{ user.title }}</h3>
      </v-card-subtitle>

      <v-card-actions>
        <v-btn color="orange lighten-2" text> Show More... </v-btn>

        <v-spacer></v-spacer>

        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
          <v-list two-line>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-phone </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ user.phoneNumber }}</v-list-item-title>
                <v-list-item-subtitle>Mobile</v-list-item-subtitle>
              </v-list-item-content>

              <!-- <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon> -->
            </v-list-item>

            <v-list-item>
              <!-- <v-list-item-action></v-list-item-action> -->

              <v-list-item-icon>
                <v-icon color="indigo"> mdi-phone </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ user.workPhone }}</v-list-item-title>
                <v-list-item-subtitle>Work</v-list-item-subtitle>
              </v-list-item-content>

              <!-- <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon> -->
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-email </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ user.email }}</v-list-item-title>
                <v-list-item-subtitle>Work</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <!-- <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-map-marker </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ user.addressLine1 }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ user.addressLine2 }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </div>
      </v-expand-transition>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  props: {
    user: {
      required: false,
      type: Object,
      default() {
        return {
          name: "Alain Ghiai",
          //role: "President/CEO/Secretary, GlobeX Data Ltd",
          company: "Amazon Static Company",
          // settings: {
          //   lang: "9226e769-3bde-42ad-a7cb-96170e2c78bd",
          //   roles: ["contractor"],
          // },
          image: "Alain Ghiai.jpg",
          phoneNumber: "(650) 555-1234",
          workPhone: "(323) 555-6789",
          referredBy: "alain.ghiai@globexData.com",
          // addressLine1:
          //   "First Canadian Place 100 King Street West, Suite 5600 ",
          // addressLine2: "Toronto, ON M5X 1C9",
          // logo: "globex-logo.jpg",
          // bio: "lain Ghiai is a Swiss national and Founder, CEO & Chairman of Switzerland based GlobeX Data S.A. and of publicly traded GlobeX Data Ltd., the leading company for Swiss hosted cyber security and Internet Privacy solutions for secure data management and secure communications.",
        };
      },
    },
  },
  data() {
    return {
      show: true,
      companyDetail: {},
    };
  },

  mounted() {
    console.log(this.user);
    // if (this.user.company != null) {
    //   axios
    //     .get("https://prodeo-nodered-dev.prodeo-live.com/document", {
    //       params: {
    //         id: [this.user.company],
    //       },
    //     })
    //     .then((res) => {
    //       this.companyDetail = res.data[0];
    //     });
    // }
  },

  methods: {},
};
</script>
    
    
// Auth middlewear is required for any page that should only be accessable after login

// http://localhost:4200/agreement-template/#/signature-agreement?uid=0O3OXrzAakA8jIHGBqIa&step=1
// signature-agreement?uid=0O3OXrzAakA8jIHGBqIa&step=1
export default function auth({ to, from, next, userData }) {
    let redirect = `?redirectTo=${to.fullPath}`;
    if (!userData) {
        return next(`/login${redirect}`);
    }
    return next();
}

<template>
  <div>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="selectedCompany"
          :items="companies"
          item-text="name"
          item-value="id"
          label="Client Company Name"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <div>
      <br />
      <template v-if="selectedCompany">
        <v-container class="grey lighten-5">
          <v-row no-gutters>
            <v-col>
              <v-card class="pa-3" outlined tile>
                <b> {{ selectedCompanyData.interview.agreementName }} </b>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="pa-2" outlined tile>
                <b>Status:</b>
                <v-chip color="teal white--text">
                  {{ selectedCompanyData.interview.status }}
                </v-chip>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="pa-2" outlined tile>
                <b>Interview:</b>
                <v-chip color="blue white--text">
                  {{ selectedCompanyData.interview.interviewNumber }}
                </v-chip>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </div>

    <!-- Container Begin Columns -->
    <v-container class="grey lighten-5">
      <v-row class="mb-6" no-gutters>
        <v-col sm="5" md="6">
          <!-- Upcoming Interview v-card begins -->
          <template v-if="selectedCompany">
            <v-card
              elevation="2"
              shaped
              :loading="loading"
              class="mx-auto"
              max-width="374"
            >
              <v-toolbar color="teal" dark dense>
                <v-toolbar-title
                  >Upcoming Interview
                  {{
                    selectedCompanyData.interview.interviewNumber
                  }}</v-toolbar-title
                >

                <v-spacer></v-spacer>

                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-toolbar>
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-img
                height="200"
                :src="selectedCompanyData.interview.anchorImage"
              ></v-img>

              <v-card-title>
                <v-icon class="pa-3"> mdi-account-voice </v-icon>
                Anchor: {{ selectedCompanyData.interview.anchor }}
              </v-card-title>

              <v-img
                height="200"
                :src="selectedCompanyData.interview.intervieweeImage"
              ></v-img>

              <v-card-title>
                <v-icon class="pa-3"> mdi-account-voice </v-icon>
                Interviewee: {{ selectedCompanyData.interview.interviewee }}
              </v-card-title>

              <v-divider class="mx-4"></v-divider>

              <v-card-title>
                <v-icon class="pa-3"> mdi-calendar-clock </v-icon>Interview
                Scheduled on:
              </v-card-title>

              <v-card-text class="ml-14">
                <v-chip color="teal white--text">{{
                  selectedCompanyData.interview.scheduled
                }}</v-chip>
              </v-card-text>

              <v-card-actions>
                <v-btn color="red" text @click="rescheduleInterview">
                  Reschedule
                </v-btn>
                <v-btn color="grey" text @click="cancelInterview">
                  Cancel
                </v-btn>
              </v-card-actions>
              <v-divider class="mx-4"></v-divider>

              <v-card-actions>
                <v-card-title>Interview Questions:</v-card-title>

                <v-spacer></v-spacer>

                <v-btn icon @click="show = !show">
                  <v-icon>{{
                    show ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>
                  <v-card max-width="650">
                    <v-expansion-panels accordion>
                      <v-expansion-panel
                        v-for="item in this.questions"
                        :key="item.sequence"
                      >
                        <v-expansion-panel-header>
                          <h3>Question: {{ item.sequence }}</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-textarea
                            name="question"
                            :value="item.question"
                            hint="Type the question you want to be asked"
                            prepend-inner-icon="mdi-message-question-outline"
                            class="mx-2"
                            rows="2"
                          ></v-textarea>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-card-actions>
                      <v-btn
                        color="deep-purple lighten-2"
                        text
                        @click="saveQuestions"
                      >
                        Save Changes
                      </v-btn>
                      <v-btn
                        color="deep-purple lighten-2"
                        text
                        @click="discardChanges"
                      >
                        Discard
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
              </v-expand-transition>
            </v-card>
          </template>
        </v-col>
        <v-col sm="5" offset-sm="2" md="6" offset-md="0">
          <template v-if="selectedCompany">
            <v-container style="max-width: 100%">
              <v-timeline dense clipped>
                <v-timeline-item class="mb-6" hide-dot>
                  <span
                    ><h1>{{ selectedCompanyData.name }} Timeline</h1></span
                  >
                </v-timeline-item>

                <v-timeline-item
                  v-for="data in selectedCompanyData.data"
                  :key="data.time"
                  class="mb-4"
                  :color="data.color ? data.color : 'grey'"
                  small
                >
                  <v-row justify="space-between">
                    <v-col cols="7">
                      <v-chip
                        v-if="data.chip != ''"
                        class="white--text ml-0"
                        :color="data.chipColor"
                        label
                        small
                      >
                        {{ data.chip }}
                      </v-chip>
                      {{ data.text }}
                    </v-col>
                    <v-col class="text-right" cols="5"> {{ data.time }} </v-col>
                  </v-row>
                  <v-btn v-if="data.btn" class="mx-0">{{ data.btn }}</v-btn>
                </v-timeline-item>
              </v-timeline>
            </v-container>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <v-card class="mx-auto text-center" color="green" dark max-width="600">
      <v-card-text>
        <v-sheet color="rgba(0, 0, 0, .12)">
          <v-sparkline
            :value="value"
            color="rgba(255, 255, 255, .7)"
            height="100"
            padding="24"
            stroke-linecap="round"
            smooth
          >
            <template v-slot:label="item"> ${{ item.value }} </template>
          </v-sparkline>
        </v-sheet>
      </v-card-text>

      <v-card-text>
        <div class="text-h4 font-weight-thin">Sales Last 6 Months</div>
      </v-card-text>
    </v-card>
    <!-- </template> -->

    <!-- End of Timeline -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {
    "upcoming-interview":
      require("@/components/dashboard/upcomingInterview.vue").default,
  },
  data() {
    return {
      selectedCompany: null,
      companies: [],
      value: [1000, 1500, 1000, 1000, 1500, 1000, 1000],
      events: [],
      input: null,
      nonce: 0,

      amazonData: [
        {
          color: "grey",
          text: "Agreement Created by Adam Smith (adam.smith@amazon.com)",
          btn: "",
          btnColor: "",
          chip: "",
          chipColor: "",
          time: "15:26 EDT 10/07/2021",
        },
        {
          color: "pink",
          text: "Authorized Signer Sam Smith Signed Contract",
          btn: "",
          btnColor: "",
          chip: "Signature",
          chipColor: "purple",
          time: "9:25 EDT 10/08/2021",
        },
        {
          color: "blue",
          text: "Agreement Signed by NTTS Managing Partner Avi",
          btn: "Resend Agreement to Adam Smith",
          btnColor: "",
          chip: "",
          chipColor: "",
          time: "19:25 EDT 10/08/21",
        },
        {
          text: "A $1500.00 USD payment was made by Amazon to NewToTheStreet",
          btn: "",
          btnColor: "",
          chip: "Deposit",
          chipColor: "green",
          time: "12:37 EDT 10/10/21",
        },
        {
          text: "Karen johnson (Interviewee) has scheduled an Interview with Jane King on 10/22/21",
          btn: "",
          btnColor: "",
          chip: "Interview",
          chipColor: "blue",
          time: "15:25 EDT 10/11/21",
        },
      ],

      googleData: [
        {
          color: "green",
          text: "Agreement Created by {sales rep}",
          btn: "",
          btnColor: "",
          chip: "Agreement",
          chipColor: "red",
          time: "15:26 EDT 10/07/2021",
        },
        {
          color: "green",
          text: "Agreement Signed by {managing partner}",
          btn: "",
          btnColor: "",
          chip: "Signature",
          chipColor: "purple",
          time: "9:25 EDT 10/08/2021",
        },
        {
          color: "blue",
          text: "Agreement Signed by Joseph Clark (joseph@google.com)",
          btn: "Resend Agreement to Joseph Clark",
          btnColor: "",
          chip: "signature",
          chipColor: "purple",
          time: "19:25 EDT 10/08/21",
        },
        {
          color: "blue",
          text: "Interview Scheduled by Joseph Clark (joseph@google.com) for 10/27/2021",
          btn: "",
          btnColor: "",
          chip: "Interview",
          chipColor: "blue",
          time: "12:37 EDT 10/10/21",
        },
        {
          color: "green",
          text: "Jane King accepted interview with Joseph Clark (joseph@google.com) for 10/27/2021 ",
          btn: "",
          btnColor: "",
          chip: "Interview",
          chipColor: "blue",
          time: "15:25 EDT 10/11/21",
        },
      ],

      facebookData: [
        {
          color: "green",
          text: "Invoice sent for monthly payment 2/6",
          btn: "",
          btnColor: "",
          chip: "Invoice",
          chipColor: "green",
          time: "8:45 EDT 10/01/2021",
        },
        {
          color: "blue",
          text: "Montly Payment 2/6 received",
          btn: "",
          btnColor: "",
          chip: "Payment",
          chipColor: "green",
          time: "12:25 EDT 10/01/2021",
        },
        {
          color: "green",
          text: "Email sent to James Thomas (James@facebook.com) to schedule interview 2/6",
          btn: "Resend schedule email to James Thomas",
          btnColor: "",
          chip: "",
          chipColor: "",
          time: "13:00 EDT 10/01/21",
        },
        {
          color: "blue",
          text: "James Thomas (James@facebook.com) scheduled interview 2/6 for 10/15/2021 at 11 A.M.",
          btn: "",
          btnColor: "view calendar",
          chip: "Interview",
          chipColor: "blue",
          time: "12:37 EDT 10/05/21",
        },
        {
          color: "green",
          text: "Jane King accepted Interview 2/6 with James Thomas (James@facebook.com) on 10/15/2021 at 11 A.M.  ",
          btn: "Reschedule Interview",
          btnColor: "",
          chip: "Interview",
          chipColor: "blue",
          time: "14:25 EDT 10/05/21",
        },
        {
          color: "green",
          text: "Interview Completed: Jane King interviewing James Thomas (James@facebook.com)",
          btn: "",
          btnColor: "",
          chip: "Interview",
          chipColor: "blue",
          time: "15:25 EDT 10/15/21",
        },
        {
          color: "green",
          text: "Interview Broadcasted",
          btn: "view interview",
          btnColor: "",
          chip: "Interview",
          chipColor: "blue",
          time: "17:25 EDT 10/17/21",
        },
      ],

      items: [
        {
          sequence: 1,
          timestamp: "10/10/2021 08:00 AM",
          event: "Agreement Signed",
          icon: "mdi-draw",
          image: "",
          color: "red",
          route: "/agreement",
          title: "View Agreement",
        },
        {
          sequence: 2,
          timestamp: "10/11/2021 12:00 AM",
          event: "First Interview Scheduled with Jane King",
          icon: "mdi-calendar-account-outline",
          image: "jane-king.gif",
          color: "primary",
          route: "https://www.confluence.myorg.com",
          title: "View Schedule",
        },
        {
          sequence: 3,
          timestamp: "10/17/2021 12:00 AM",
          event: "First Interview Aired",
          icon: "mdi-broadcast",
          image: "CNN-logo.png",
          color: "success",
          route: "https://www.confluence.myorg.com",
          title: "Watch it now!",
        },
        {
          sequence: 4,
          timestamp: "10/10/2021 08:00 AM",
          event: "Agreement Signed",
          icon: "mdi-draw",
          image: "",
          color: "red",
          route: "/agreement",
          title: "View Agreement",
        },
        {
          sequence: 5,
          timestamp: "10/11/2021 12:00 AM",
          event: "First Interview Scheduled with Jane King",
          icon: "mdi-calendar-account-outline",
          image: "jane-king.gif",
          color: "primary",
          route: "https://www.confluence.myorg.com",
          title: "View Schedule",
        },
        {
          sequence: 6,
          timestamp: "10/17/2021 12:00 AM",
          event: "First Interview Aired",
          icon: "mdi-broadcast",
          image: "CNN-logo.png",
          color: "success",
          route: "https://www.confluence.myorg.com",
          title: "Watch it now!",
        },
        {
          sequence: 7,
          timestamp: "10/10/2021 08:00 AM",
          event: "Agreement Signed",
          icon: "mdi-draw",
          image: "",
          color: "red",
          route: "/agreement",
          title: "View Agreement",
        },
        {
          sequence: 8,
          timestamp: "10/11/2021 12:00 AM",
          event: "First Interview Scheduled with Jane King",
          icon: "mdi-calendar-account-outline",
          image: "jane-king.gif",
          color: "primary",
          route: "https://www.confluence.myorg.com",
          title: "View Schedule",
        },
        {
          sequence: 9,
          timestamp: "10/17/2021 12:00 AM",
          event: "First Interview Aired",
          icon: "mdi-broadcast",
          image: "CNN-logo.png",
          color: "success",
          route: "https://www.confluence.myorg.com",
          title: "Watch it now!",
        },
      ],
      trip: {
        name: "",
        location: null,
        start: null,
        end: null,
      },
      questions: [
        {
          sequence: 1,
          question: "Why should customers want to use GlobeX data services?",
          answer: "",
        },
        {
          sequence: 2,
          question: "When are you planning to go public?",
          answer: "",
        },
        {
          sequence: 3,
          question:
            "Are there any upcoming new services and features for your main product?",
          answer: "",
        },
        {
          sequence: 4,
          question: "What value add is GlobeX bringing to its customers?",
          answer: "",
        },
        {
          sequence: 5,
          question: "What makes GlobeX data different from its competition?",
          answer: "",
        },
        {
          sequence: 6,
          question:
            "Can you give an overview of the roadmap GlobeX commited to?",
          answer: "",
        },
      ],
      show: false,
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    timeline() {
      return this.events.slice().reverse();
    },

    selectedCompanyData() {
      let company = Object.values(this.companies).find((v) => {
        if (v.id == this.selectedCompany) {
          return v;
        }
      });
      let interviewData = "";
      let staticData = this.amazonData;
      if (company.name == "Amazon") {
        (staticData = this.amazonData),
          (interviewData = {
            interviewee: "Karen Johnson",
            intervieweeImage: "karenimage.jpeg",
            anchor: "Jane King",
            anchorImage: "jane-king.gif",
            scheduled: "10/22/21 1 P.M.",
            interviewNumber: "1 out of 6",
            agreementName: "Amazon Media Agreement",
            status: "In Progress",
          });
      } else if (company.name == "Google") {
        staticData = this.googleData;
        interviewData = {
          interviewee: "Joseph Clark",
          intervieweeImage: "josephinterview.jpeg",
          anchor: "Jane King",
          anchorImage: "jane-king.gif",
          scheduled: "10/27/21 1 P.M.",
          interviewNumber: "3 out of 12",
          agreementName: "Google Billboard Agreement",
          status: "In Progress",
        };
      } else if (company.name == "Facebook") {
        staticData = this.facebookData;
        interviewData = {
          interviewee: "James Thomas",
          intervieweeImage: "jamesinterview.jpeg",
          anchor: "Jane King",
          anchorImage: "jane-king.gif",
          scheduled: "10/15/21 1 P.M.",
          interviewNumber: "2 out of 6",
          agreementName: "Facebook Agreement",
          status: "In Progress",
        };
      }

      return {
        name: company.name,
        data: staticData,
        interview: interviewData,
      };
    },
  },

  methods: {
    refresh() {
      this.companies = [
        {
          id: "Amazon",
          name: "Amazon",
        },
        {
          id: "Google",
          name: "Google",
        },
        {
          id: "Facebook",
          name: "Facebook",
        },
      ];

      console.log("this.companies: ", this.companies);
    },
  },
};
</script>

<style>
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import LiveInterview from '../views/LiveInterview.vue'
import Billing from '../views/Billing.vue'
import Form from '../views/Form.vue'
import Agreements from '../views/Agreements.vue'
import CompletedAgreements from '../views/CompletedAgreements.vue'
import CreateAgreement from '../views/CreateAgreement.vue'
import ShowAgreement from '../views/ShowAgreement.vue'
import SearchAgreement from '../views/SearchAgreement.vue'
import Login from '../views/Login.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Profile from '../views/Profile.vue'
import Company from '../views/Company.vue'
import CompanyProfile from '../views/CompanyProfile.vue'
import Leads from '../views/Leads.vue'
import CreateLead from '../views/CreateLead.vue'
import CompanyStatus from '../views/CompanyStatus.vue'
import LeadSearch from '../views/LeadSearch.vue'
import SignatureAgreement from '../views/SignatureAgreement.vue'
import ClientForm from '../views/ClientForm.vue'
import ClientCalendar from '../views/ClientCalendar.vue'
import LeadsCalendar from '../views/LeadsCalendar.vue'
import EventsCalendar from '../views/EventsCalendar.vue'
import CryptoLeads from '../views/CryptoLeads/Index.vue'
import CryptoLeadCreate from '../views/CryptoLeads/Create.vue'

// Admin url related files
import AdminDashboard from '../views/Admin/Dashboard.vue'


// Middleware
import auth from './middleware/auth';
import guest from './middleware/guest';
import role from './middleware/role';
import pipeline from './pipeline'

// Roles will be contractor (for employees) and user

// Admin has access to everything 
// Contractor Access to all pages, but only their data
// User accesss to profile and dashboard 


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['user']
    }
  },
  {
    path: '/liveinterview',
    name: 'LiveInterview',
    component: LiveInterview,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/form',
    name: 'Form',
    component: Form,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor', 'user']
    }
  },
  {
    path: '/agreements',
    name: 'agreements',
    component: Agreements,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/completed-agreements',
    name: 'completed-agreements',
    component: CompletedAgreements,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/create-agreement',
    name: 'CreateAgreement',
    component: CreateAgreement,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/edit-agreement/:id',
    name: 'EditAgreement',
    component: CreateAgreement,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/show-agreement/:id',
    name: 'ShowAgreement',
    component: ShowAgreement,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/search-agreement',
    name: 'SearchAgreement',
    component: SearchAgreement,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'AuthLayout',
      middleware: [guest],
      role: ['admin', 'contractor', 'user']
    }
  },
  {
    path: '/reset/:token',
    name: 'Reset',
    component: ResetPassword,
    meta: {
      layout: 'AuthLayout',
      middleware: [guest],
      role: ['admin', 'contractor', 'user']
    }
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Company,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/companyprofile',
    name: 'CompanyProfile',
    component: CompanyProfile,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/edit-companyprofile/:id',
    name: 'edit-companyProfile',
    component: CompanyProfile,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/leads',
    name: 'leads',
    component: CreateLead,                  // while fetch stock api is working then replace this component with Leads
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/companystatus',
    name: 'companyStatus',
    component: CompanyStatus,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/leadsearch',
    name: 'leadSearch',
    component: LeadSearch,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/edit-lead/:id',
    name: 'edit-lead',
    component: CreateLead,              // while fetch stock api is working then replace this component with Leads
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/leadscalendar',
    name: 'leadsCalendar',
    component: LeadsCalendar,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/eventscalendar',
    name: 'calendarEvents',
    component: EventsCalendar,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor'],
    }
  },

  {
    path: '/client-form',
    name: 'client-form',
    component: ClientForm,
    meta: {
      layout: 'AuthLayout',
    }
  },


  // Route for signature agreement page
  {
    path: '/signature-agreement',
    name: 'signatureAgreement',
    component: SignatureAgreement,
    meta: {
      layout: 'SignatureLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor', 'user']
    }
  },

  {
    path: '/signature-agreement-for-guest',
    name: 'signatureAgreementForGuest',
    component: SignatureAgreement,
    meta: {
      layout: 'SignatureLayout',
    }
  },

  // Route for client calendar page
  {
    path: '/client-calendar',
    name: 'clientCalendar',
    component: ClientCalendar,
    meta: {
      layout: 'SignatureLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor', 'user']
    }
  },

  // CryptoLeads
  {
    path: '/crypto-leads',
    name: 'cryptoLeads',
    component: CryptoLeads,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/crypto-lead/create',
    name: 'cryptoLeadCreate',
    component: CryptoLeadCreate,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },
  {
    path: '/crypto-lead/edit/:id',
    name: 'cryptoLeadEdit',
    component: CryptoLeadCreate,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin', 'contractor']
    }
  },

  // Only Admin related Routes
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: AdminDashboard,
    meta: {
      layout: 'AppLayout',
      middleware: [auth, role],
      role: ['admin']
    }
  },


]

const router = new VueRouter({
  routes
})

// Documentation https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards

router.beforeEach((to, from, next) => {
  let userData = localStorage.getItem("user_data");
  if (userData) {
    userData = JSON.parse(userData);
    userData.role = userData.role ?? 'user';
  } else {
    userData = null;
  }
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = { to, from, next, userData };
    return middleware[0]({
      ...context,
      next: pipeline(context, middleware, 1),
    });
  } else {
    return next();
  }
});

export default router


export const countries = [
  {
    name: 'United States',
    flag: 'em-us'
  },
  {
    name: 'United Kingdom',
    flag: "em-gb"
  },
  {
    name: 'Canada',
    flag: 'em-flag-ca'
  },
  {
    name: 'Andorra',
    flag: 'em-flag-ad'
  },
  {
    name: 'Arab Emirates',
    flag: 'em-flag-ae'
  },
  {
    name: 'Antigua & Barbuda',
    flag: 'em-flag-ag'
  },
  {
    name: 'Albania',
    flag: 'em-flag-al'
  },
  {
    name: 'Anguilla',
    flag: 'em-flag-ai'
  },
  {
    name: 'Brazil',
    flag: 'em-flag-br'
  },
  {
    name: 'Mongolia',
    flag: 'em-flag-mn'
  },
  {
    name: 'Spain',
    flag: 'em-es'
  },
  {
    name: 'Russia',
    flag: 'em-ru'
  },
  {
    name: 'Kazakhstan',
    flag: 'em-flag-kz'
  },
  {
    name: 'Kyrgyzstan',
    flag: 'em-flag-kg'
  }
]
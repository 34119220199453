<template>
  <div id="overview" class="mb-4 grey lighten-5 pa-4">
    <!-- 1 STARTS HERE -->
    <v-row>
      <v-col>
        <h3 class="text-decoration-underline">Contracting Company Details</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <o-text label="Agreement Name" :value="form.agreementName" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <o-text label="Categories" />
        <template v-for="v in form.categories">
          <v-chip class="ma-2" :key="v">{{ v }} </v-chip>
        </template>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <o-text label="Client Company Name" :value="company.name" />
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="company.type == 'lead'">
        <o-text label="Client Address" :value="company.addressLine1" />
      </v-col>
      <v-col v-if="company.type == 'crypto-lead'">
        <o-text label="Client Address" :value="company.addressLine2" />
      </v-col>
    </v-row>

    <v-row v-if="company.type == 'lead'">
      <v-col>
        <o-text label="City" :value="company.city" />
      </v-col>
      <v-col>
        <o-text label="State" :value="company.state" />
      </v-col>
      <v-col>
        <o-text label="Zip Code" :value="company.zip" />
      </v-col>
      <v-col>
        <o-text label="Country" :value="company.country" />
      </v-col>
    </v-row>

    <!-- 2 STARTS HERE -->
    <v-row>
      <v-col>
        <h3 class="text-decoration-underline">
          Client Details
        </h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <o-text
          label="Client Authorized Signer Name"
          :value="form.client.name"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <o-text
          label="Client Authorized Signer Mobile Phone"
          :value="form.client.number"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <o-text
          label="Client Authorized Signer Email"
          :value="form.client.email"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <o-text label="Main Contact Role" :value="form.client.role" />
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col>
        <o-text
          label="Client Main Contact Name"
          :value="form.secondaryClient.name"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <o-text label="Client Email" :value="form.secondaryClient.email" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <o-text
          label="Client Mobile Phone"
          :value="form.secondaryClient.number"
        />
      </v-col>
    </v-row> -->

    <!-- 3 STARTS HERE -->
    <v-row>
      <v-col>
        <h3 class="text-decoration-underline">Services Rendered</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <o-text label="Edit Compensation" :value="form.editCompensation" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <o-text label="Common Stock" :value="company.commonStock" />
      </v-col>
      <v-col>
        <o-text label="Restricted Shares" :value="form.restrictedShares" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <o-text label="Initial Payment" :value="form.initialPayment" />
      </v-col>
      <v-col>
        <o-text label="Monthly Payment" :value="form.monthlyPayment" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="Object.values(form.tableData).filter((v) => v.required)"
          :items-per-page="20"
          item-key="name"
          dense
          hide-default-footer
          class="grey lighten-5"
        >
          <template v-slot:item.actions="{ item }">
            <p>{{ item.duration }}</p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OText from "@/components/steps/OverviewText.vue";

export default {
  components: {
    OText,
  },

  props: {
    form: Object,
    companies: Array,
  },

  data() {
    return {
      headers: [
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "serviceType",
        },
        {
          text: "Duration",
          value: "serviceDuration",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    company() {
      let company = this.companies.find((v) => v.id == this.form.company);
      if (company) {
        return company;
      }
      return {};
    },
  },
};
</script>

<style scoped>
.col {
  padding: 5px;;
}
</style>
<template>
  <div>
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app>
        <v-list-item class="ma-1">
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              <v-img src="/ntts-logo.png"></v-img>
            </v-list-item-title>
            <v-list-item-subtitle>
              Covering all your IR needs
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list class="sidebar-nav-item-list" dense nav>
          <v-list-item
            v-for="item in items.filter((v) => v.access == true)"
            :key="item.title"
            :to="item.to"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                v-if="item.title == 'Agreements'"
                color="#206a5d"
                small
                icon
                to="create-agreement"
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <div class="pa-2"></div>
        </template>
      </v-navigation-drawer>

      <v-app-bar dense app color="#206a5d" dark>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>NewToTheStreet</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-toolbar-title>1 (516) 696-5900</v-toolbar-title>

        <v-btn icon @click="toggle_dark_mode(), buttonToggle()">
          <v-icon>mdi-brightness-6</v-icon>
        </v-btn>

        <v-dialog v-model="logoutDialog" persistent max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h6"> Logging Out </v-card-title>
            <v-card-text>Are you sure you would like to logout?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="logoutDialog = false">
                No
              </v-btn>
              <v-btn color="green darken-1" text @click="handleLogoutEvent">
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-app-bar>

      <v-main>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import helper from "../helper/helper";

export default {
  data() {
    return {
      drawer: null,
      logoutDialog: false,
      newAgreement: "",
      items: [
        {
          title: "Dashboard",
          icon: "mdi-desktop-mac-dashboard",
          to: "/dashboard",
          access: helper.hasRouteAccess("/dashboard"),
        },
        {
          title: "Profile",
          icon: "mdi-account-voice",
          to: "/profile",
          access: helper.hasRouteAccess("/profile"),
        },
        {
          title: "Clients",
          icon: "mdi-domain",
          to: "/companies",
          access: helper.hasRouteAccess("/companies"),
        },
        {
          title: "Agreements",
          icon: "mdi-text-box-check",
          to: "/agreements",
          access: helper.hasRouteAccess("/agreements"),
        },
        {
          title: "Completed",
          icon: "mdi-text-box-check",
          to: "/completed-agreements",
          access: helper.hasRouteAccess("/completed-agreements"),
        },
        {
          title: "Leads",
          icon: "mdi-account-filter",
          to: "/leadsearch",
          access: helper.hasRouteAccess("/leadsearch"),
        },
        {
          title: "Crypto Leads",
          icon: "mdi-account-filter-outline",
          to: "/crypto-leads",
          access: helper.hasRouteAccess("/crypto-leads"),
        },
        {
          title: "Leads Calendar",
          icon: "mdi-calendar-account",
          to: "/leadscalendar",
          access: helper.hasRouteAccess("/leadscalendar"),
        },
        {
          title: "Interview Calendar",
          icon: "mdi-calendar-check",
          to: "/eventscalendar",
          access: helper.hasRouteAccess("/eventscalendar"),
        },
        {
          title: "Admin Dashboard",
          icon: "mdi-users",
          to: "/admin/dashboard",
          access: helper.hasRouteAccess("/admin/dashboard"),
        },
      ],
      darkButton: "Light Mode",
      agreements: [],
    };
  },

  methods: {
    addAgreement() {
      let newAgreement = {
        id: Date.now(),
        title: this.newAgreement,
        icon: "mdi-image",
      };
      this.agreements.push(newAgreement);
      this.newAgreement = "";
    },
    toggle_dark_mode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
      console.log(this.$vuetify.theme.dark);
    },
    buttonToggle() {
      if (this.$vuetify.theme.dark) {
        this.darkButton = "light Mode";
        console.log(this.darkButton, "light Mode");
      } else {
        this.darkButton = "Dark Mode";
        console.log(this.darkButton, "Dark Mode");
      }
    },
    handleLogoutEvent() {
      this.logoutDialog = false;
      axios
        .get("https://jsonplaceholder.typicode.com/posts")
        .then(function (response) {
          // handle success
          console.log(response);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
      // remove user_data from local storage and sends user back to login page
      localStorage.removeItem("user_data");
      this.$router.push("/login");
    },
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
        console.log("theme");
      } else {
        this.$vuetify.theme.dark = false;
        console.log("theme");
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  },
};
</script>

<style lang="sass">
.no-tasks
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  opacity: .5

.sidebar-nav-item-list
.v-list-item__action
  margin: auto
</style>
